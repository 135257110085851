export default {
  YISHUPLUSH: {
    HUOBI:'火币',
    XINSHOUZHINAN: "新手指南",
    JIARUSHEQU: "加入社区",
    HUODELIRUNKOUCHU: "获得利润时扣除",
    JQRXQ: "机器人详情",
    MC: "满仓",
    FC: "分仓",
    HY: "合约",
    XH: "现货",
    HYSYPH: "合约收益排行",
    XHSYPH: "现货收益排行",
    ZCGZ: "资产估值",
    ZCZH: "资产组合",
    FCGL: "分仓管理",
    CJMCJQR: "创建满仓机器人",
    CJFCJQR: "创建分仓机器人",
    MCCLZYL: "满仓策略今日总盈利",
    MCCLLSZYL: "满仓策略历史总盈利",
    FCCLZYL: "分仓策略今日总盈利",
    FCCLLSZYL: "分仓策略历史总盈利",
    BEI: "倍",
    GUZHI: "估值",
    LIRUN: "利润",
    KCW: "开仓位",
    ZYFS: "止盈方式",
    BFB: "百分比",
    KD: "开多",
    PD: "平多",
    KK: "开空",
    PK: "平空",
    SK: "双开",
    CLCS: "策略参数",
    KCFX: "开仓方向",
    GGBS: "杠杆倍数",
    KDZB: "开多指标",
    XZDDKCW: "选择多单开仓位",
    DDZYFS: "多单止盈方式",
    DDZYZB: "多单止盈指标",
    DDZYBL: "多单止盈比例",
    DDZYCHBL: "多单止盈回撤比例",
    DDZSBL: "多单止损比例",
    XT: "选填",
    KKZB: "开空指标",
    XZKDKCW: "选择空单开仓位",
    KDZYFS: "空单止盈方式",
    KDZYZB: "空单止盈指标",
    KDZYBL: "空单止盈比例",
    KDZYHCCBL: "空单止盈回撤比例",
    KDZSBL: "空单止损比例",

    KCZB: "开仓指标",
    ZYTJ: "止盈条件",
    BCFZZB: "补仓辅助指标",
    XZBCW: "选择补仓位",
    DX: "多选",
    KCTJ: "开仓条件",
    FCJQRXQ: "分仓机器人详情",
    MCJQRXQ: "满仓机器人详情",
    ZDSL: "做单数量",
    ZYBL: "止盈比例",
    ZYHCBL:"止盈回撤比例",
    HTMCBL: "回调卖出比例",
    YL: "预览",
    BCW: "补仓位",
    DENGDAI:"等待",
    FCCL:"分仓策略",
    JYCL:"交易策略",
    ZYZB:"止盈指标",
    DUODAN:"多单",
    KONGDAN:"空单",

    ZJMCJ:"最佳卖出价"
  },

  NEWGN: {
    BD: "布丁",
    BDCL: "布丁策略",
    CJBDJRR: "创建布丁机器人",
    BDCLJRZYL: "布丁策略今日总盈利",
    BDLSZYL: "布丁历史总盈利",
    BDLR: "布丁利润",
    BDJRRXQ: "布丁机器人详情",
    XYGZCW: "下一个补仓位",
    ZCWZDSLBF: "补仓位数量与做单数量不符，选择补仓位数量需要为：",
    YL: "预览",
    XRCLK: " 分仓策略库 ",
    ZCW: "补仓位",
    FZXG: "分钟下轨",
    XSXG: "小时下轨",
    RXG: "日下轨",

    // ----------------------------
    ZCFX: "资产分析",
    ZZC: "总资产",
    ZRSY: "昨日收益",
    RSY7: "7日收益",
    RSY30: "30日收益",
    LSSY: "历史收益",
    SYFX: "收益分析",
    TLFX: "套利分析",
    LJSYL: "累计收益率",
    LJSY: "累计收益",
    MRSY: "每日收益",
    LJTL: "累计套利",
    MRTL: "每日套利",
    DAY: "日",
    MONTH: "月",
  },

  XROBOT: {
    XSHUOMING:
      "X-Robot是一个允许用户更开放地设置策略参数的实验室，可以将策略保存并快速应用在马丁机器人中。",
    ZDSL: "做单数量",
    ZYBL: "止盈比例",
    BCBS: "补仓倍数",
    HTMCBL: "回调卖出比例",
    YL: "预览",
    XTCLBKBJ: "系统策略不可编辑",
    BJ: "编辑",
    ZWCL: "暂无策略",
    TJXCL: "添加新策略",
    BDTS: "布丁策略中回调买入比例不生效",
    XBSYD: "相比上一单补仓比例",
    XBSYD2: "相比上一单回调买入比例",
    BJCL: "编辑策略",
    SC: "删除",
    SZZDSL: "设置做单数量",
    SZBCJH: "设置补仓计划",
    SZZYJH: "设置止盈计划",
    ZXCL: "选择策略",
    QD: "确定",
    CLMC: "策略名称",
    QTXCLMC: "请填写策略名称",
    ZDBCS: "最多向下做单数量，最大可填写10",
    BCBSSM: "每次补仓对比上一次补仓金额的倍数",
    ZDSLZD: "做单数量最大为10",
    ZDSLZX: "做单数量最小为2",
    XYB: "下一步",
    SYB: "上一步",
    XBYCCJJZYBL: "相比于持仓均价的止盈比例",
    XBYCCJJHTMCBL: "相比于持仓均价的回调卖出比例",
    WC: "完成",
  },
  MONI: {
    MNZH: "模拟账户",
    MNZHCSJE: "模拟账户初始金额",
    MNSM: "模拟账户仅用于模拟用户在币安交易所的交易，将忽略交易深度等问题，创建机器人时选择模拟交易即可根据币安交易所的实时价格启动机器人并进行结算，模拟交易的初始金额为系统自动设置且不计入流水，请通过面板查看详情。",
    CKMNZH: "查看模拟账户",
    MN: "模拟",
    MNZHZCZE: "模拟资产总额(U)",
  },
  HEYUE: {
    HY: "合约",
    HYCL: "合约策略",
    HYCLJRZYL: "合约策略今日总盈利",
    HYCLLSZYL: "合约历史总盈利：",
    // BCTP: "保存图片",
    BCTP: "截屏分享",
    BAHY: "币安合约",
    CJHYJQR: "创建合约机器人",
    KD: "开多",
    KK: "开空",
    KC: "开仓",
    PC: "平仓",
    ZYJ: "止盈价",
    ZSJ: "止损价",
    BZJLX: "保证金类型",
    QUANC: "满仓",
    ZHUC: "分仓",
    HYJQRXQ: "合约机器人详情",
    DDKC: "等待开仓",
    JHKCJ: "计划开仓价",
    DDPC: "等待平仓",
    SJKCJ: "实际开仓价",
    HYJQRWCYCMRMC: "合约机器人完成一次买入和对应卖出后视为一次套利",
    KCJG: "开仓价格",
    GGBS: "杠杆倍数",
    KCFX: "开仓方向",
    TDHYCCL: "TA的合约策略",
    QDMRJG: "请先确定买入价格",
    ZSBL: "止损比例",
    // ------
    XGCS: "修改参数",
    CSCW: "参数错误！",
    FBT: "(非必填)",
  },
  // 补充
  ADD: {
    MDGD: "马丁跟单",
    DTGD: "定投跟单",
    WGGD: "网格跟单",
    MD: "马丁",
    DT: "定投",
    WG: "网格",
    HB: "火币",
    BA: "币安",
    FX: "分享",
    JYBD: "交易标的",
    SYCL: "使用策略",
    SJSYL: "实际收益率",
    YSJQR: "Tradease机器人",
    NDLHGJ: "Make trade easy",
    YJNH: "策略预计年化",
    ZSYL: "我考100是因为满分只有100",
    FSYL: "币圈无情人有情",
  },
  // ----------其他----------
  ORDER: {
    FZCG: "复制成功",
    FZSB: "复制失败",
    ZWSJ: "暂无数据",
    QSRZFMM: "请输入支付密码",
    QD: "确定",
    KY: "可用：",
    TIAN: "天",
    SHI: "时",
    FEN: "分",
  },
  // 面板
  MIANBAN: {
    HB: "火币",
    BA: "币安",
    OY: "欧易",
    SYZJ: "剩余资金",
    MDCL: "马丁策略",
    WGCL: "网格策略",
    DTCL: "定投策略",
    CXJJ: "重新加载",
    BAZCZE: "币安资产总额",
    HBZCZE: "火币资产总额",
    SYZJZ: "注：剩余资金为未参与策略的资产的折合",
    ZCXQ: "资产详情",
    DQMYKYPC: "当前没有可以平仓币种",
    YJPC: "一键平仓",
    BZ: "币种",
    SL: "数量",
    YE: "余额",
    KJY: "可交易",
    ZWZC: "暂无资产,请到交易所充值",
    ZWBDJYSAPI: "暂未绑定交易所API",
    LJBD: "立即绑定",
    ZYSX: "注意事项：一键平仓后所有币种都将按当前市价卖出变成USDT，且相关机器人将被自动关闭，请慎重操作！！！",
    YXBZSL: "已选币种数量：",
    PCYJKD: "平仓预计可得",
    QUANXUAN: "全选",
    QRPC: "确认平仓",
    XUXIAO: "取消",
    QQRPC: "请输入安全密码并确认平仓",
    LHCL: "量化策略",
    ZCFB: "资产分布",
  },
  // ---------------------------------登录-------------------------------------------
  LOGIN: {
    // ------------------login--------------
    LOGIN_SUCCESS: "登录成功",
    PLEASE_RIGHT_PHONE_EMAIL: "请输入正确的手机号或邮箱",
    NUM_ERROR: "账号密码错误",
    WELCOME: "欢迎登录",
    Tradease: "Tradease",
    LOGIN_TYPE: "请通过手机号/邮箱/用户名登录",
    PLEASE_INPUT: "请输入手机号/邮箱/用户名",
    PLEASE_PASSWORD: "请输入密码",
    FORGET_PASSWORD: "忘记密码",
    LOGIN_NOW: "立即登录",
    NO_ACCOUNT: "没有账号？",
    REG_NOW: "立即注册",
    AGREE_XY: "继续登录即视为同意",
    AGREE_XY2: "《用户协议》",
  },
  DOWNLOAD: {
    ZCWC: "注册完成",
    GXN: "恭喜您",
    SLWCZC: "顺利完成注册",
    DJZZAPP: "点击下方按钮下载APP",
    XZAZ: "下载安卓APP",
    XZIOS: "下载 ios APP",
  },
  // 修改设置支付密码
  OTCPASSWORD: {
    ZFMM: "支付密码",
    // PLEASE_INPUT_CODE: "请输入图形验证码",
    NUM_ERROR: "账号输入错误",
    PASSWORD_LEAST: "密码最少8位，字母加数字",
    PASSWORD_NOSAME: "两次密码不一致",
    MODIFY_PAY_PASSWORD: "修改支付密碼",
    SET_PAY_PASSWORD: "设置支付密码",
    // TX_YANZHENGMA: "图形验证码",
    // PLEASE_TX_YANZHENGMA: "请输入图形验证码",
    HQ_YANZHENGMA: "获取验证码",
    YZM: "验证码",
    PLEASE_YZM: "请输入验证码",
    PLEASE_PASSWORD: "请输入密码",
    CONFIRM_PASSWORD: "确认密码",
    PLEASE_CONFIRM_PASSWORD: "请确认密码",
    OK: "确 定",
    ERROR: "获取账号失败请重新登录",
  },
  // 修改密码
  PASSWORD: {
    PLEASE_RIGHT_NUMBER: "请输入正确的账号",
    NUM_ERROR: "账号输入错误",
    PASSWORD_LEAST: "密码最少8位，字母加数字",
    PASSWORD_NOSAME: "两次密码不一致",
    ZHAOHUI_PASSWORD: "找回密码",
    TIPS: "请根据已注册的手机号的验证码找回登录密码",
    EMAIL_NUM: "邮箱",
    PHONE_NUM: "手机号",
    HQ_YANZHENGMA: "获取验证码",
    PLEASE_YZM: "请输入验证码",
    PASSWORD: "密码",
    PLEASE_PASSWORD: "请输入密码",
    PLEASE_PASSWORD2: "请再次输入密码",
    // TISHI: "登录密码即支付密码，登录后可在安全中心修改支付密码",
    CONFIRM_PASSWORD: "确认密码",
    PLEASE_CONFIRM_PASSWORD: "请确认密码",
    OK: "确 定",

    XGDLMM: "修改登录密码",
    QSRYMM: "请输入原密码",
  },
  // 注册
  REGISTER: {
    ZCXZH: "注册新账号",
    HYZC: "欢迎注册新账号",
    SJH: "请输入手机号",
    YHM: "用户名",
    YQM: "请输入邀请码",
    PLEASE_RIGHT_PHONE: "请输入正确的手机号",
    PLEASE_RIGHT_EMAIL: "请输入正确的邮箱",
    PLEASE_RIGHT_PHONE_EMAIL: "请输入正确的账号",
    PLEASE_INPUT_CODE: "请输入图形验证码",
    NUM_ERROR: "账号输入错误",
    PASSWORD_LEAST: "密码最少8位，字母加数字",
    PASSWORD_NOSAME: "两次密码不一致",
    PHONE_REGISTER: "手机注册",
    EMAIL_REGISTER: "邮箱注册",
    PHONE_NUM: "手机号",
    EMAIL_NUM: "邮箱",
    YANZHENGMA: "验证码",
    PLEASE_YANZHENGMA: "请输入验证码",
    HQ_YANZHENGMA: "获取验证码",
    PASSWORD: "密码",
    PLEASE_PASSWORD: "请输入密码",
    PLEASE_PASSWORD2: "请再次输入密码",
    TISHI: "支付密码默认与登录密码一致，可在安全中心中修改",
    NOW_REGISTER: "立即注册",
    HAD_ZHANGHAO: "已有账号?",
    HAD_ZHANGHAO2: "立即登录",
    REGISTER_SUCESS: "注册成功",
    PLEASW_NUM: "请输入账号",
    LJXZ: "立即下载app",
  },
  // 选择国家
  // TELPREFIX: {
  //   CHOOSE_CITY: "选择国家",
  //   OK: "确定",
  //   SEARCH_CITY: "搜索国家",
  // },
  // ------------------------------------登录注册结束----------------------------
  // ----------------------------------------跟单-------------------
  GENDAN: {
    QBD: "请绑定",
    DTGD: "定投跟单",
    CLYJNH: "策略预计年化",
    YYX: "已运行：",
    TIAN: "天",
    CLZPM: "该策略周排名",
    DTCLCS: "定投策略参数",
    JYPT: "交易平台",
    JYBD: "交易标的",
    MRJG: "买入价格",
    ZYBL: "止盈比例",
    ZSBL: "止损比例",
    HTMRBL: "回调买入比例",
    HTMCBL: "回调卖出比例",
    XHCZBL: "循环重置比例",
    GSGCL: "跟随该策略",
    MDGD: "马丁跟单",
    MDCLCS: "马丁策略参数",
    ZDSL: "做单数量",
    BCBL: "补仓比例",
    BCZFBL: "补仓增幅比例",
    BCBS: "补仓倍数",
    WGGD: "网格跟单",
    WGCLCS: "网格策略参数",
    JGFW: "价格范围",
    WGSL: "网格数量",
    GE: "个",
    MGLR: "每格利润",
    HD: "滑点",
  },
  // -------------------------------------资产相关开始-----------------------
  // 转出
  EXTRACT: {
    // LMC: "链名称",
    PLEASE_LOGIN: "请先登录",
    ERROR: "账号错误，请退出重新登录",
    YVE_BUZHU: "余额不足",
    MORE_TIBI: "最大转出为",
    LESS_TIBI: "最小转出为",
    INPUT_TX_YANZHENGMA: "请输入图形验证码",
    TIBI: "提币",
    TIBIJILU: "提币记录",
    KE_YONG: "可用：",
    TIBI_ADRESS: "提币地址",
    INPUT_OR_LANGAN_ADRESS: "粘贴BEP20提币地址",
    NUM_USDT: "提币数量", //-
    LESS_TIBI_NUM: "最小转出数量",
    PHONE_YANZHENGMA: "手机验证码",
    EMAIL_YANHENGMAL: "邮箱验证码",
    PLEASE_YANZHENGMA: "请输入验证码",
    HQ_YANZHENGMA: "获取验证码",
    ZHIFU_PWD: "安全密码",
    PLEASE_ZHIFU_PWD: "请输入支付密码",
    TIXIAN_SUCCESS: "转出成功",
    DAOZHANG_NUM: "实际到账",
    SHOUXVFEI: "手续费：",
    ITXIAN: "确认提币",
    TISHI: "友好提示：",
    TISHI_1: "当前,每人每日最高可转出",
    TISHI_2: ",单笔转出限额为",
    TISHI_5: "为了保障资金安全,我们会对转出进行人工审核,请耐心等待",
    DQGAS: "当前Gas:",
    QHBZ: "切换币种",
    ZCJL: "转出记录",
    KZ: "可转：",
  },
  // 资产页面DMO
  WALLET: {
    XLSX: "下拉刷新",
    SKLJSX: "松开立即刷新",
    JIFEN: "积分：",
    GMJF: "购买积分",
    APISQ: "授权",
    YQHY: "邀请好友",
    FWJF: "服务积分",
    ZCXQ: "资产详情",
    RILI: "日历",
    YUJING: "预警",
    JQQD: "敬请期待",
    MONI: "模拟",
    ZWPM: "暂无排名",
    PAIHANG: "排行",
    JIAOYIDUI: "交易对",
    PINGTAI: "平台",
    YUJINIANHUA: "预期年化",

    ZCXQ: "资产详情",
    FWJF: "服务积分",
    JFYE: "积分余额(1积分=1USDT)",
    ZHYE: "账户余额",

    JFLS: "积分流水",
    SYLS: "所有流水",
    ZRLS: "转入流水",
    ZCLS: "转出流水",
    PLEASE_LOGIN: "请先登录",
    GMJF: "购买积分",

    CHONG_BI: "充币",
    TI_BI: "提币",
    DUI_HUAN: "兑换",
    ALL_ASSETS: "总账户资产折合（USDT）",
    CAN_USE: "可用",
    ONE_PRICE: "单价（USDT）",
    ZHE_HE: "折合USDT",
    ZHE_HE_CNY: "折合CNY",
    LOAD: "加载中",
    LOADING: "等待卖出",
    PENDING: "部分卖出",
    SUCCESS: "已经卖出",
    LOCK: "冻结",
    DAI_CHUSHOU: "待出售",
    JIAOYI_XIANGQING: "交易详情",
    ALL_NUM: "总量",
    SHENGYV: "剩余未卖出",
    ZHICHANLIUSHUI: "资产流水",
  },
  // 充值页面
  RECHARGE: {
    CHONGBI: "充币",
    LIANNAME: "链名称",
    PLEASE_LOGIN: "请先登录",
    FAIL_COPY: "复制失败，请长按复制",
    SUCCESS_COPY: "复制成功",
    CHONG_BI: "转入",
    USDT_CHONG_BI: "转入",
    JIEPING_SAVE_QRCODE: "截屏保存二维码",
    COPY_ADDRESS: "复制地址",
    ZHUANRU_SHUOMING: "转入说明：",
    SHUOMING_1:
      "此地址是您唯一且独自使用的转入地址,您的转入地址不会经常改变,可以重复充值;如有更改,我们会尽量通过公告或邮件通知您",
    SHUOMING_2: "请勿向上述地址充值任何非ERC20_USDT资产,否则资产将不可找回",
    SHUOMING_4: "请勿向上述地址充值任何非TRC20_USDT资产,否则资产将不可找回 ",
    SHUOMING_3: "请勿向上述地址充值任何非DMO资产,否则资产将不可找回",
    QHBZ: "切换币种",
  },

  // 转出记录
  TIBIRECORD: {
    TIBIJILU: "提币记录",
    PLEASE_LOGIN: "请先登录",
    DAI_SHEN_HE: "待审核",
    TI_BI_ZHONG: "打包中",
    SUCCESS: "成功",
    FAIL: "失败",
    TIBI_JILU: "转出记录",
    NO_DATA: "暂无数据",
    BIAN_HAO: "编号",
    SHU_LIANG: "数量",
    TIME: "时间",
    ADDRESS: "地址",
  },
  // -------------------------------------资产相关结束-----------------------
  // ------------------------镜像----------------

  JINGXIANG: {
    JXZBJ: "镜像准备金",
    KSSJ: "开始时间",
    TZE: "投资额(U)",
    LJTZE: "累计投资额(U)",
    LJSYE: "累计收益额(U)",
    LJSYL: "累计收益率",
    LJJXSC: "累计镜像时长",
    JYPT: "交易平台",
    JXRC: "镜像人次",
    CLANDSYZB: "策略与收益占比",
    JXYHSZBKJ: "镜像用户设置不可见",
    WG: "网格",
    MD: "马丁",
    DT: "定投",
    SYZJ: "剩余资金",
    MDCL: "马丁策略",
    WGCL: "网格策略",
    DTCL: "定投策略",
    GDY: "跟单员",
    CLZB: "策略占比",
    ZWSY: "暂无收益",
    TACL: "TA的策略",

    JYBD: "交易标的",
    CJSJ: "创建时间",
    SYL: "收益率",
    ZWSJ: "暂无数据",

    TUIJIAN: "推荐",
    YIJINGXIANG: "已镜像",
    JX: "镜像",

    JXKQSJ: "镜像开启时间",
    XQ: "详情",
    YGB: "已关闭",

    JXXQ: "镜像详情",
    QBZBJCZ: "确保准备金充足并开始镜像",
    QRJX: "确认镜像",
    QX: "取消",
    QRRISHI:
      "请输入支付密码并确认镜像账户的后续策略，请确保相关交易账户有足够的准备金，镜像产生的亏损将由用户自身承担，请再次确认。",
    QSRMM: "请输入密码",
    ZHYEBZ: "您的账户可交易余额低于准备金标准",
    LIJIJX: "立即镜像",

    SSYH: "搜索用户",
    ZWJG: "暂无结果",
    CCXQ: "持仓详情",
    CZRZ: "操作日志",
    QXJX: "取消镜像",
    ZWJL: "暂无记录",
    CKXQ: "查看详情",
    QXZH: "取消镜像后，将不在自动跟随镜像账户进行操作，正在进行的机器人继续进行策略，可自行关闭，确认取消镜像么？",
    FQ: "放弃",
    QRQX: "确认取消",
  },
  // -------------------------------------个人中心相关开始-------------------
  GOUMAIJIFEN: {
    GMJF: "购买积分",
    DQJFYE: "当前积分余额（产生收益时扣除等价20%收益的积分）",
    JFTC: "积分套餐",
    JFTCSM: "不同套餐积分购买后可直接叠加至积分余额",
    JF: "积分",
    WZK: "无折扣",
    ZK: "折扣",
    JFSM: "积分说明",
    JFSM1:
      "1.购买过积分套餐（套餐类型/次数不限）即可成为VIP积分，VIP积分可以邀请好友/享受其他社区返利等权益；",
    JFSM2: "2.积分仅用于抵扣机器人策略产生的收益的20%等价抵扣，无法转让/交易；",
    JFSM3: "3.积分购买后不可撤销/不可退还；",
    JFSM4:
      " 4.积分余额小于10时将无法继续开启新的策略，正在进行的策略不受影响，但仍然扣除积分余额，续费后方可开启新策略。",
    KYYE: "可用余额",
    QRGM: "确认购买",
    QX: "取消",
    QSRMM: "请输入密码",
    KYUSDT: "可用USDT:",
    QSRAQMM: "请输入安全密码并确认购买",
    GMHLJSX: "购买后立即生效",
  },
  JXSETTING: {
    JXGL: "镜像管理",
    YXBJX: "允许被镜像",
    YXJXSM:
      "允许被镜像后，其他用户可通过搜索用户名查看你的主页，只有关闭所有机器人时才能被镜像和取消允许被镜像。",
    JXZH: "镜像账户",
    JXZYKJ: "镜像主页可见",
    OK: "确认",
    CLZB: "策略占比",
    SYZB: "收益占比",
    WGCL: "网格策略",
    DTCL: "定投策略",
    MDCL: "马丁策略",
  },
  CUSTOMERSERVIXE: {
    MORE_INPUT: "最多输入200个字",
    LIXNXI_KEFU: "联系客服",
    PLEASE_TELL_QUE: "请填写问题，9:00~18:00客服将及时回复",
    UPLOADING: "上传中...",
    TIJIAO: "提 交",
    HISTORY: "历史记录",
    HUIFU: "回复：",
    NO_DATA: "暂无记录",
    GDJL: "工单记录",
    TJGD: "提交工单",
  },
  // 邀请好友
  FRINDSCOME: {
    PLEASE_LOGIN: "请先登录",
    FRIND_COME: "邀请好友",
    YQHYZC: "邀请好友注册Tradease量化机器人得推广返佣",
    SMXZ: "扫描以下二维码注册并下载APP",
    FZYQLJ: "复制邀请链接",
  },
  // 选择语言
  LANGSWITCH: {
    CHOOSE_CITY: "选择语言",
  },

  // 我的
  MINELIST: {
    GMJF: "购买积分",
    CSSYHDK: "产生收益后抵扣",
    APISQ: "API授权",
    JFZX: "积分中心",
    XTGG: "系统公告",
    BZZX: "帮助中心",
    WDSQ: "我的社区",
    JXGL: "镜像管理",
    AQSZ: "安全设置",
    TJGD: "提交工单",
    GYWM: "关于我们",
    TCDL: "退出登录",
    QDTC: "确定退出？",
    QX: "取消",
    OK: "确定",
  },
  // 公告
  NOTICE: {
    GONG_GAO_LIST: "系统公告",
    NO_DATA: "暂无公告",
    GONG_GAO_INFO: "公告详情",
    BZZX: "帮助中心",
  },
  // 安全中心
  SAFETY: {
    ANQUAN_ZHONGXIN: "安全中心",
    ZHIFU_PWD: "修改支付密码",
    XIUGAI_PWD: "修改登录密码",
  },
  // http
  HTTP: {
    ERROR_LOG: "获取登录信息失败",
    ERROR_WANGLUO: "请求失败",
  },
  // footer

  FOOTER: {
    SHOUYE: "首页",
    CELUE: "策略",
    MIANBAN: "面板",
    JINGXIANG: "镜像",
    ZHUANGHU: "账户",
  },
  // -----------------------------个人中心结束------------------------------------
  //  社区
  SHEQU: {
    SQ: "社区",
    GZ: "规则",
    WU: "无",
    SHEQUDENGJI: "社区等级",
    BZYJ: "本周业绩",
    ZYJ: "总业绩",
    WODESHEQU: "我的社区",
    QXGMJF: "请先购买积分成为VIP会员",
    YQHY: "邀请好友",
    ZWSQ: "暂无社区",

    VIP1: "初级",
    VIP2: "中级",
    VIP3: "初级",
    VIP4: "高级",
    GZSM: "规则说明",
    GUIZHE: `
    <h3>VIP会员:</h3>
          <p>只要购买过一次积分套餐的用户即为VIP会员。未镜像他人的用户结束机器人后统一扣除等值盈利部分10%的积分；已镜像他人的用户结束机器人后统一扣除等值盈利部分20%的积分。</p>

          <h3>激励机制一 ——推荐奖（秒结）：</h3>
          <p>
            直推用户可获得服务积分的10%作为奖励；<br></br>
            间推用户可获得服务积分的5%作为奖励。</p>

          <h3>激励机制二 ——社区奖（级差/日结）:</h3>
          <p>
            初级VIP：直推5个VIP会员即可成为初级VIP，可获得服务积分的5%作为奖励；<br></br>
            中级VIP：直推3个初级VIP即可成为中级VIP，可获得服务积分的10%作为奖励；<br></br>
            高级VIP：直推3个中级VIP即可成为高级VIP，可获得服务积分的16%作为奖励。
          </p>

          <h3>激励机制三 ——分红奖（日结）:</h3>
          <p>
            所有初级VIP按业绩比例加权分3%；<br></br>
            所有中级VIP按业绩比例加权分3%；<br></br>
            所有高级VIP按业绩比例加权分3%。
          </p>
          <h3>激励机制四 ——镜像分红（按策略结算）:</h3>
          <p>
           开启镜像策略的，可获得镜像用户消耗服务积分的40%作为奖励。<br></br>
          </p>
          <h3>特别说明:</h3>
          <p>  
            以上规则均通过Tradease机器人APP客户端自动运行，如有人以代管等任何形式索取佣金，请勿相信！
          </p> 
    `,
  },
  // 用户协议
  AGREEMENTS: {
    USER_AGREEMENTS: "用户协议",
    XIEYI: ` <p>Tradease机器人在此特别提醒您（用户）在注册成为用户之前，请认真阅读本《用户协议》（以下简称“Tradease机器人用户协议”），确保您充分理解本协议中各条款。请您审慎阅读并选择接受或不接受本协议。除非您接受本协议所有条款，否则您无权注册、登录或使用本协议所涉服务。您的注册、登录、使用等行为将视为对本协议的接受，并同意接受本协议各项条款的约束。
    本协议约定Tradease机器人与用户之间关于“Tradease机器人”软件服务（以下简称“服务”）的权利义务。“用户”是指注册、登录、使用本服务的个人。本协议可由Tradease机器人随时更新，
    更新后的协议条款壹旦公布即代替原来的协议条款，恕不再另行通知，用户可在本网站查阅最新版协议条款。在Tradease机器人修改协议条款后，如果用户不接受修改后的条款，请立即停止使用Tradease机器人提供的服务，用户继续使用Tradease机器人提供的服务将被视为接受修改后的协议。
</p>
    <p>本协议约定Tradease机器人与用户之间关于“Tradease机器人”软件服务（以下简称“服务”）的权利义务。“用户”是指注册、登录、使用本服务的个人。本协议可由Tradease机器人随时更新，
    更新后的协议条款壹旦公布即代替原来的协议条款，恕不再另行通知，用户可在本网站查阅最新版协议条款。在Tradease机器人修改协议条款后，如果用户不接受修改后的条款，请立即停止使用Tradease机器人提供的服务，用户继续使用Tradease机器人提供的服务将被视为接受修改后的协议。
</p>
    <h3>一、账号注册</h3>
    <p>1、用户在使用本服务前需要注册壹个“Tradease机器人”账号。“Tradease机器人”账号应当使用手机号码绑定注册，请用户使用尚未与“Tradease机器人”账号绑定的手机号码，以及未被Tradease机器人根据本协议封禁的手机号码注册“Tradease机器人”账号。Tradease机器人可以根据用户需求或产品需要对账号注册和绑定的方式进行变更，而无须事先通知用户。</p>
    <p>2、“Tradease机器人”系授权Tradease机器人地理位置和壹切相关的个人信息的移动社交产品。故用户完成注册即表明用户同意Tradease机器人提取、公开及使用用户的地理位置信息。</p>
    <p>3、鉴于“Tradease机器人”账号的绑定注册方式，您同意Tradease机器人在注册时将使用您提供的手机号码及/或自动提取您的手机号码及自动提取您的手机设备识别码等信息用于注册。</p>
    <p>4、在用户注册及使用本服务时， Tradease机器人需要搜集能识别用户身份的个人信息以便Tradease机器人可以在必要时联系用户，或为用户提供更好的使用体验。Tradease机器人搜集的信息包括但不限于用户的姓名、性别、年龄、出生日期、身份证号、地址、学校情况、公司情况、所属行业、兴趣爱好、常出没的地方、个人说明；Tradease机器人同意对这些信息的使用将受限于第三条用户个人隐私信息保护的约束。</p>
    <h3>二、服务内容</h3>
    <p>本服务的具体内容由Tradease机器人根据实际情况提供，包括但不限于授权用户通过其账号进行实时通讯、添加好友、加入群组、关注他人、发布留言。Tradease机器人可以对其提供的服务予以变更，且Tradease机器人提供的服务内容可能随时变更；用户将会收到Tradease机器人关于服务变更的通知。</p>
    <h3>三、用户个人隐私信息保护</h3>
    <p>1、用户在注册账号或使用本服务的过程中，可能需要填写或提交壹些必要的信息，如法律法规、规章规范性文件（以下称“法律法规”）规定的需要填写的身份信息。如用户提交的信息不完整或不符合法律法规的规定，则用户可能无法使用本服务或在使用本服务的过程中受到限制。</p>
    <p>2、个人隐私信息是指涉及用户个人身份或个人隐私的信息，比如，用户真实姓名、身份证号、手机号码、手机设备识别码、IP地址、用户聊天记录。非个人隐私信息是指用户对本服务的操作状态以及使用习惯等明确且客观反映在Tradease机器人服务器端的基本记录信息、
个人隐私信息范围外的其它普通信息，以及用户同意公开的上述隐私信息。</p>
    <p>3、尊重用户个人隐私信息的私有性是Tradease机器人的壹贯制度，Tradease机器人将采取技术措施和其他必要措施，确保用户个人隐私信息安全，防止在本服务中收集的用户个人隐私信息泄露、毁损或丢失。 在发生前述情形或者Tradease机器人发现存在发生前述情形的可能时，将及时采取补救措施。</p>
    <p>Tradease机器人未经用户同意不向任何第三方公开、 透露用户个人隐私信息。但以下特定情形除外：
      <br></br>(1)Tradease机器人根据法律法规规定或有权机关的指示提供用户的个人隐私信息；
      <br></br>(2)由于用户将其用户密码告知他人或与他人共享注册账户与密码，由此导致的任何个人信息的泄漏，或其他非因Tradease机器人原因导致的个人隐私信息的泄露；
      <br></br>(3)用户自行向第三方公开其个人隐私信息；
      <br></br>(4)用户与Tradease机器人及合作单位之间就用户个人隐私信息的使用公开达成约定，Tradease机器人因此向合作单位公开用户个人隐私信息；
      <br></br>(5)任何由于黑客攻击、计算机病毒侵入及其他不可抗力事件导致用户个人隐私信息的泄露。
      <br></br>(6)用户在使用过程中存在主动散布政治有害、淫秽色情、广告、血腥暴力、诽谤侮辱、泄露个人隐私等信息。
    </p>
    <p>5、用户同意Tradease机器人可在以下事项中使用用户的个人隐私信息
      <br></br>(1)Tradease机器人向用户及时发送重要通知，如软件更新、本协议条款的变更；
      <br></br>(2) Tradease机器人内部进行审计、数据分析和研究等，以改进Tradease机器人的产品、服务和与用户之间的沟通;
      <br></br>(3) 依本协议约定，Tradease机器人管理、审查用户信息及进行处理措施；
      <br></br>(4) 适用法律法规规定的其他事项。
      <br></br>除上述事项外，如未取得用户事先同意，Tradease机器人不会将用户个人隐私信息使用于任何其他用途。
    </p>
    <p>6、Tradease机器人重视对未成年人个人隐私信息的保护。
    Tradease机器人将依赖用户提供的个人信息判断用户是否为未成年人。
    任何18岁以下的未成年人注册账号或使用本服务应事先取得家长或其法定监护人（以下简称"监护人"）的书面同意。
    除根据法律法规的规定及有权机关的指示披露外，
    Tradease机器人不会使用或向任何第三方透露未成年人的聊天记录及其他个人隐私信息。除本协议约定的例外情形外，
      未经监护人事先同意，Tradease机器人不会使用或向任何第三方透露未成年人的个人隐私信息。</p>
    <p>7、用户确认，其地理位置信息为非个人隐私信息，用户成功注册Tradease机器人”账号视为确认授权Tradease机器人
提取、公开及使用用户的地理位置信息。用户地理位置信息将作为用户公开资料之壹，
      由Tradease机器人向其他用户公开。如用户需要终止向其他用户公开其地理位置信息，可随时自行设置为隐身状态。</p>
    <p>8、为了改善Tradease机器人的技术和服务，向用户提供更好的服务体验，
      Tradease机器人或可会自行收集使用或向第三方提供用户的非个人隐私信息。</p>
    <h3>四、内容规范</h3>
    <p>1、本条所述内容是指用户使用本服务过程中所制作、上载、复制、发布、传播的任何内容，
    包括但不限于账号头像、名称、用户说明等注册信息及认证资料，或文字、语音、图片、视频、图文等发送、
      回复或自动回复消息和相关链接页面，以及其他使用账号或本服务所产生的内容。</p>
    <p>2、用户不得利用Tradease机器人”账号或本服务制作、上载、复制、发布、传播如下法律、法规和政策禁止的内容：
      <br></br>(1) 反对宪法所确定的基本原则的；
      <br></br>(2) 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
      <br></br>(3) 损害国家荣誉和利益的；
      <br></br>(4) 煽动民族仇恨、民族歧视，破坏民族团结的；
      <br></br>(5) 破坏国家宗教政策，宣扬邪教和封建迷信的；
      <br></br>(6) 散布谣言，扰乱社会秩序，破坏社会稳定的；
      <br></br>(7) 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
      <br></br>(8) 侮辱或者诽谤他人，侵害他人合法权益的；
      <br></br>(9) 不遵守法律法规底线、社会主义制度底线、国家利益底线、公民合法权益底线、社会公共秩序底线、道德风尚底线和信息真实性底线的“七条底线”要求的；
      <br></br>(10) 含有法律、行政法规禁止的其他内容的信息。
    </p>
    <p>3、用户不得利用Tradease机器人”账号或本服务制作、上载、复制、发布、
    传播如下干扰Tradease机器人”正常运营，以及侵犯其他用户或第三方合法权益的内容：
      <br></br>(1) 含有任何性或性暗示的；
      <br></br>(2) 含有辱骂、恐吓、威胁内容的；
      <br></br>(3) 含有骚扰、垃圾广告、恶意信息、诱骗信息的；
      <br></br>(4) 涉及他人隐私、个人信息或资料的；
      <br></br>(5) 侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的；
      <br></br>(6) 含有其他干扰本服务正常运营和侵犯其他用户或第三方合法权益内容的信息。

    </p>
    <h3>五、使用规则</h3>
    <p>1、用户在本服务中或通过本服务所传送、发布的任何内容并不反映或代表，
      也不得被视为反映或代表Tradease机器人的观点、立场或政策，Tradease机器人对此不承担任何责任。</p>
    <p>2、用户不得利用Tradease机器人”账号或本服务进行如下行为：
      <br></br>(1) 提交、发布虚假信息，或盗用他人头像或资料，冒充、利用他人名义的；
      <br></br>(2) 强制、诱导其他用户关注、点击链接页面或分享信息的；
      <br></br>(3) 虚构事实、隐瞒真相以误导、欺骗他人的；
      <br></br>(4) 利用技术手段批量建立虚假账号的；
      <br></br>(5) 利用Tradease机器人”账号或本服务从事任何违法犯罪活动的；
      <br></br>(6) 制作、发布与以上行为相关的方法、工具，或对此类方法、工具进行运营或传播，
      无论这些行为是否为商业目的；
      <br></br>(7) 其他违反法律法规规定、侵犯其他用户合法权益、
      干扰Tradease机器人”正常运营或深圳越界创新科技有限公司未明示授权的行为。
    </p>
    <p>3、用户须对利用Tradease机器人”账号或本服务传送信息的真实性、合法性、无害性、准确性、
    有效性等全权负责，与用户所传播的信息相关的任何法律责任由用户自行承担，与Tradease机器人无关。
      如因此给Tradease机器人或第三方造成损害的，用户应当依法予以赔偿。</p>
    <p>4、Tradease机器人提供的服务中可能包括广告，用户同意在使用过程中显示Tradease机器人和第三方供应商、
    合作伙伴提供的广告。除法律法规明确规定外，用户应自行对依该广告信息进行的交易负责，
      对用户因依该广告信息进行的交易或前述广告商提供的内容而遭受的损失或损害，Tradease机器人不承担任何责任。</p>
    <h3>六、账户管理</h3>
    <p>1、 Tradease机器人”账号的所有权归Tradease机器人所有，
    用户完成申请注册手续后，获得Tradease机器人”账号的使用权，
    该使用权仅属于初始申请注册人，禁止赠与、借用、租用、转让或售卖。Tradease机器人因经营需要，
      有权回收用户的Tradease机器人”账号。</p>
    <p>2、用户可以更改、删除Tradease机器人”账户上的个人资料、
      注册信息及传送内容等，但需注意，删除有关信息的同时也会删除用户储存在系统中的文字和图片，用户需承担该风险。</p>
    <p>3、用户有责任妥善保管注册账号信息及账号密码的安全，
    因用户保管不善可能导致遭受盗号或密码失窃，责任由用户自行承担。
    用户需要对注册账号以及密码下的行为承担法律责任。用户同意在任何情况下不使用其他用户的账号或密码
      。在用户怀疑他人使用其账号或密码时，用户同意立即通知Tradease机器人。</p>
    <p>4、用户应遵守本协议的各项条款，正确、适当地使用本服务，如因用户违反本协议中的任何条款，
    Tradease机器人在通知用户后有权依据协议中断或终止对违约用户Tradease机器人”账号提供服务。
      同时，Tradease机器人保留在任何时候收回Tradease机器人”账号、用户名的权利。</p>
    <p>5、如用户注册Tradease机器人”账号后壹年不登录，通知用户后，Tradease机器人可以收回该账号，
      以免造成资源浪费，由此造成的不利后果由用户自行承担。</p>
    <h3>七、数据储存</h3>

    <p>1、Tradease机器人不对用户在本服务中相关数据的删除或储存失败负责。</p>
    <p>2、Tradease机器人可以根据实际情况自行决定用户在本服务中数据的最长储存期限，
      并在服务器上为其分配数据最大存储空间等。用户可根据自己的需要自行备份本服务中的相关数据。</p>
    <p>3、如用户停止使用本服务或本服务终止，Tradease机器人可以从服务器上永久地删除用户的数据。
      本服务停止、终止后，Tradease机器人没有义务向用户返还任何数据。</p>
    <h3>八、风险承担</h3>
    <p>1、用户理解并同意，Tradease机器人”仅为用户提供信息分享、传送及获取的平台，
    用户必须为自己注册账号下的壹切行为负责，包括用户所传送的任何内容以及由此产生的任何后果。
    用户应对Tradease机器人”及本服务中的内容自行加以判断，并承担因使用内容而引起的所有风险，
    包括因对内容的正确性、完整性或实用性的依赖而产生的风险。
    Tradease机器人无法且不会对因用户行为而导致的任何损失或损害承担责任。
    如果用户发现任何人违反本协议约定或以其他不当的方式使用本服务，
      请立即向Tradease机器人举报或投诉，Tradease机器人将依本协议约定进行处理。</p>
    <p>2、用户理解并同意，因业务发展需要，Tradease机器人保留单方面对本服务的全部或部分服务内容变更、
      暂停、终止或撤销的权利，用户需承担此风险。</p>
    <h3>九、知识产权声明</h3>
    <p>1、除本服务中涉及广告的知识产权由相应广告商享有外，（Tradease机器人）在本服务中提供的内容
    （包括但不限于网页、文字、图片、音频、视频、图表等）的知识产权均归（Tradease机器人）所有，
      但用户在使用本服务前对自己发布的内容已合法取得知识产权的除外。</p>
    <p>2、除另有特别声明外，（Tradease机器人）提供本服务时所依托软件的著作权、
      专利权及其他知识产权均归（Tradease机器人）所有。</p>
    <p>3、（Tradease机器人）在本服务中所涉及的图形、文字或其组成，
    以及其他（Tradease机器人）标志及产品、服务名称（以下统称“（Tradease机器人）标识”）
    ，其著作权或商标权归（Tradease机器人）所有。未经（Tradease机器人）事先书面同意，
    用户不得将（Tradease机器人）标识以任何方式展示或使用或作其他处理，
      也不得向他人表明用户有权展示、使用、或其他有权处理（Tradease机器人）标识的行为。</p>
    <p>4、上述及其他任何（Tradease机器人）或相关广告商依法拥有的知识产权均受到法律保护，
      未经（Tradease机器人）或相关广告商书面许可，用户不得以任何形式进行使用或创造相关衍生作品。</p>
    <h3>十、法律责任</h3>

    <p>1、如果Tradease机器人发现或收到他人举报或投诉用户违反本协议约定的，
    Tradease机器人有权不经通知随时对相关内容，包括但不限于用户资料、聊天记录进行审查、删除，
      并视情节轻重对违规账号处以包括但不限于警告、账号封禁 、设备封禁 、功能封禁 的处罚，且通知用户处理结果。</p>
    <p>2、因违反用户协议被封禁的用户，可以联系Tradease机器人客服查询封禁期限，
    并在封禁期限届满后自助解封。其中，被实施功能封禁的用户会在封禁期届满后自动恢复被封禁功能。
      被封禁用户可向Tradease机器人客服提交申诉，Tradease机器人将对申诉进行审查，并自行合理判断决定是否变更处罚措施。</p>
    <p>3、用户理解并同意，Tradease机器人有权依合理判断对违反有关法律法规或本协议规定的行为进行处罚，
    对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，
      用户应承担由此而产生的壹切法律责任。</p>
    <p>4、用户理解并同意，因用户违反本协议约定，导致或产生的任何第三方主张的任何索赔、要求或损失，
      包括合理的律师费，用户应当赔偿Tradease机器人与合作公司、关联公司，并使之免受损害。</p>
    <h3>十一、不可抗力及其他免责事由</h3>

    <p>1、用户理解并确认，在使用本服务的过程中，可能会遇到不可抗力等风险因素，
    使本服务发生中断。不可抗力是指不能预见、不能克服并不能避免且对壹方或双方造成重大影响的客观事件，
    包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为等。出现上述情况时，
    Tradease机器人将努力在第壹时间与相关单位配合，及时进行修复，但是由此给用户或第三方造成的损失，
      Tradease机器人及合作单位在法律允许的范围内免责。</p>
    <p>2、本服务同大多数互联网服务壹样，受包括但不限于用户原因、网络服务质量、
    社会环境等因素的差异影响，可能受到各种安全问题的侵扰，如他人利用用户的资料，
    造成现实生活中的骚扰；用户下载安装的其它软件或访问的其他网站中含有“特洛伊木马”等病毒，
    威胁到用户的计算机信息和数据的安全，继而影响本服务的正常使用等等。用户应加强信息安全及使用者资料的保护意识，
      要注意加强密码保护，以免遭致损失和骚扰。</p>
    <p>3、用户理解并确认，本服务存在因不可抗力、计算机病毒或黑客攻击、系统不稳定、用户所在位置、
    用户关机以及其他任何技术、互联网络、通信线路原因等造成的服务中断或不能满足用户要求的风险，
      因此导致的用户或第三方任何损失，Tradease机器人不承担任何责任。</p>
    <p>4、用户理解并确认，在使用本服务过程中存在来自任何他人的包括误导性的、
    欺骗性的、威胁性的、诽谤性的、令人反感的或非法的信息，或侵犯他人权利的匿名或冒名的信息，
      以及伴随该等信息的行为，因此导致的用户或第三方的任何损失，Tradease机器人不承担任何责任。</p>
    <p>5、用户理解并确认，Tradease机器人需要定期或不定期地对Tradease机器人”平台或相关的设备进行检修或者维护，
      如因此类情况而造成服务在合理时间内的中断，Tradease机器人无需为此承担任何责任，Tradease机器人应事先进行通告。</p>
    <p>6、Tradease机器人依据法律法规、本协议约定获得处理违法违规或违约内容的权利，
      该权利不构成Tradease机器人的义务或承诺，Tradease机器人不能保证及时发现违法违规或违约行为或进行相应处理。</p>
    <p>7、用户理解并确认，对于Tradease机器人向用户提供的下列产品或者服务的质量缺陷及其引发的任何损失，
      Tradease机器人无需承担任何责任：(1) Tradease机器人向用户免费提供的服务；</p>
    <p>8、在任何情况下，Tradease机器人均不对任何间接性、后果性、惩罚性、偶然性、特殊性或刑罚性的损害，
    包括因用户使用Tradease机器人”或本服务而遭受的利润损失，
    承担责任（即使Tradease机器人已被告知该等损失的可能性亦然）。尽管本协议中可能含有相悖的规定，
    Tradease机器人对用户承担的全部责任，无论因何原因或何种行为方式，始终不超过用户因使用Tradease机器人
提供的服务而支付给Tradease机器人的费用(如有)。</p>
    <h3>十二、服务的变更、中断、终止</h3>
    <p>1、鉴于网络服务的特殊性，用户同意Tradease机器人有权随时变更、
    中断或终止部分或全部的服务（包括收费服务）。Tradease机器人变更、
    中断或终止的服务，Tradease机器人应当在变更、中断或终止之前通知用户，
      并应向受影响的用户提供等值的替代性的服务。</p>
    <p>2、如发生下列任何壹种情形，Tradease机器人有权变更、中断或终止向用户提供的免费服务
    ，而无需对用户或任何第三方承担任何责任：
    (1) 根据法律规定用户应提交真实信息，而用户提供的个人资料不真实
    或与注册时信息不壹致又未能提供合理证明；(2) 用户违反相关法律法规或本协议的约定；
    (3) 按照法律规定或有权机关的要求；(4) 出于安全的原因或其他必要的情形。
    </p>
    <h3>十三、其他</h3>

    <p>1、Tradease机器人郑重提醒用户注意本协议中免除其责任和限制用户权利的条款，
      请用户仔细阅读，自主考虑风险。未成年人应在法定监护人的陪同下阅读本协议。</p>
    <p>2、本协议的效力、解释及纠纷的解决，适用于中华人民共和国法律。
    若用户和Tradease机器人之间发生任何纠纷或争议，首先应友好协商解决，协商不成的，
      用户同意将纠纷或争议提交法院。</p>
    <p>3、本协议的任何条款无论因何种原因无效或不具可执行性，其余条款仍有效，对双方具有约束力。</p> 
    <p  class='redtext'>
    投资需谨慎，入市有风险！<br>
    本软件所提供的策略与软件中镜像等功能的使用只作为参考，投资品种及产品均不构成投资建议，镜像等功能产生的亏损将由用户自身承担，请再次确认。</p>
    `,
  },

  EXCEPTION: {},
  // 策略相关
  CELUE: {
    // 新增排序
    ORDERBY_START: "按启动时间",
    ORDERBY_INVEST: "按投资额",
    ORDERBY_DEFAULT: "默认排序",
    ORDERBY: "排序方式",
    // 策略页面
    MDCL: "马丁策略",
    WGCL: "网格策略",
    DTCL: "定投策略",
    HYCL: "合约策略",
    // -------------------
    MD: "马丁",
    WG: "网格",
    DT: "定投",
    HY: "合约",
    // ------------------------
    GBZ: "关闭中",
    CZCG: "操作成功",
    QBDAPI: "请绑定api",
    CJMDJQR: "创建马丁机器人",
    MDCLJRZYL: "马丁策略今日总盈利（USDT）",
    MDLSZYL: "马丁历史总盈利：",
    WDJQR: "我的机器人",
    QB: "全部",
    LSJQR: "历史机器人",
    ZWJRR: "暂无机器人",
    YGB: "已关闭",
    YCZ: "已重置",
    YXSC: "运行时长：",
    CJ: "创建",
    TZE: "投资额",
    ZLR: "总利润",
    MDLR: "马丁利润",
    CCZE: "持仓总额",
    CCL: "持仓量",
    CCDS: "持仓单数",
    CCJJ: "持仓均价",
    DQJG: "当前价格",
    QDJQR: "启动机器人",
    ZTJQR: "暂停机器人",
    KQMR: "开启买入",
    ZTMR: "暂停买入",
    JQRXQ: "机器人详情",
    JXJQRBKCZ: "镜像机器人不可操作",
    ZWBDJYSAPI: "暂未绑定交易所API",
    LJBD: "立即绑定",
    CJWGJRQ: "创建网格机器人",
    WGCLJRZYL: "网格策略今日总盈利（USDT）",
    WGLSZYL: "网格历史总盈利：",
    WGLR: "网格利润",
    WGZDJ: "网格最低价",
    WGZGJ: "网格最高价",
    CJDTJQR: "创建定投机器人",
    DTCLJRZYL: "定投策略今日总盈利（USDT）",
    DTLSZYL: "定投历史总盈利：",
    DTLR: "定投利润",
    MRJ: "买入价",
    ZYBL: "止盈比例",
    ZSBL: "止损比例",
    TZCF: "停止重复",
    CFZX: "重复执行",
    GBJQR: "关闭机器人",
    GBJQRHWFCQ: "关闭机器人后将无法重启，请确认是否需要帮您自动卖成USDT？",
    QBWASCJMC: "请帮我按照市场价卖出",
    BYLWZJM: "不用了,我自己卖",
    GBJQRWFCQ: "关闭机器人后将无法重启，请确认是否关闭？",
    QX: "取消",
    OK: "确认",
    XZCFJQRH:
      "选择停止重复执行后，机器人将在本次止盈后停止继续执行策略，确定停止重复执行吗？",
    XZTZJQRH:
      "选择重复执行后，机器人将在本次止盈后以目标买入价继续执行策略，确定重复执行吗？",
    ZTJQRH: "暂停机器人后，系统既不会买入又不会卖出，您确定要暂停机器人么？",
    QDJQRH: "启动机器人后，系统恢复买入卖出，您确定要启动机器人么？",
    KQMRH: "开启买入后，系统自动买入卖出，您确定要开启买入么？",
    ZTMRH: "暂停买入后，系统不会自动买入但是不影响卖出，您确定要暂停买入么？",

    // api授权
    APISQ: "授权",
    QSRKEY: "请输入从交易所创建的API KEY和API SECRET",
    CHAPIJC: "创建API教程",
    OK: "确认",
    APITS:
      "完成API授权后才能开始策略，请先前往对应交易所创建API，并在本页中完成绑定授权",
    QSQ: "去授权",
    // 选择币种
    XZBZ: "选择币种",
    SSBZ: "搜索币种",
    ZX: "自选",
    QB: "全部",
    ZWZX: "暂无自选",
    // 历史机器人
    MD: "马丁",
    WG: "网格",
    DT: "定投",
    HY: "合约",
    // 选择交易所
    XZJYS: "选择交易所",
    XZJYSSM: "从已授权绑定的交易所中选择一个来启动策略",
    JYS: "交易所",
  },
  // 创建机器人
  CHUANGJIAN: {
    // 定投
    ZYJBDXYXJ: "止盈价不得小于现价",
    ZXTZ: "最小投资",
    ZYBLBDXY: "止盈比例不得小于",
    ZTZEBDXY: "总投资额不得小于",
    XHCZBLZX: "循环重置比例最小为0.5%",
    CJDTJQR: "创建定投机器人",
    SZ: "设置",
    YSJMR: "以市价买入",
    YXJMR: "以限价买入",
    MRJG: "买入价格",
    ZSMJGMR: "在什么价格买入",
    ZYJ: "止盈价",
    JGDDDSSMC: "价格达到多少时卖出",
    ZYBL: "止盈比例",
    ZDYLDSSMC: "最低盈利多少时卖出",
    ZHU: "注：达到止盈比例后并不直接卖出，回调时卖出",
    ZTZE: "总投资额",
    ZXTZ: "最小投资",
    KYZJ: "可用资金:",
    QRCS: "确认参数",
    ZSBL: "止损比例",
    SR0BZDZS: "输入0则为不自动止损",
    HTMRBL: "回调买入比例",
    JGDDZDDDSSMR: "价格达到最低点回调多少时买入",
    HTMCBL: "回调卖出比例",
    JGDDZGDDSSMC: "价格达到最高点回调多少时卖出",
    XHCZBL: "循环重置比例",
    XHSMRJGXTBL: "循环时买入价格下调比例",
    FQ: "放弃",
    QRSZ: "确认设置",
    // 马丁
    ZXTZEW: "最小投资额为",
    ZDSLQJW: "做单数量区间为",
    BCBLQJ: "补仓比例区间",
    BCZFBLQJW: "补仓增幅比例区间为",
    ZYBLQJW: "止盈比例区间为",
    BCBSQJW: "补仓倍数区间为",
    CJMDJQR: "创建马丁机器人",
    GQQRNH: "过去七日年化",
    AICL: "AI策略",
    DCZDTXCS: "点此自动填写参数",
    SYAICL: "使用AI策略",
    SDSZ: "手动设置",
    ZDSL: "做单数量",
    ZDXXBCCS: "最多向下补仓的次数",
    BCBL: "补仓比例",
    MCBCDBL: "每次补仓的比例",
    BCZFBL: "补仓增幅比例",
    ZBCBLSDZF: "在补仓比例上的增幅",
    ZYBL: "止盈比例",
    YJMCSDYLBL: "预计卖出时的盈利比例",
    MCZF: "每次增幅",
    BCBS: "补仓倍数",
    // 网格
    WGS: "网格数",
    QJJGBHF: "区间价格不合法",
    MGZXLRW: "每格利润最小为",
    CJWWGJQR: "创建网格机器人",
    NRW: "你认为",
    ZDJ: "最低价",
    ZGJ: "最高价",
    QJSXJG: "区间上限价格",
    QJXXJG: "区间下限价格",
    WGSL: "网格数量",
    MGLR: "每格利润:",
    JGFW: "价格范围",
    HD: "滑点",
    // ---启动
    XDZ: "下单中",
    CZDTJQR: "创建定投机器人",
    DQJG: "当前价格",
    SJMRJSM: "实际买入价以交易所返回成交均价为准",
    ZDSHCJ: "涨多少后成交",
    MCJG: "卖出价格",
    YJZTZE: "预计总投资额",
    KYYE: "可用余额",
    QDJQR: "启动机器人",
    CJMDJQR: "创建马丁机器人",
    JG: "价格",
    TZE: "投资额",
    SL: "数量",
    CJWGJQR: "创建网格机器人",
    WGQJ: "网格区间",
  },
  // 机器人详情
  XIANGQING: {
    GDXQ: "挂单详情",
    JYJL: "交易记录",
    JQRCS: "机器人参数",
    DTJQRXQ: "定投机器人详情",
    DQJG: "当前价格",
    BUY: "买入",
    SELL: "卖出",
    CCSL: "持仓数量",
    CCJJ: "持仓均价",
    JQRZT: "机器人状态",
    DDMR: "等待买入",
    JHMRJ: "计划买入价",
    ZDMRJ: "最低买入价",
    JQRZT: "机器人状态",
    DDMC: "等待卖出",
    SJMRJ: "实际买入价",
    MBZYJ: "目标止盈价",
    ZGMCJ: "最高卖出价",
    ZSJ: "止损价",
    DQJGGYMR: "当前价格高于最低买入价且低于计划买入价时买入",
    DQJGDYMC:
      "当前价格高于目标止盈价且低于最高卖出价时止盈卖出；当前价格低于止损价时止损卖出",
    JQRYBTZ: "机器人已经被停止",
    JXJQRBKCK: "镜像机器人不可查看",
    XXTLCS: "24H套利次数",
    ZTLCS: "总套利次数",
    RJCS: "日均次数",
    TLJL: "套利记录",
    DTJQRWCYCMRMC: "定投机器人完成一次买入和对应卖出后视为一次套利",
    MDJQRWCYCMRMC: "马丁机器人完成一次买入和对应卖出后视为一次套利",
    BDJQRWCYCMRMC: "布丁机器人完成一次买入和对应卖出后视为一次套利",
    WGJQRWCYCMRMC: "网格机器人完成一次买入和对应卖出后视为一次套利",
    HYJQRWCYCMRMC: "合约机器人完成一次买入和对应卖出后视为一次套利",
    ZLR: "总利润",
    CJJJ: "成交均价",
    CJL: "成交量",
    CJE: "成交额",
    DTCLCS: "定投策略参数",
    MDCLCS: "马丁策略参数",
    WGCLCS: "网格策略参数",
    JYBD: "交易标的",
    ZTZE: "总投资额",
    ZYBL: "止盈比例",
    JHMRJG: "计划买入价格",
    HTMRBL: "回调买入比例",
    HTMCBL: "回调卖出比例",
    ZSBL: "止损比例",
    XHCZBL: "循环重置比例",
    //
    MDJQRXQ: "马丁机器人详情",
    JG: "价格",
    TZE: "投资额",
    SL: "数量",
    YMR: "已买入",
    DHT: "待回调",
    SDTZE: "首单投资额",
    JHZD: "计划做单",
    BCBL: "补仓比例",
    BCZF: "补仓增幅比例",
    BCBS: "补仓倍数",
    //
    WGJQRXQ: "网格机器人详情",
    MRJG: "买入价格",
    ZDSHCJ: "涨多少后成交",
    MCJG: "卖出价格",
    JCSMRSL: "建仓时买入数量",
    JCSMRJJ: "建仓时买入均价",
    QJSXJG: "区间上限价格",
    QJXXJG: "区间下限价格",
    WGSL: "网格数量",
    MGLR: "每格利润",
    HD: "滑点",
    MGMMSL: "每格买卖数量",
  },
  ABOUTUS: {
    GYWM: "关于我们",
    SM: `
    <p>Tradease量化机器人是Tradease科技推出的跨加密货币交易平台的三方交易策略软件，通过可自由配置的量化策略来帮助用户自动交易套利。</p>
    <p>本平台将通过不断创新并且深化可为用户服务的量化工具，包括但不限于网格交易策略/马丁交易策略/定投交易策略等多种丰富的功能，让用户不用盯盘也可以轻松获利。</p>
    <p>感谢您的信任，有任何意见或建议，请您通过工单系统提交至客服，或在微信公众号及微博联系我们。</p>
    <p></p>
    <p></p>
    `,
  },
};
