import UserApi from './user';
import OtherApi from "./other";
import WalletApi from "./wallet";
import propertyM4DApi from "./propertyM4D";
import RobotApi from "./robot";


export {
  UserApi,
  OtherApi,
  WalletApi,
  propertyM4DApi,//资产
  RobotApi,
};
