export default {
  YISHUPLUSH: {
    HUOBI:'Huobi',
    XINSHOUZHINAN: "가이드",
    JIARUSHEQU: "커뮤니티",
    HUODELIRUNKOUCHU: "획득 시 차감",
    JQRXQ: "상세정보",
    MC: "풀 포지션",
    FC: "부분 포지션",
    HY: "선물",
    XH: "현물",
    HYSYPH: "선물 순위",
    XHSYPH: "현물 순위",
    ZCGZ: "가치",
    ZCZH: "포트폴리오",
    FCGL: "부분 관리",
    CJMCJQR: "로봇 생성",
    CJFCJQR: "로봇 생성",
    MCCLZYL: "오늘 수익",
    MCCLLSZYL: "총 수익",
    FCCLZYL: "오늘 수익",
    FCCLLSZYL: "총 수익",
    BEI: "배",
    GUZHI: "가치",
    LIRUN: "이익",
    KCW: "진입 포지션",
    ZYFS: "수익 실현 방법",
    BFB: "퍼센트",
    KD: "롱",
    PD: "롱 종료",
    KK: "숏",
    PK: "숏 종료",
    SK: "롱 & 숏",
    CLCS: "파라미터",
    KCFX: "진입 방향",
    GGBS: "레버리지",
    KDZB: "롱 지표",
    XZDDKCW: "롱 포지션",
    DDZYFS: "롱 수익 실현",
    DDZYZB: "롱 수익 실현 지표",
    DDZYBL: "롱 수익 실현 비율",
    DDZYCHBL: "롱 추적 수익 실현 비율",
    DDZSBL: "롱 손절 비율",
    XT: "선택사항",
    KKZB: "숏 지표",
    XZKDKCW: "숏 포지션",
    KDZYFS: "숏 수익 실현",
    KDZYZB: "숏 수익 실현 지표",
    KDZYBL: "숏 수익 실현 비율",
    KDZYHCCBL: "숏 추적 수익 실현 비율",
    KDZSBL: "숏 손절 비율",

    KCZB: "진입 지표",
    ZYTJ: "수익 조건",
    BCFZZB: "보충 포지션 보조 지표",
    XZBCW: "보충 포지션",
    DX: "복수 선택",
    KCTJ: "진입 조건",
    FCJQRXQ: "부분 포지션 로봇 상세정보",
    MCJQRXQ: "풀 포지션 로봇 상세정보",
    ZDSL: "주문 수량",
    ZYBL: "수익 비율",
    ZYHCBL: "수익 추적 비율",
    HTMCBL: "콜백 판매 비율",
    YL: "미리보기",
    BCW: "보충 포지션",
    DENGDAI: "대기",
    FCCL: "부분 포지션 전략",
    JYCL: "거래 전략",
    ZYZB: "수익 지표",
    DUODAN: "롱 포지션",
    KONGDAN: "숏 포지션",

    ZJMCJ: "최적 판매 가격",
  },
  NEWGN: {
    BD: "푸딩",
    BDCL: "푸딩 전략",
    CJBDJRR: "푸딩 로봇 만들기",
    BDCLJRZYL: "푸딩 전략 총 이익 오늘",
    BDLSZYL: "푸딩 역사적 총 이익",
    BDLR: "푸딩 이익",
    BDJRRXQ: "푸딩 로봇 세부 정보",
    XYGZCW: "다음 지원 수준",
    ZCWZDSLBF:
      "지원 수준 수가 주문 수와 일치하지 않습니다. 지원 수준 수를 다음과 같이 선택해야 합니다.",
    YL: "시사",
    XRCLK: " 전략 라이브러리 ",
    ZCW: "지원하다",
    FZXG: "트랙을 벗어난 시간",
    XSXG: "쉬는 시간",
    RXG: "레일 아래",

    ZCFX: "자산 분석",
    ZZC: "총 자산",
    ZRSY: "어제의 수입",
    RSY7: "7일 수입",
    RSY30: "30일 수익",
    LSSY: "역사적 수익률",
    SYFX: "이익 분석",
    TLFX: "차익거래 분석",
    LJSYL: "누적수익률",
    LJSY: "누적 소득",
    MRSY: "일일 수입",
    LJTL: "누적 차익거래",
    MRTL: "일일 차익거래",
    DAY: "낮",
    MONTH: "달달",
  },

  XROBOT: {
    XSHUOMING:
      "X-Robot은 사용자가 전략 매개변수를 보다 공개적으로 설정할 수 있는 연구실이며, 전략을 저장하고 Martin Robots에 빠르게 적용할 수 있습니다.",
    ZDSL: "주문 수량",
    ZYBL: "테이크 프로핏 비율",
    BCBS: "여백 배수",
    HTMCBL: "콜백 대 판매 비율",
    YL: "시사",
    XTCLBKBJ: "시스템 정책을 수정할 수 없습니다.",
    BJ: "편집하다",
    ZWCL: "전략 없음",
    TJXCL: "새 전략 추가",
    BDTS: "푸딩 전략의 콜백 매수 비율이 적용되지 않습니다.",
    XBSYD: "기존 주문 보충 비율 대비",
    XBSYD2: "이전 주문과 비교하여 구매 비율이 콜백됩니다.",
    BJCL: "편집 전략",
    SC: "삭제",
    SZZDSL: "주문 수 설정",
    SZBCJH: "마진 콜 계획 설정",
    SZZYJH: "이익실현 계획을 세워라",
    ZXCL: "전략 선택",
    QD: "확신하는",
    CLMC: "전략명",
    QTXCLMC: "전략명을 입력해주세요",
    ZDBCS: "하향 보충의 최대 수, 최대 10개까지 채울 수 있습니다.",
    BCBSSM: "마지막 보충 금액 대비 각 보충 금액의 배수",
    ZDSLZD: "최대 주문 수는 10개입니다.",
    ZDSLZX: "최소 주문 수량은 2개입니다.",
    XYB: "다음 단계",
    SYB: "이전의",
    XBYCCJJZYBL: "평균 포지션 가격 대비 이익률을 취하십시오.",
    XBYCCJJHTMCBL: "평균 포지션 가격 대비 콜백 판매 비율",
    WC: "마치다",
  },
  MONI: {
    MNZH: "데모 계정",
    MNZHCSJE: "데모 계정의 초기 금액",
    MNSM: "데모 계정은 Binance Exchange에서 사용자의 트랜잭션을 시뮬레이션하는 데만 사용되며 트랜잭션 깊이와 같은 문제는 무시합니다.로봇을 생성할 때 시뮬레이션된 트랜잭션을 선택하여 로봇을 시작하고 Binance Exchange의 실시간 가격을 정산합니다. 모의 거래의 초기 금액은 시스템이 자동으로 설정되며 회전율을 계산하지 않으므로 패널을 통해 세부 사항을 확인하십시오.",
    CKMNZH: "데모 계정 보기",
    MN: "시뮬레이션",
    MNZHZCZE: "총 시뮬레이션 자산(U)",
  },
  HEYUE: {
    HY: "계약",
    HYCL: "계약 전략",
    HYCLJRZYL: "계약 전략 금일 총 이익",
    HYCLLSZYL: "계약 내역 총 이익:",
    BCTP: "사진 저장",
    BAHY: "Binance계약",
    CJHYJQR: "계약 로봇 만들기",
    KD: "BUY",
    KK: "SELL",
    KC: "창고를 열다",
    PC: "평평한 창고",
    ZYJ: "적립 가격",
    ZSJ: "손해 방지 가격",
    BZJLX: "보증 유형",
    QUANC: "완창",
    ZHUC: "창고별",
    HYJQRXQ: "계약로봇 상세 정보",
    DDKC: "창고 개설을 기다리다",
    JHKCJ: "계획발행가격",
    DDPC: "폐기 대기",
    SJKCJ: "실제 창고 개시 가격",
    HYJQRWCYCMRMC:
      "계약로봇은 1회 매수와 대응매도를 완료한 후 1회 차익으로 간주합니다.",
    KCJG: "창고 개시 가격",
    GGBS: "레버리지 승수",
    KCFX: "창고를 여는 방향",
    TDHYCCL: "TA의 계약 전략",
    QDMRJG: "먼저 매입 가격을 확정하십시오.",
    ZSBL: "손실 방지 비율",
    XGCS: "개정하다",
    CSCW: "️매개변수 오류!",
    FBT: "(필요하지 않음)",
  },

  // 补充
  ADD: {
    MDGD: "마틴",
    DTGD: "정투",
    WGGD: "메쉬",
    MD: "마틴",
    DT: "정투",
    WG: "메쉬",
    HB: "Huobi",
    BA: "Binance",
    FX: "그것을 공유",
    JYBD: "거래대상",
    SYCL: "사용 전략",
    SJSYL: "실제 수익률",
    YSJQR: "Tradease",
    NDLHGJ: "Make trade easy",
    ZSYL: "만점이 100 점이라서 100 점을 찍었습니다",
    FSYL: "통화 서클은 무자비하고 애정 어린",
  },
  // ----------其他----------
  ORDER: {
    FZCG: "복사 성공",
    FZSB: "복사 실패",
    ZWSJ: "데이터 없 음",
    QSRZFMM: "지불 비밀 번 호 를 입력 하 세 요.",
    QD: "확정 하 다.",
    KY: "사용 가능:",
    TIAN: "하늘.",
    SHI: "시.",
    FEN: "분.",
  },
  MIANBAN: {
    HB: "Huobi",
    BA: "Binance",
    OY: "OKEX",
    SYZJ: "잉여금",
    MDCL: "마틴의 전략",
    WGCL: "그리드 정책",
    DTCL: "결정 투표 전략",
    CXJJ: "다시 로드",
    BAZCZE: "Binance자산총액",
    HBZCZE: "Huobi 자산총액",
    SYZJZ: "주: 잉여 자금은 정책에 참여하지 않는 자산에 대한 환산입니다.",
    ZCXQ: "자산 상세 정보",
    DQMYKYPC: "현재 버릴 수 있는 통화가 없습니다.",
    YJPC: "원클릭 스플랫",
    BZ: "통화",
    SL: "수",
    YE: "잔고",
    KJY: "거래가능",
    ZWZC: "잠시 자산이 없으니 거래소에 가서 충전해주십시오",
    ZWBDJYSAPI: "거래소 API가 일시적으로 바인딩되지 않음",
    LJBD: "지금 바인딩",
    ZYSX: "주의 사항: 원클릭 스핀이 되면 모든 코인이 현재 시가로 매도되어 USDT가 되며 관련 로봇이 자동으로 종료되므로 주의하십시오! ! !",
    YXBZSL: "선택된 통화 수량：",
    PCYJKD: "보합권을 정리할 수 있을 것으로 예상하다.",
    QUANXUAN: "모두 선택",
    QRPC: "스플라인 확인",
    XUXIAO: "취소",
    QQRPC: "보안 암호를 입력하고 카트리지를 확인하십시오.",
    LHCL: "정량화 전략",
    ZCFB: "자산 분배",
  },
  // ---------------------------------登录-------------------------------------------
  LOGIN: {
    // ------------------login--------------
    LOGIN_SUCCESS: "로그 인 성공",
    PLEASE_RIGHT_PHONE_EMAIL: "정확 한 핸드폰 번호 나 메 일 을 입력 하 세 요.",
    NUM_ERROR: "계 정 비밀번호 오류",
    WELCOME: "로그 인 을 환영 합 니 다.",
    Tradease: "Tradease",
    LOGIN_TYPE: "핸드폰 번호 / 메 일주 소 / 아 이 디 로 로그 인하 세 요",
    PLEASE_INPUT: "핸드폰 번호 / 메 일주 소 / 아 이 디 를 입력 하 세 요",
    PLEASE_PASSWORD: "비밀 번 호 를 입력 하 세 요.",
    FORGET_PASSWORD: "비밀 번 호 를 잊어버리다.",
    LOGIN_NOW: "즉시 로그 인",
    NO_ACCOUNT: "계 정 이 없어 요？",
    REG_NOW: "즉시 등록",
    AGREE_XY: "계속 로그 인 하면 동의",
    AGREE_XY2: "《 사용자 협 의 》.",
  },
  DOWNLOAD: {
    ZCWC: "등록 완료",
    GXN: "축하합니다.",
    SLWCZC: "무사히 등록 을 마 쳤 습 니 다.",
    DJZZAPP: "앱 다운 받 으 려 면 아래 버튼 을 누 르 세 요.",
    XZAZ: "안 드 로 이 드 APP 다운로드",
    XZIOS: "ios APP 다운로드",
  },
  // 修改设置支付密码
  OTCPASSWORD: {
    ZFMM: "지불 암호",
    // PLEASE_INPUT_CODE: "도형 인증번호 입력",
    NUM_ERROR: "계 정 입력 오류",
    PASSWORD_LEAST: "비밀번호 최소 8 자리, 알파벳 플러스 숫자",
    PASSWORD_NOSAME: "두 번 의 비밀번호 가 일치 하지 않 습 니 다.",
    MODIFY_PAY_PASSWORD: "지급 비밀번호 변경",
    SET_PAY_PASSWORD: "지급 비밀 번 호 를 설정 하 다.",
    // TX_YANZHENGMA: "도형 인증번호",
    // PLEASE_TX_YANZHENGMA: "도형 인증번호 입력",
    HQ_YANZHENGMA: "인증번호 가 져 오기",
    YZM: "인증번호",
    PLEASE_YZM: "인증번호 입력",
    PLEASE_PASSWORD: "비밀 번 호 를 입력 하 세 요.",
    CONFIRM_PASSWORD: "비밀번호 확인",
    PLEASE_CONFIRM_PASSWORD: "비밀번호 확인 해 주세요.",
    OK: "확정 하 다.",
    ERROR: "계 정 획득 실패, 다시 로그 인 하 세 요.",
  },
  // 修改密码
  PASSWORD: {
    PLEASE_RIGHT_NUMBER: "정확 한 계 정 을 입력 하 세 요",
    NUM_ERROR: "계정 입력 오류",
    PASSWORD_LEAST: "암호 최소 8자리, 문자 + 숫자",
    PASSWORD_NOSAME: "두 개의 암호가 일치하지 않음",
    ZHAOHUI_PASSWORD: "암호 찾기",
    TIPS: "등록한 휴대전화 번호의 인증번호에 따라 로그인 비밀번호를 찾아주세요",
    EMAIL_NUM: "사서함",
    PHONE_NUM: "휴대폰 번호",
    HQ_YANZHENGMA: "인증 코드 가져오기",
    PLEASE_YZM: "인증 번호를 입력하십시오.",
    PASSWORD: "암호",
    PLEASE_PASSWORD: "암호를 입력하십시오.",
    PLEASE_PASSWORD2: "암호를 다시 입력하십시오.",
    // TISHI: "로그인 비밀번호는 비밀번호 지불, 로그인 후 보안센터에서 비밀번호 수정 가능",
    CONFIRM_PASSWORD: "암호 확인",
    PLEASE_CONFIRM_PASSWORD: "암호를 확인하십시오.",
    OK: "확인",

    XGDLMM: "로그인 암호 수정",
    QSRYMM: "원래 암호를 입력하십시오.",
  },
  // 注册
  REGISTER: {
    ZCXZH: "새 계정 등록",
    HYZC: "새 계정 등록 시작",
    SJH: "휴대폰 번호를 입력하십시오.",
    YHM: "사용자 이름",
    YQM: "초대 코드를 입력하십시오.",
    PLEASE_RIGHT_PHONE: "올바른 휴대폰 번호를 입력하십시오.",
    PLEASE_RIGHT_EMAIL: "올바른 사서함을 입력하십시오.",
    PLEASE_RIGHT_PHONE_EMAIL: "올바른 계좌번호를 입력하십시오.",
    PLEASE_INPUT_CODE: "도면 유효성 검사 코드를 입력하십시오.",
    NUM_ERROR: "계정 입력 오류",
    PASSWORD_LEAST: "암호 최소 8자리, 문자 + 숫자",
    PASSWORD_NOSAME: "두 개의 암호가 일치하지 않음",
    PHONE_REGISTER: "휴대폰 등록",
    EMAIL_REGISTER: "사서함 등록",
    PHONE_NUM: "휴대폰 번호",
    EMAIL_NUM: "사서함",
    YANZHENGMA: "인증 번호",
    PLEASE_YANZHENGMA: "인증 번호를 입력하십시오.",
    HQ_YANZHENGMA: "인증 코드 가져오기",
    PASSWORD: "암호",
    PLEASE_PASSWORD: "암호를 입력하십시오.",
    PLEASE_PASSWORD2: "암호를 다시 입력하십시오.",
    TISHI:
      "지불 비밀번호는 기본적으로 로그인 비밀번호와 일치하므로 보안 센터에서 수정할 수 있습니다.",
    NOW_REGISTER: "지금 등록",
    HAD_ZHANGHAO: "이미 계정이 있습니까?",
    HAD_ZHANGHAO2: "지금 로그인",
    REGISTER_SUCESS: "등록 성공",
    PLEASW_NUM: "계좌번호를 입력하십시오.",
    LJXZ: "지금 앱 다운로드",
  },
  // 选择国家
  // TELPREFIX: {
  //   CHOOSE_CITY: "국가 선택",
  //   OK: "확인",
  //   SEARCH_CITY: "국가 검색",
  // },
  // ------------------------------------登录注册结束----------------------------
  // ----------------------------------------跟单-------------------
  GENDAN: {
    QBD: "바인딩하십시오.",
    DTGD: "투입 계약서",
    CLYJNH: "전략 예상 연도별",
    YYX: "실행됨:",
    TIAN: "일",
    CLZPM: "주간 전략 순위",
    DTCLCS: "결정 투사 전략 매개변수",
    JYPT: "거래 플랫폼",
    JYBD: "거래대상",
    MRJG: "매입 가격",
    ZYBL: "자르기 축척",
    ZSBL: "손실 방지 비율",
    HTMRBL: "콜백매입 비율",
    HTMCBL: "콜백 매출 비율",
    XHCZBL: "루프 배율 재설정",
    GSGCL: "이 전략을 따르십시오.",
    MDGD: "마틴 운송장",
    MDCLCS: "마틴 정책 매개변수",
    ZDSL: "주문 수량",
    BCBL: "보충 창고 비율",
    BCZFBL: "보충 창고 증가 비율",
    BCBS: "보충 창고 배수",
    WGGD: "격자 변환",
    WGCLCS: "그리드 정책 매개 변수",
    JGFW: "가격대",
    WGSL: "메쉬 수",
    GE: "개",
    MGLR: "칸당 이윤",
    HD: "슬라이더",
  },
  // -------------------------------------资产相关开始-----------------------
  // 转出
  EXTRACT: {
    // LMC: "체인 이름",
    PLEASE_LOGIN: "먼저 로그인하십시오.",
    ERROR: "계정이 잘못되었습니다. 로그아웃하고 다시 로그인하십시오.",
    YVE_BUZHU: "잔액이 부족하다",
    MORE_TIBI: "최대 전출",
    LESS_TIBI: "최소 전출",
    INPUT_TX_YANZHENGMA: "도면 유효성 검사 코드를 입력하십시오.",
    TIBI: "인화",
    TIBIJILU: "화폐 인출 기록",
    KE_YONG: "사용 가능：",
    TIBI_ADRESS: "수취인 주소",
    INPUT_OR_LANGAN_ADRESS: "BEP20 인화 주소 붙여넣기",
    NUM_USDT: "인화수량", //-
    LESS_TIBI_NUM: "최소 전출 수량",
    PHONE_YANZHENGMA: "휴대폰 인증번호",
    EMAIL_YANHENGMAL: "사서함 인증 번호",
    PLEASE_YANZHENGMA: "인증 번호를 입력하십시오.",
    HQ_YANZHENGMA: "인증 코드 가져오기",
    ZHIFU_PWD: "보안 코드",
    PLEASE_ZHIFU_PWD: "결제 비밀번호를 입력하세요",
    TIXIAN_SUCCESS: "성공적으로 전송되었습니다.",
    DAOZHANG_NUM: "태어나다",
    SHOUXVFEI: "수수료：",
    ITXIAN: "출금 확인",
    TISHI: "친절한 알림：",
    TISHI_1: "현재 1 인당 하루 최대 양도 가능",
    TISHI_2: ",단일 전송 한도는",
    TISHI_5:
      "자금의 안전을 보장하기 위해 이체를 수동으로 검토 할 것입니다. 잠시 기다려주십시오",
    DQGAS: "현재 가스:",
    QHBZ: "통화 전환",
    ZCJL: "녹음",
    KZ: "양도 가능：",
  },
  // 资产页面DMO
  WALLET: {
    XLSX: "드롭다운 새로 고침",
    SKLJSX: "지금 새로 고침 해제",
    JIFEN: "적분:",
    GMJF: "구매 포인트",
    APISQ: "API 인증",
    YQHY: "친구를 초대하다",
    FWJF: "서비스 포인트",
    ZCXQ: "자산 상세 정보",
    RILI: "달력",
    YUJING: "경보",
    JQQD: "기대하세요",
    MONI: "시뮬레이션",
    ZWPM: "당분간 순위가 없다",
    PAIHANG: "서열",
    JIAOYIDUI: "거래 쌍",
    PINGTAI: "플랫폼",
    YUJINIANHUA: "예상 연도별",

    ZCXQ: "자산 상세 정보",
    FWJF: "서비스 포인트",
    JFYE: "积잔액 나누기(1포인트=1USDT)",
    ZHYE: "계정 잔액(USDT)",

    JFLS: "유수를 적분하다.",
    SYLS: "모든 유수",
    ZRLS: "물속으로 들어가다",
    ZCLS: "흐르는 물에서 돌아 나오다",
    PLEASE_LOGIN: "먼저 로그인하십시오.",
    GMJF: "구매 포인트",

    CHONG_BI: "동전을 충전하다",
    TI_BI: "인화",
    DUI_HUAN: "환전",
    ALL_ASSETS: "총 계정 자산 환산(USDT)",
    CAN_USE: "사용 가능",
    ONE_PRICE: "단가(USDT)",
    ZHE_HE: "USDT 환산",
    ZHE_HE_CNY: "CNY 환산",
    LOAD: "로드 중",
    LOADING: "매도를 기다리다",
    PENDING: "부분매출",
    SUCCESS: "이미 팔리다",
    LOCK: "동결",
    DAI_CHUSHOU: "매물",
    JIAOYI_XIANGQING: "거래 상세 정보",
    ALL_NUM: "총량",
    SHENGYV: "미판매로 남다",
    ZHICHANLIUSHUI: "자산 유수",
  },
  // 充值页面
  RECHARGE: {
    CHONGBI: "동전을 충전하다",
    LIANNAME: "체인 이름",
    PLEASE_LOGIN: "먼저 로그인하십시오.",
    FAIL_COPY: "복사가 실패했습니다. 복사를 길게 누르십시오.",
    SUCCESS_COPY: "복제 성공",
    CHONG_BI: "이동",
    USDT_CHONG_BI: "전입",
    JIEPING_SAVE_QRCODE: "스크린샷 QR코드 저장",
    COPY_ADDRESS: "주소 복사",
    ZHUANRU_SHUOMING: "전입 지침:",
    SHUOMING_1:
      "이 주소는 유일하고 혼자서 사용하는 전입 주소입니다. 사용자의 전입 주소는 자주 변경되지 않으며 반복해서 충전할 수 있습니다. 만약 변경이 있을 경우, 우리는 가능한 한 공고나 우편을 통해 당신에게 통지할 것입니다.",
    SHUOMING_2:
      "위의 주소에 ERC20_USDT 이외의 자산을 충전하지 마십시오. 그렇지 않으면 자산을 찾을 수 없습니다.",
    SHUOMING_4:
      "위의 주소에 TRC20_USDT 이외의 자산을 충전하지 마십시오. 그렇지 않으면 자산을 찾을 수 없습니다. ",
    SHUOMING_3:
      "위 주소에 DMO가 아닌 자산을 충전하지 마십시오. 그렇지 않으면 자산을 찾을 수 없습니다.",
    QHBZ: "통화 전환",
  },

  // 转出记录
  TIBIRECORD: {
    TIBIJILU: "화폐 인출 기록",
    PLEASE_LOGIN: "먼저 로그인하십시오.",
    DAI_SHEN_HE: "평가 보류 중",
    TI_BI_ZHONG: "패키지화 중",
    SUCCESS: "성공",
    FAIL: "실패",
    TIBI_JILU: "레코드 반출",
    NO_DATA: "임시 데이터 없음",
    BIAN_HAO: "번호",
    SHU_LIANG: "수",
    TIME: "시간",
    ADDRESS: "주소",
  },
  // -------------------------------------资产相关结束-----------------------
  // ------------------------镜像----------------

  JINGXIANG: {
    JXZBJ: "대칭 충당금",
    KSSJ: "시작 시간",
    TZE: "투자 금액(U)",
    LJTZE: "누적 투자 금액(U)",
    LJSYE: "누적 이익(U)",
    LJSYL: "누적 수익률",
    LJJXSC: "누적 미러 길이",
    JYPT: "거래 플랫폼",
    JXRC: "연인원 미러링",
    CLANDSYZB: "전략 대 수익",
    JXYHSZBKJ: "미러 사용자 설정이 표시되지 않음",
    WG: "메쉬",
    MD: "마틴",
    DT: "정투",
    SYZJ: "잉여금",
    MDCL: "마틴의 전략",
    WGCL: "그리드 정책",
    DTCL: "결정 투표 전략",
    GDY: "단수",
    CLZB: "전략 비율",
    ZWSY: "잠정 무이익",
    TACL: "TA에 대한 정책",

    JYBD: "거래대상",
    CJSJ: "생성 시간",
    SYL: "수익률",
    ZWSJ: "임시 데이터 없음",

    TUIJIAN: "권장",
    YIJINGXIANG: "미러링됨",
    JX: "미러링",

    JXKQSJ: "미러 시작 시간",
    XQ: "상세",
    YGB: "닫힘",

    JXXQ: "미러링 상세 정보",
    QBZBJCZ: "충당금이 충분한지 확인하고 미러링 시작",
    QRJX: "미러 확인",
    QX: "취소",
    QRRISHI:
      "지불 비밀번호를 입력하시고 미러 계좌의 후속 정책을 확인하십시오.관련 거래 계좌에 충분한 준비금이 있는지 확인하십시오.미러 손실은 사용자 자신이 부담합니다. 다시 확인하십시오.3.",
    QSRMM: "암호를 입력하십시오.",
    ZHYEBZ: "귀하의 계좌의 거래 가능 잔액이 준비금 기준 미만입니다.",
    LIJIJX: "지금 미러링",

    SSYH: "사용자 검색",
    ZWJG: "아직 결과가 없다",
    CCXQ: "보유 상세 정보",
    CZRZ: "작업 로그",
    QXJX: "미러링 해제",
    ZWJL: "보류 중인 레코드 없음",
    CKXQ: "자세히 보기",
    QXZH: "미러링을 취소하면 자동으로 미러 계정을 따르지 않습니다. 진행 중인 로봇이 정책을 계속합니다. 스스로 종료할 수 있습니다. 미러링을 취소하시겠습니까?",
    FQ: "포기",
    QRQX: "취소 확인",
  },
  // -------------------------------------个人中心相关开始-------------------
  GOUMAIJIFEN: {
    GMJF: "구매 포인트",
    DQJFYE: "현재 포인트 잔액(수익 발생 시 동일 가액의 20%를 차감한 포인트)",
    JFTC: "포인트 패키지",
    JFTCSM: "서로 다른 패키지 포인트 구매 후 바로 포인트 잔액에 중첩 가능",
    JF: "적분",
    WZK: "할인 없음",
    ZK: "할인",
    JFSM: "적분 설명",
    JFSM1:
      "1. 포인트 패키지(패키지 유형/횟수 제한 없음)를 구매하면 VIP 포인트가 되며, VIP 포인트는 친구를 초대할 수 있다/기타 커뮤니티 리베이트 등 권리를 누릴 수 있다.",
    JFSM2:
      "2. 포인트는 로봇전략에서 발생한 수익의 20%만 공제되며 양도/거래가 불가능합니다.",
    JFSM3: "3. 포인트 구매 후 해지/환불 불가능,",
    JFSM4:
      "4. 포인트 잔액이 10보다 작으면 새로운 정책을 계속 개설할 수 없습니다. 진행 중인 정책은 영향을 받지 않습니다. 그러나 포인트 잔액은 계속 공제됩니다. 새로운 정책을 개설하기 전에 갱신됩니다.",
    KYYE: "가용 잔액",
    QRGM: "구매 확인",
    QX: "취소",
    QSRMM: "암호를 입력하십시오.",
    KYUSDT: "사용 가능한 USDT:",
    QSRAQMM: "보안 암호를 입력하고 구매를 확인하십시오.",
    GMHLJSX: "구입 즉시 효력 발생",
  },
  JXSETTING: {
    JXGL: "미러 관리",
    YXBJX: "미러링 허용",
    YXJXSM:
      "미러링이 허용되면 다른 사용자가 사용자 이름을 검색하여 당신의 홈페이지를 볼 수 있습니다. 모든 로봇을 닫을 때만 미러링할 수 있고 미러링의 허용을 해제할 수 있습니다.",
    JXZH: "계정 미러링",
    JXZYKJ: "미러 홈 페이지 표시",
    OK: "확인",
    CLZB: "전략 비율",
    SYZB: "수익 비율",
    WGCL: "그리드 정책",
    DTCL: "결정 투표 전략",
    MDCL: "마틴의 전략",
  },
  CUSTOMERSERVIXE: {
    MORE_INPUT: "최대 200자 입력",
    LIXNXI_KEFU: "고객 센터에 문의",
    PLEASE_TELL_QUE:
      "질문을 작성해 주십시오. 9:00~18:00에 고객센터에서 즉시 회신할 것입니다.",
    UPLOADING: "업로드 중...",
    TIJIAO: "제출",
    HISTORY: "기록",
    HUIFU: "응답:",
    NO_DATA: "보류 중인 레코드 없음",
    GDJL: "작업 명세서 기록",
    TJGD: "제출",
  },
  // 邀请好友
  FRINDSCOME: {
    PLEASE_LOGIN: "먼저 로그인하십시오.",
    FRIND_COME: "친구를 초대하다",
    YQHYZC:
      "친구들을 Tradease 양자화 로봇에 등록하도록 초대하면 리베이트를 보급할 수 있습니다.",
    SMXZ: "아래 QR코드를 스캔하여 앱 등록 및 다운로드",
    FZYQLJ: "초대 링크 복사",
  },
  // 选择语言
  LANGSWITCH: {
    CHOOSE_CITY: "언어 선택",
  },

  // 我的
  MINELIST: {
    GMJF: "구매 포인트",
    CSSYHDK: "수익 발생 후 공제",
    APISQ: "API 인증",
    JFZX: "포인트 센터",
    XTGG: "시스템 알림",
    BZZX: "도움말 센터",
    WDSQ: "내 커뮤니티",
    JXGL: "미러 관리",
    AQSZ: "보안 설정 ",
    TJGD: "계정 명세서 제출",
    GYWM: "우리에 대해",
    TCDL: "로그온 종료",
    QDTC: "종료하시겠습니까?",
    QX: "취소",
    OK: "확인",
  },
  // 公告
  NOTICE: {
    GONG_GAO_LIST: "시스템 알림",
    NO_DATA: "당분간 공고하지 않다.",
    GONG_GAO_INFO: "알림 상세 정보",
    BZZX: "도움말 센터",
  },
  // 安全中心
  SAFETY: {
    ANQUAN_ZHONGXIN: "보안 센터",
    ZHIFU_PWD: "결제 암호 수정",
    XIUGAI_PWD: "로그인 암호 수정",
  },
  // http
  HTTP: {
    ERROR_LOG: "로그인 정보를 가져오지 못했습니다.",
    ERROR_WANGLUO: "요청 실패",
  },
  // footer

  FOOTER: {
    SHOUYE: "집",
    CELUE: "전략",
    MIANBAN: "대시보드",
    JINGXIANG: "미러링",
    ZHUANGHU: "계정",
  },
  // -----------------------------个人中心结束------------------------------------
  //  社区
  SHEQU: {
    SQ: "커뮤니티",
    GZ: "규칙",
    WU: "없음",
    SHEQUDENGJI: "커뮤니티 등급",
    BZYJ: "금주 실적",
    ZYJ: "총 성과",
    WODESHEQU: "내 커뮤니티",
    QXGMJF: "먼저 포인트 구매해 VIP 회원 되세요",
    YQHY: "친구를 초대하다",
    ZWSQ: "커뮤니티 없음",

    VIP1: "VIP1",
    VIP2: "VIP2",
    VIP3: "VIP3:",
    VIP4: "VIP4",
    GZSM: "규칙 설명",
    GUIZHE: `
    <h3>VIP 회원:</h3>
          <p>포인트 패키지를 한 번이라도 구매한 사용자는 VIP 회원이 된다. 타인을 미러링하지 않은 사용자는 로봇을 종료한 후 동일 가치 수익 부분의 10%를 포인트로 일괄 공제한다. 다른 사람을 미러링한 사용자가 로봇을 종료하면 동일한 수익의 20%를 일괄적으로 공제한다.</p>

          <h3>인센티브 메커니즘 1-추천상 (초결):</h3>
          <p>
            직추 사용자는 서비스 포인트의 10%를 보상으로 받을 수 있습니다.；<br></br>
            간접 사용자들은 서비스 포인트의 5%를 보상으로 받을 수 있다.</p>

          <h3>인센티브 메커니즘 2-지역 사회 상(등급차/일 매듭):</h3>
          <p>
            初级VIP：직접 5명의 VIP 회원으로 가입하면 VIP2가 되며 서비스 포인트의 5%를 보상으로 받을 수 있다.<br></br>
            中级VIP：VIP2를 3명만 밀면 VIP3가 되며 서비스 포인트의 10%를 보상으로 받을 수 있다.<br></br>
            高级VIP：VIP3를 3명만 밀면 VIP4가 되며 서비스 포인트의 16%를 보상으로 받을 수 있다.
          </p>

          <h3>인센티브 메커니즘 3-배당상(일결):</h3>
          <p>
            모든 VIP 2는 실적 비율에 따라 3% 가중치 부여；<br></br>
            모든 VIP 3는 실적 비율에 따라 3% 가중치 부여；<br></br>
            모든 VIP 4는 실적 비율에 따라 가중치가 부여됩니다.3%。
          </p>
          <h3>인센티브 메커니즘 4 – 미러링 배당(정책별 정산):</h3>
          <p>
           미러링 정책을 설정하면 미러링 사용자가 소비한 서비스 포인트의 40%를 보상으로 받을 수 있다.<br></br>
          </p>
          <h3>특별 지침:</h3>
          <p>  
            이상의 규칙은 모두 Tradease 로봇 APP 클라이언트를 통해 자동으로 실행되며, 만약 누군가 대리관리 등 어떠한 형식으로든 커미션을 요구한다면, 믿지 마십시오!
          </p> 
    `,
  },
  // 用户协议
  AGREEMENTS: {
    USER_AGREEMENTS: "사용자 프로토콜",
    XIEYI: ` <p>Tradease 로봇은 귀하(사용자)가 가입하기 전에 본 "사용자 계약서"(이하" Tradease 로봇 사용자 계약서")를 잘 읽어 귀하가 본 계약서의 각 조항을 충분히 이해하도록 주의합니다. 귀하는 이 계약을 신중하게 읽고 수락 여부를 선택하시기 바랍니다. 귀하가 본 계약의 모든 조항에 동의하지 않는 한 귀하는 본 계약과 관련된 서비스를 등록, 로그인 또는 사용할 권한이 없습니다. 귀하의 등록, 로그인, 사용 등의 행위는 본 계약에 대한 수락으로 간주하며 본 계약의 각 조항의 구속을 받는 것에 동의합니다. 
본 계약은 Tradease 로봇과 사용자 간의 "Tradease 로봇" 소프트웨어 서비스(이하 "서비스")에 관한 권리와 의무를 약정합니다. "사용자"는 본 서비스를 등록, 로그인, 사용하는 개인을 말합니다. 이 계약은 Tradease 로봇에 의해 언제든지 갱신될 수 있으며, 
업데이트된 계약 조항이 하루아침에 공개됨으로써 원래의 계약 조항을 대체하며, 더 이상 통지를 하지 않을 수 있으며, 사용자는 본 사이트에서 최신 계약 조항을 열람할 수 있습니다. YISHU로봇의 계약 약관 수정 후, 이용자가 변경된 약관에 동의하지 않을 경우 YISHU로봇이 제공하는 서비스의 이용을 즉시 중지하시기 바라며, 이용자가 YISHU로봇이 제공하는 서비스를 계속 이용하는 것은 변경된 계약에 동의하는 것으로 간주됩니다. 
</p>
    <p>본 계약은 Tradease 로봇과 사용자 간의 "Tradease 로봇" 소프트웨어 서비스(이하 "서비스")에 관한 권리와 의무를 약정합니다. "사용자"는 본 서비스를 등록, 로그인, 사용하는 개인을 말합니다. 이 계약은 Tradease 로봇에 의해 언제든지 갱신될 수 있으며, 
업데이트된 계약 조항이 하루아침에 공개됨으로써 원래의 계약 조항을 대체하며, 더 이상 통지를 하지 않을 수 있으며, 사용자는 본 사이트에서 최신 계약 조항을 열람할 수 있습니다. YISHU로봇의 계약 약관 수정 후, 이용자가 변경된 약관에 동의하지 않을 경우 YISHU로봇이 제공하는 서비스의 이용을 즉시 중지하시기 바라며, 이용자가 YISHU로봇이 제공하는 서비스를 계속 이용하는 것은 변경된 계약에 동의하는 것으로 간주됩니다. 
</p>
    <h3>1. 계정 등록</h3>
    <p>1. 사용자는 본 서비스를 이용하기 전에 'Tradease 로봇' 계정을 등록해야 합니다. "Tradease 로봇" 계정은 반드시 핸드폰 번호로 등록해야 하며, 사용자는 "Tradease 로봇" 계정과 아직 연결되지 않은 핸드폰 번호 및 본 협의에 따라 Tradease 로봇이 차단하지 않은 핸드폰 번호로 "Tradease 로봇" 계정을 등록해야 합니다. Tradease 로봇은 사용자의 요구나 제품의 필요에 따라 사용자에게 사전 통지 없이 계정 등록 및 바인딩 방식을 변경할 수 있다.</p>
    <p>2. "Tradease 로봇"은 Tradease 로봇의 지리적 위치와 일치하는 개인정보를 허가하는 모바일 소셜 상품이다. 따라서 사용자가 등록을 완료하면 Tradease 로봇이 사용자의 지리적 위치 정보를 추출하여 공개하고 사용하는데 동의한다는 것을 나타낸다.</p>
    <p>3. "Tradease 로봇" 계정의 바인딩 등록 방식을 고려하여, 귀하는 Tradease 로봇이 가입시 귀하가 제공한 핸드폰 번호 및/또는 자동적으로 귀하의 핸드폰 번호를 추출하고 자동적으로 귀하의 핸드폰 장치 식별 코드를 추출하는 등의 정보를 등록에 사용하는 것에 동의합니다.</p>
    <p>4. Tradease 로봇은 이용자가 본 서비스를 등록 및 이용할 때 이용자의 신원을 식별할 수 있는 개인정보를 수집하여야 하며, 필요한 경우 Tradease 로봇이 이용자에게 연락하거나 이용자에게 더 나은 이용경험을 제공할 수 있도록 하여야 합니다. Tradease 로봇이 수집하는 정보에는 사용자의 이름, 성별, 나이, 생년월일, 주민등록번호, 주소, 학교 상황, 회사 상황, 소속 업종, 취미, 자주 나타나는 곳, 개인 설명을 포함하지만 이에 국한되지는 않는다; Tradease Robotics는 이러한 정보의 사용이 제3조의 사용자 개인 사생활 정보 보호에 제한될 것이라는 것에 동의한다.</p>
    <h3>2. 서비스 내용</h3>
    <p>본 서비스의 구체적인 내용은 Tradease 로봇이 실제 상황에 근거하여 제공하며, 사용자의 계정을 통한 실시간 통신, 친구 추가, 그룹 가입, 다른 사람의 관심, 댓글 게시를 포함하지만 이에 국한되지는 않는다. Tradease 로봇은 자신이 제공하는 서비스를 변경할 수 있으며 Tradease 로봇이 제공하는 서비스 내용은 수시로 변경될 수 있다; 사용자는 Tradease 로봇으로부터 서비스 변경에 대한 통지를 받게 될 것이다.</p>
    <h3>3. 사용자 개인의 프라이버시 정보 보호</h3>
    <p>1. 사용자는 계정을 등록하거나 본 서비스를 사용하는 과정에서, 법률, 규정, 규약 규범성 문건(이하 "법률 규정"이라 함)에 규정된 신원 정보와 같은 필요한 정보를 기입하거나 제출해야 할 수 있습니다. 이용자가 제출하는 정보가 불완전하거나 법령의 규정에 부합하지 않는 경우 이용자는 본 서비스를 이용할 수 없거나 이용에 제한을 받을 수 있습니다.</p>
    <p>2. 개인 프라이버시 정보는 사용자 개인 신분 또는 개인 프라이버시와 관련된 정보를 가리키며, 예를 들면 사용자 실명, 신분증 번호, 핸드폰 번호, 핸드폰 장치 식별 코드, IP 주소, 사용자 채팅 기록. 비개인 사생활 정보라 함은 본 서비스의 운영 상태 및 사용 습관 등 Tradease 로봇 서버에 명확하고 객관적으로 반영된 사용자의 기본 기록 정보를 가리키며, 
개인 사생활 정보 범위 밖의 기타 일반 정보 및 사용자가 공개에 동의한 상기 사생활 정보 </p>
    <p>3. Tradease 로봇의 일관성 있는 제도로서, Tradease 로봇은 기술적 조치와 기타 필요한 조치를 취하여 사용자 개인 사생활 정보의 안전을 보장하고, 본 서비스에서 수집한 사용자 개인 사생활 정보의 누설, 훼손 또는 분실을 방지합니다. 상술한 상황이 발생하거나 Tradease 로봇이 상술한 상황이 발생할 가능성이 존재함을 발견했을 때, 즉시 보완조치를 취할 것이다.</p>
    <p>Tradease 로봇은 사용자의 동의 없이는 어떠한 제3자에게도 사용자의 개인 사생활 정보를 공개하지 않는다. 단, 다음과 같은 특정 상황은 제외합니다.      <br></br>(1) YISHU로봇은 법령의 규정 또는 권한이 있는 기관의 지시에 따라 이용자의 개인 프라이버시 정보를 제공합니다.
      <br></br>(2) 사용자가 자신의 비밀번호를 타인에게 알려주거나 타인과 공유한 등록 계정과 비밀번호로 인해 발생하는 모든 개인정보의 누설 또는 Tradease 로봇의 원인이 아닌 기타 개인 사생활 정보의 누설;
      <br></br>(3) 사용자가 스스로 제3자에게 자신의 개인 프라이버시 정보를 공개하는 경우;
      <br></br>(4) 사용자와 Tradease 로봇 및 협력업체 간에 사용자 개인 프라이버시 정보의 사용 공개에 대한 약정을 체결하고 Tradease 로봇은 이에 따라 협력업체에 사용자 개인 프라이버시 정보를 공개한다;      <br></br>(5) 해커의 공격, 컴퓨터 바이러스의 침입 및 기타 불가항력적인 사건으로 인하여 사용자 개인의 프라이버시 정보가 유출되는 경우.      <br></br>(6)이용자는 사용과정 중에 주동적으로 정치적 유해성, 음란, 음란물, 광고, 피비린내 나는 폭력, 비방, 모욕, 개인 프라이버시 누설 등 정보를 유포하는 것이 존재한다.
    </p>
    <p>5. 이용자는 Tradease 로봇이 다음의 사항에서 이용자의 개인 사생활 정보를 사용할 수 있다는 것에 동의한다      <br></br>(1) Tradease 로봇은 사용자에게 즉시 소프트웨어 업데이트, 본 계약서 조항의 변경과 같은 중요한 통지를 발송한다;      <br></br>(2) Tradease 로봇 내부에서 감사, 데이터 분석 및 연구 등을 진행하여 Tradease 로봇의 제품, 서비스 및 사용자와의 소통을 개선한다;
      <br></br>（3）본 계약의 약정에 따라 Tradease 로봇 관리, 사용자 정보 심사 및 처리 조치를 진행한다;
      <br></br>(4) 법률, 법규가 규정한 기타 사항을 적용한다.      <br></br>상술한 사항을 제외하고, 사용자의 사전 동의가 없으면 Tradease 로봇은 사용자의 개인 사생활 정보를 어떠한 다른 용도로도 사용하지 않습니다.    </p>
    <p>6, Tradease 로봇은 미성년자 개인 사생활 정보의 보호를 중시한다.
    Tradease 로봇은 사용자가 제공한 개인 정보에 의존하여 사용자가 미성년자인지 여부를 판단할 것이다. 
18세 미만의 미성년자가 계정을 등록하거나 본 서비스를 이용하려면 사전에 학부모 또는 그의 법적 보호자(이하 "보호자")의 서면 동의를 얻어야 합니다. 
법령의 규정과 권한 있는 기관의 지시에 따라 공개하는 경우를 제외하고는, 
Tradease 로봇은 미성년자의 채팅 기록 및 기타 개인 정보를 제3자에게 공개하거나 사용하지 않는다. 이 협정에 규정된 예외를 제외하고, 
Tradease 로봇은 보호자의 사전 동의 없이는 미성년자의 개인 정보를 사용하거나 제3자에게 공개하지 않는다. </p>
    <p>7. 사용자의 확인, 지리적 위치 정보가 개인 사생활 정보가 아님, 사용자가 Tradease 로봇에 성공적으로 등록한 계정은 Tradease 로봇에 대한 인증 확인 으로 간주됩니다. 
사용자의 지리적 위치 정보를 추출하고, 공개하고, 사용합니다. 사용자의 지리적 위치 정보는 사용자의 공개 자료로서, 
Tradease 로봇에 의해 다른 사용자들에게 공개되었다. 만약 사용자가 다른 사용자에게 자신의 지리적 위치 정보를 공개하는 것을 중단해야 한다면, 언제든지 스스로 스텔스 상태로 설정할 수 있다. </p>
    <p>8, Tradease 로봇의 기술과 서비스를 개선하고, 사용자에게 더 나은 서비스 경험을 제공하기 위하여, 
Tradease 로봇은 스스로 수집하여 사용하거나 제3자에게 사용자의 비개인 정보를 제공할 수 있다. </p>
    <h3>4. 내용 규범</h3>
    <p>1. 본 조에서 언급하는 내용은 사용자가 본 서비스를 사용하는 과정에서 제작, 업로드, 복사, 게시, 전파한 모든 내용을 가리키며, 
계정 프로필 사진, 명칭, 사용자 설명 등 등록 정보 및 인증 자료, 문자, 음성, 사진, 동영상, 그림 등 발송을 포함하지만 이에 국한되지는 않는다. 
메시지 및 관련 링크 페이지, 계정 또는 본 서비스 사용으로 인한 기타 내용에 대한 회신 또는 자동 회신. </p>
    <p>2. 이용자는 Tradease 로봇 계정이나 본 서비스를 이용하여 아래와 같은 법률, 법규 및 정책에서 금지하는 내용을 제작, 업로드, 복사, 배포, 전파할 수 없습니다.
      <br></br>(1) 헌법이 정한 기본원칙에 반대하는 경우；
      <br></br>(2) 국가 안전을 해치고, 국가 기밀을 누설하고, 국가 정권을 전복하고, 국가 통일을 파괴하는 경우；
      <br></br>(3) 국가의 명예와 이익을 해치는 경우；
      <br></br>(4) 민족 증오, 민족 차별을 선동하고 민족 단결을 파괴하는；
      <br></br>(5) 국가 종교 정책을 파괴하고, 사교와 봉건 미신을 선양하는 경우；
      <br></br>(6) 유언비어를 퍼뜨려 사회 질서를 어지럽히고 사회 안정을 파괴하는；
      <br></br>(7) 음란, 색정, 도박, 폭력, 살인, 공포를 유포하거나 범죄를 교사한 경우；
      <br></br>(8) 타인을 모욕하거나 비방하여 타인의 합법적 권익을 침해한 경우；
      <br></br>(9) 법률, 법규의 마지노선, 사회주의 제도의 마지노선, 국가이익의 마지노선, 공민의 합법적 권익의 마지노선, 사회공공질서의 마지노선, 도덕기풍의 마지노선, 정보의 진실성의 마지노선의 "7가지 마지노선"의 요구를 준수하지 아니한 경우；
      <br></br>(10) 법률, 행정법규에서 금지하는 기타 내용을 포함하고 있는 정보。
    </p>
    <p>3. 사용자는 Tradease 로봇 계정이나 본 서비스를 이용하여 제작, 업로드, 복제, 게시할 수 없습니다. 
아래와 같이 Tradease 로봇의 정상적인 운영을 방해하고 기타 사용자 또는 제3자의 합법적 권익을 침해하는 내용을 전파한다. ：
      <br></br>(1) 어떤 성적 또는 성적 암시가 있는；
      <br></br>(2) 욕설, 공갈, 협박 등의 내용이 포함된；
      <br></br>(3) 괴롭힘, 스팸, 악의적인 메시지, 기만적인 메시지를 담고 있는；
      <br></br>(4) 다른 사람의 사생활, 개인 정보 또는 정보를 다루는；
      <br></br>(5) 타인의 명예권, 초상권, 지적재산권, 상업비밀 등 합법적 권리를 침해한 경우；
      <br></br>(6) 서비스의 정상적인 운영을 방해하고 다른 사용자 또는 제3자의 합법적 이익을 침해하는 기타 내용이 포함된 정보。

    </p>
    <h3>5. 규칙 사용</h3>
    <p>1、사용자가 본 서비스에서 또는 본 서비스를 통해 전송하거나 게시한 내용은 반영하거나 대표하지 않으며, 
Tradease 로봇의 관점, 입장, 정책을 반영하거나 대표한다고 간주될 수 없으며 Tradease 로봇은 이에 대해 어떠한 책임도 지지 않습니다. </p>
    <p>2이용자는 Tradease 로봇 계정이나 본 서비스를 이용하여 다음과 같은 행위를 하여서는 아니 된다.：
      <br></br>(1) 허위 정보를 제출, 발표하거나 타인의 얼굴 사진이나 자료를 도용, 사칭, 타인의 명의를 이용한 경우；
      <br></br>(2) 다른 사용자들이 접속하도록 유도하거나, 링크된 페이지를 클릭하거나, 정보를 공유하도록 하는；
      <br></br>(3) 사실을 허구하고 진상을 은폐하여 남을 오도하고 기만하는 경우；
      <br></br>(4) 기술 수단을 이용하여 대량으로 허위 계정을 만든 경우；
      <br></br>(5) Tradease 로봇 "계정 또는 본 서비스를 이용하여 모든 위법 범죄 활동에 종사한 경우；
      <br></br>(6) 위의 행위와 관련된 메서드, 도구를 제작, 게시, 또는 이러한 메서드, 도구를 운영 또는 배포합니다.，
      이러한 행위가 상업적인 목적이든 아니든 상관없이；
      <br></br>(7) 기타 법률, 법규, 기타 사용자의 합법적 권익을 침해하고, 
Tradease 로봇의 정상적인 운영을 방해하거나 심천월계창신과학기술유한공사가 명시적으로 권한을 위임하지 않은 행위. 
    </p>
    <p>3、이용자는 Tradease 로봇 계정 또는 본 서비스를 이용하여 전송한 정보의 진실성, 합법성, 무해성, 정확성、
    유효성 등에 대한 모든 책임은 사용자가 퍼뜨린 정보와 관련된 모든 법적 책임은 사용자가 부담하며 Tradease 로봇과는 무관합니다.。
      만약 이로 인해 Tradease 로봇 또는 제3자에게 손해를 입힌 경우, 사용자는 법에 따라 배상해야 한다。</p>
    <p>4、Tradease 로봇이 제공하는 서비스에는 사용자가 Tradease 로봇과 타사 공급업체를 표시하는 데 동의하는 광고가 포함될 수 있습니다.、
    파트너가 제공하는 광고 법률, 법규가 명확히 규정한 경우를 제외하고 사용자는 해당 광고 정보에 따라 진행한 거래에 대하여 스스로 책임을 져야 한다，
      Tradease 로봇은 해당 광고 정보에 의한 거래 또는 앞서 설명한 광고주가 제공한 내용으로 인하여 이용자가 입은 손실 또는 손해에 대하여 어떠한 책임도 지지 않는다。</p>
    <h3>6. 계정 관리</h3>
    <p>1、 "Tradease 로봇" 계정의 소유권은 Tradease 로봇에게 있으며, 
사용자는 등록 수속을 마치면 Tradease 로봇 계정의 사용권을 얻는다. 
이 사용권은 최초 등록 신청자에게만 귀속되며, 증여, 차용, 임대, 양도 또는 판매가 금지된다. Tradease 로봇은 경영상 필요에 의해 ，
      사용자의 Tradease 로봇 계정을 회수할 권리가 있다.</p>
    <p>2、Tradease 로봇 계정의 프로필을 변경하거나 삭제할 수 있으며, 
정보 및 전송 내용 등을 등록하되, 해당 정보를 삭제하면 사용자가 시스템에 저장한 글과 그림도 삭제되므로 사용자가 위험을 감수해야 합니다. </p>
    <p>3、사용자는 등록 계정 정보 및 계정 비밀번호의 안전을 적절히 보관할 책임이 있으며, 
사용자의 보관이 소홀하여 번호나 비밀번호를 도난당할 수 있으며, 책임은 사용자 스스로 진다. 
사용자는 계정 및 비밀번호를 등록한 행위에 대해 법적 책임을 져야 한다. 사용자가 어떠한 경우에도 다른 사용자의 계정이나 비밀번호를 사용하지 않기로 동의한 경우 
. 사용자가 다른 사람이 자신의 계정이나 비밀번호를 사용하는 것을 의심할 경우, 사용자는 Tradease 로봇에게 즉시 알리기로 동의한다. </p>
    <p>4、이용자는 본 계약의 각 조항을 준수하고, 정확하고 적절하게 본 서비스를 이용하여야 하며, 만약 이용자가 본 계약의 어떠한 조항을 위반하여, 
Tradease 로봇은 사용자에게 통지한 후 계약에 따라 위약자 Tradease 로봇 "계정에 대한 서비스 제공을 중단 또는 종료할 권리가 있습니다. 
      동시에 Tradease 로봇은 언제든지 Tradease 로봇의 "계정, 사용자 이름을 회수할 수 있는 권리를 보유한다.。</p>
    <p>5、만약 사용자가 Tradease 로봇 계정을 등록한 후 일년 동안 로그인하지 않으면, 사용자에게 통지한 후 Tradease 로봇은 해당 계정을 회수할 수 있다. 
자원의 낭비를 초래하지 않기 위해, 이로 인해 초래되는 불리한 결과는 사용자가 스스로 부담한다 。</p>
    <h3>7, 데이터 저장</h3>

    <p>1, Tradease 로봇은 본 서비스 중 사용자의 관련 데이터 삭제 또는 저장 실패에 대해 책임을 지지 않습니다.</p>
    <p>2、Tradease 로봇은 실제 상황에 따라 사용자가 본 서비스에서 데이터를 저장하는 최장 기한을 스스로 결정할 수 있으며, 
서버에 최대 데이터 저장 공간 등을 할당합니다. 사용자는 필요에 따라 이 서비스의 관련 데이터를 직접 백업할 수 있습니다. 。</p>
    <p>3、Tradease 로봇은 사용자가 본 서비스의 사용을 중지하거나 본 서비스가 종료될 경우 서버에서 사용자의 데이터를 영구적으로 삭제할 수 있습니다. 
본 서비스의 중지, 종료 후, Tradease 로봇은 사용자에게 어떠한 데이터도 반환할 의무가 없다 。</p>
    <h3>8. 위험 부담</h3>
    <p>1、사용자는 Tradease 로봇이 "단지 사용자에게 정보를 공유, 전송, 획득할 수 있는 플랫폼을 제공한다는 것을 이해하고 동의합니다. 
사용자는 사용자가 전송한 모든 내용과 그로 인해 발생하는 모든 결과를 포함하여 자신의 등록 계정 아래에서 발생한 일체의 행위에 대해 책임을 져야 한다. 
사용자는 Tradease 로봇 및 본 서비스의 내용에 대해 스스로 판단하고, 콘텐츠 사용으로 인해 발생하는 모든 위험을 감수해야 한다. 
내용의 정확성, 완전성 또는 실용성에 대한 의존으로 인한 위험을 포함한다. 
Tradease 로봇은 사용자의 행동으로 인한 어떠한 손실이나 손해에 대해서도 책임을 질 수 없으며 책임을 지지 않는다. 
만약 이용자가 본 계약의 규정을 위반하거나 기타 부적절한 방법으로 본 서비스를 이용하는 사람을 발견할 경우, 
Tradease 로봇에게 즉시 신고 또는 고소를 해주시기 바랍니다. Tradease 로봇은 본 협의의 약정에 따라 처리할 것입니다. </p>
    <p>2. 사용자는 Tradease 로봇이 업무 발전의 필요에 따라 일방적으로 본 서비스의 전부 또는 일부의 서비스 내용을 변경하는 것을 이해하고 동의합니다. 
일시 정지, 종료 또는 취소할 수 있는 권리는 사용자가 이 위험을 감수해야 한다. </p>
    <h3>9. 지식재산권 성명</h3>
    <p>1. 본 서비스에서 광고와 관련된 지적재산권은 해당 광고주가 향유하는 것 외에 (Tradease 로봇)이 본 서비스에서 제공하는 내용 
(웹 페이지, 텍스트, 그림, 오디오, 비디오, 도표 등을 포함하되 이에 국한되지 않음)의 지적 재산권은 (Tradease Robotics)의 소유이며, 
단, 이용자가 본 서비스를 이용하기 전에 자신이 게시한 내용에 대해 적법하게 지적재산권을 취득한 경우는 예외로 합니다. </p>
    <p>(Tradease Robotics)가 본 서비스를 제공함에 있어 특별히 명시된 경우를 제외하고, (Tradease Robotics)의 소프트웨어에 대한 저작권, 
특허권 및 기타 지적 재산권은 모두 (Tradease Robotics) 소유이다. </p>
    <p>3, (Tradease Robotics) 이 서비스에 관련된 그래픽, 텍스트 또는 구성, 
및 기타 (Tradease 로봇) 표지 및 제품, 서비스 명칭(이하 "(Tradease 로봇) 표식"으로 통칭) 
저작권 또는 상표권은 Tradease 로봇에 있습니다. (Tradease 로봇의) 사전 서면 동의 없이, 
사용자들은 (Tradease 로봇) 로고를 어떤 식으로든 보여주거나 사용하거나 다른 방법으로 처리해서는 안 되며, 
또한 사용자가 (Tradease 로봇) 표지를 전시, 사용 또는 처리할 권한이 있다는 것을 타인에게 표명하는 행위를 하여서는 아니 된다. </p>
    <p>4, 상기 및 기타 (Tradease 로봇) 또는 관련 광고주가 법적으로 소유한 지식재산권은 모두 법적 보호를 받으며, 
이용자는 (Tradease Robotics) 또는 관련 광고주의 서면 허가 없이는 어떠한 형식으로도 관련 파생저작물을 사용하거나 창조할 수 없습니다. </p>
    <h3>10. 법적 책임</h3>

    <p>1. Tradease 로봇이 사용자가 본 계약의 약정을 위반했다는 타인의 고발 또는 신고를 받은 경우, 
Tradease 로봇은 통지 없이 수시로 사용자 정보, 채팅 기록을 포함하되 이에 국한되지 않는 관련 내용을 검열하고 삭제할 수 있는 권리를 가진다. 
또한 상황의 경중에 따라 규정 위반 계정에 대하여 경고, 계정 봉쇄, 설비 봉쇄, 기능 봉쇄 등의 처벌을 포함하나 이에 국한되지 않으며 사용자에게 처리 결과를 통지한다. </p>
    <p>2. 사용자 계약을 위반하여 차단된 사용자는 Tradease 로봇 고객센터에 연락하여 차단기간을 조회할 수 있다. 
또한 봉인기간이 만료된 후 스스로 봉인을 해제한다. 이 중 기능이 차단된 사용자는 차단 기간이 만료되면 자동으로 차단된 기능을 복원한다. 
봉인된 사용자는 Tradease 로봇 고객센터에 이의신청을 제출할 수 있으며, Tradease 로봇은 이의신청을 심사하여 스스로 합리적으로 판단하여 처벌조치의 변경여부를 결정한다. </p>
    <p>3. 사용자는 Tradease 로봇이 합리적 판단에 따라 관련 법률, 법규 또는 본 계약의 규정을 위반하는 행위를 처벌할 권리가 있음을 이해하고 동의하며, 
법률, 법규를 위반한 모든 사용자에 대해 적절한 법률 조치를 취하고, 법률, 법규에 따라 관련 정보를 보관하고 관련 부서에 보고하는 등, 
사용자는 그로 인해 발생하는 모든 법적 책임을 져야 한다. </p>
    <p>4. 사용자가 본 계약 위반으로 인해 제3자가 주장하는 모든 클레임, 요구 또는 손실에 대해 이해하고 동의합니다. 
합리적인 변호사 비용을 포함하여 사용자는 Tradease 로봇과 협력회사, 관계회사에 손해를 입지 않도록 배상해야 한다. </p>
    <h3>11. 불가항력 및 기타 면책사유</h3>

    <p>1. 이용자는 본 서비스를 이용하는 과정에서 불가항력 등의 위험요소가 발생할 수 있음을 이해하고 확인하며, 
본 서비스를 중단시키다. 불가항력이란 예견할 수 없고, 극복할 수 없으며 피할 수 없으며, 일방 또는 양측에 중대한 영향을 끼치는 객관적 사건을 가리킨다. 
홍수, 지진, 전염병, 폭풍과 같은 자연재해와 전쟁, 동란, 정부 행위와 같은 사회적 사건을 포함하되 이에 국한되지는 않는다. 이런 상황이 발생하면, 
Tradease 로봇은 일시에 관련 부서와 협력하여 적시에 복구할 수 있도록 노력할 것이지만, 이로 인해 사용자 또는 제3자에게 발생한 손실은, 
Tradease 로봇과 협력업체는 법이 허용하는 범위 내에서 면책된다. </p>
    <p>2. 본 서비스는 대부분의 인터넷 서비스와 동일하며, 사용자 원인에 국한되지 않고 포함되며, 네트워크 서비스 품질을, 
다른 사람이 사용자의 정보를 이용하는 것과 같은 여러 가지 보안 문제에 의해 영향을 받을 수 있고, 
실생활에서 괴롭힘을 일으킨다; "트로이 목마"와 같은 바이러스를 가지고 있는 다른 소프트웨어 또는 다른 웹 사이트를 다운로드하거나, 
사용자의 컴퓨터 정보 및 데이터의 보안을 위협하여 본 서비스의 정상적인 사용에 영향을 주는 경우 등입니다. 사용자는 반드시 정보 보안과 사용자 자료에 대한 보호 의식을 강화해야 하며, 
손실과 소란을 피우지 않도록 비밀번호 보호를 강화해야 한다. </p>
    <p>3. 사용자는 본 서비스가 불가항력, 컴퓨터 바이러스 또는 해커의 공격, 불안정한 시스템, 사용자의 위치, 
사용자 종료 및 기타 모든 기술, 인터넷, 통신 회선 등으로 인한 서비스 중단 또는 사용자 요구 사항을 충족하지 못할 위험이 있으며, 
Tradease 로봇은 이로 인해 발생하는 사용자 또는 제3자의 손실에 대해 어떠한 책임도 지지 않습니다. </p>
    <p>4. 이용자는 본 서비스를 사용하는 과정에서 다른 사람으로부터 오해를 포함한 모든 것이 존재한다는 것을 이해하고 확인하며, 
기만적, 위협적, 명예훼손적, 반감적, 불법적 정보, 타인의 권리를 침해하는 익명 또는 사칭 정보, 
Tradease 로봇은 이러한 정보를 수반하는 행위로 인하여 발생하는 사용자 또는 제3자의 손실에 대하여 어떠한 책임도 지지 않습니다. </p>
    <p>5. 사용자는 Tradease 로봇이 정기적 또는 비정기적으로 Tradease 로봇 "플랫폼 또는 관련 설비에 대해 점검수리 또는 유지보수를 진행해야 한다는 것을 이해하고 확인한다. 
만약 이러한 상황으로 인하여 합리적인 시간 내에 서비스가 중단된다면 Tradease 로봇은 이에 대한 어떠한 책임도 부담할 필요가 없으며, Tradease 로봇은 사전에 통보를 해야 한다. </p>
    <p>6. Tradease Robotics는 법률, 규정, 본 계약서에 따라 법률, 규정, 규정 위반, 규정 위반을 처리할 수 있는 권리를 가지며, 
이 권리는 Tradease 로봇의 의무 또는 승낙을 구성하지 않으며, Tradease 로봇은 적시에 위법 또는 위약 행위를 발견하거나 그에 상응하는 처리를 할 것을 보장할 수 없다. </p>
    <p>6. Tradease Robotics는 법률, 규정, 본 계약서에 따라 법률, 규정, 규정 위반, 규정 위반을 처리할 수 있는 권리를 가지며, 
이 권리는 Tradease 로봇의 의무 또는 승낙을 구성하지 않으며, Tradease 로봇은 적시에 위법 또는 위약 행위를 발견하거나 그에 상응하는 처리를 할 것을 보장할 수 없다. </p>
    <p>8. 어떠한 경우에도 Tradease 로봇은 어떠한 간접적, 결과적, 징벌적, 우연적, 특수적 또는 형벌적 손해를 입지 않으며, 
"Tradease Robotics" 또는 "서비스 사용으로 인한 수익 손실을 포함하며, 
Tradease 로봇이 그와 같은 손실의 가능성을 이미 알고 있었다 하더라도 책임을 진다. 이 계약서에 위반될 수 있는 조항에도 불구하고, 
Tradease 로봇이 사용자에 대하여 부담하는 모든 책임은 어떠한 원인이나 어떠한 행동 방식이든 사용자가 Tradease 로봇을 사용하는 것을 초과하지 않는다 
Tradease Robotics에 지불하는 비용입니다. </p>
    <h3>12. 서비스의 변경, 중단, 종료</h3>
    <p>1. 인터넷 서비스의 특수성을 감안하여 사용자는 Tradease 로봇이 언제든지 변경할 수 있는 권리가 있다는 것에 동의하고, 
유료 서비스를 포함한 서비스의 일부 또는 전부를 중단하거나 종료합니다. Tradease 로봇 변경, 
서비스 중단 또는 종료에 대해 Tradease 로봇은 변경, 중단 또는 종료 전에 사용자에게 통지해야 하며, 
그리고 영향을 받는 사용자들에게 동등한 대체 서비스를 제공해야 한다. </p>
    <p>2. 다음 상황 중 어느 하나가 발생할 경우 Tradease 로봇은 사용자에게 제공하는 무료 서비스를 변경, 중단 또는 종료할 권리가 있다. 
사용자 또는 제3자에 대한 책임 없이 다음을 수행합니다. 
    (1) 根법률 규정에 따르면 사용자는 반드시 진실된 정보를 제출해야 하는데, 사용자가 제공한 개인 정보는 진실하지 않다
    등록 당시 정보와 일치하지 않거나, 합리적인 증빙을 제공하지 않은 경우 (2) 사용자가 관련 법률, 법규 또는 본 계약서의 약정을 위반한 경우   (3) 법률 규정 또는 권한 있는 기관의 요청에 따라, (4) 안전의 원인 또는 기타 필요한 상황    </p>
    <h3>13, 기타</h3>

    <p>1. Tradease Robotics는 사용자에게 본 계약의 책임 면제 및 사용자 권리 제한 조항에 주의하도록 엄중히 주의를 환기하고, 
사용자들은 신중하게 읽고 스스로 위험을 고려하시기 바랍니다. 미성년자는 법정 후견인의 동반하에 이 협의를 읽어야 한다. </p>
    <p>2. 본 협의의 효력, 해석 및 분쟁의 해결은 중화인민공화국 법률에 적용된다. 
만약 사용자와 Tradease 로봇 사이에 어떠한 분쟁이나 쟁의가 발생하면, 우선 우호적인 협상을 통해 해결해야 하며, 협상이 이루어지지 않을 경우, 
사용자들은 분쟁이나 분쟁을 법원에 회부하는 데 동의한다. </p>
    <p>3. 본 협의의 어떠한 조항도 어떠한 이유로 무효이거나 집행가능성이 없든지 간에, 나머지 조항은 여전히 유효하며, 쌍방에 대하여 구속력을 가진다.</p> 
    <p  class='redtext'>
    투자는 신중해야 합니다. 시장에 진입하면 위험이 있습니다!<br>
    본 소프트웨어가 제공하는 전략과 소프트웨어 중의 미러링 등 기능의 사용은 단지 참고로 삼을 뿐이며, 투자 품목 및 제품은 모두 투자 건의를 구성하지 않으며, 미러링 등 기능으로 인한 손실은 사용자 자신이 부담할 것입니다. 다시 한번 확인하시기 바랍니다.</p>
    `,
  },
  EXCEPTION: {},
  // 策略相关
  CELUE: {
    // 新增排序
    ORDERBY_START: "시작 시간별",
    ORDERBY_INVEST: "투자액별",
    ORDERBY_DEFAULT: "기본 정렬",
    ORDERBY: "정렬 방법",
    // 策略页面
    MDCL: "마틴의 전략",
    WGCL: "그리드 정책",
    DTCL: "결정 투표 전략",
    HYCL: "계약 전략",
    GBZ: "닫기 중",
    CZCG: "작업 성공",
    QBDAPI: "API를 바인딩하십시오.",
    CJMDJQR: "마틴 로봇 만들기",
    MDCLJRZYL: "마틴 전략의 오늘 총이익(USDT)",
    MDLSZYL: "마틴의 역대 총 수익:",
    WDJQR: "내 로봇",
    QB: "모두",
    LSJQR: "역사 로봇",
    ZWJRR: "아직 로봇이 없습니다.",
    YGB: "닫힘",
    YCZ: "재설정됨",
    YXSC: "런타임 길이：",
    CJ: "작성",
    TZE: "투자액",
    ZLR: "총이익",
    MDLR: "마틴 마진",
    CCZE: "보유총액",
    CCL: "보유량",
    CCDS: "홀수",
    CCJJ: "합약보유평균가격",
    DQJG: "현재 가격",
    QDJQR: "로봇 시동",
    ZTJQR: "로봇 일시 중지",
    KQMR: "매입을 개시하다",
    ZTMR: "매입 중단",
    JQRXQ: "로봇 상세 정보",
    JXJQRBKCZ: "미러링된 로봇을 작동할 수 없습니다.",
    ZWBDJYSAPI: "거래소 API가 일시적으로 바인딩되지 않음",
    LJBD: "지금 바인딩",
    CJWGJRQ: "메시 로봇 만들기",
    WGCLJRZYL: "그리드 전략 오늘의 총 이익(USDT)",
    WGLSZYL: "그리드 히스토리 총 수익:",
    WGLR: "网격이익",
    WGZDJ: "그리드 최저 가격",
    WGZGJ: "그리드 최고가",
    CJDTJQR: "고정 투입 로봇 생성",
    DTCLJRZYL: "투자 전략 오늘 총이익(USDT)",
    DTLSZYL: "정액 투자 역사 총이익:",
    DTLR: "정액이윤",
    MRJ: "매입가",
    ZYBL: "자르기 축척",
    ZSBL: "손실 방지 비율",
    TZCF: "반복 중지",
    CFZX: "반복 수행",
    GBJQR: "로봇 끄기",
    GBJQRHWFCQ:
      "로봇을 끄면 다시 시작할 수 없습니다. 자동으로 USDT로 판매할 수 있는지 확인하십시오.？",
    QBWASCJMC: "시장가에 팔아 주세요",
    BYLWZJM: "아니요, 제가 팔아요",
    GBJQRWFCQ:
      "로봇을 종료하면 다시 시작할 수 없습니다. 종료 여부를 확인하십시오.？",
    QX: "취소",
    OK: "확인",
    XZCFJQRH:
      "반복 수행 중지를 선택하면 로봇이 이 차단 후에 정책 실행을 중지합니다. 반복 수행을 중지하시겠습니까?？",
    XZTZJQRH:
      "반복 실행을 선택한 후 로봇은 이번 차감 후 목표 매입 가격으로 전략을 계속 실행합니다. 반복 실행을 확정합니까?？",
    ZTJQRH:
      "로봇을 일시 중지하면 시스템이 매입하지도, 매도하지도 않을 것입니다. 로봇을 일시 중지하시겠습니까?？",
    QDJQRH:
      "로봇을 작동시키면 시스템이 매수 매도로 돌아갑니다. 로봇을 작동시키시겠습니까?？",
    KQMRH:
      "매수를 시작하면 시스템이 자동으로 매수 매도를 합니다. 당신은 매수를 시작하시겠습니까?？",
    ZTMRH:
      "매수를 일시 중지하면 시스템이 자동으로 매입하지 않지만 매도에 영향을 미치지 않습니다. 당신은 매수를 일시 중지하시겠습니까?？",

    // api授权
    APISQ: "API 인증",
    QSRKEY: "거래소에서 생성된 API KEY 및 API SECRET을 입력하십시오.",
    CHAPIJC: "API 만들기 자습서",
    OK: "확인",
    APITS:
      "정책을 시작하기 전에 API 인증이 완료되었습니다. 먼저 해당 거래소로 이동하여 API를 만들고 이 페이지에서 바인딩 인증을 완료하십시오.",
    QSQ: "권한 부여",
    // 选择币种
    XZBZ: "통화 선택",
    SSBZ: "통화 검색",
    ZX: "자선",
    QB: "모두",
    ZWZX: "당분간 스스로 선택할 수 없다.",
    // 历史机器人
    MD: "마틴",
    WG: "메쉬",
    DT: "정투",
    HY: "계약",
    // 选择交易所
    XZJYS: "거래소 선택",
    XZJYSSM: "바인딩이 승인된 거래소 중 하나를 선택하여 정책을 시작합니다.",
    JYS: "거래소",
  },
  // 创建机器人
  CHUANGJIAN: {
    // 定投
    ZYJBDXYXJ: "흑자정지가격은 현재가격보다 작아서는 안 된다.",
    ZXTZ: "최소 투자",
    ZYBLBDXY: "이음새 비율은 보다 작아서는 안 됩니다.",
    ZTZEBDXY: "총 투자액은 보다 작아서는 안 된다.",
    XHCZBLZX: "최소 0.5%의 루프 재설정 비율",
    CJDTJQR: "고정 투입 로봇 생성",
    SZ: "설정",
    YSJMR: "시가로 매입하다",
    YXJMR: "제한가로 매입하다.",
    MRJG: "매입 가격",
    ZSMJGMR: "어떤 가격에 매입할 것인가",
    ZYJ: "적립 가격",
    JGDDDSSMC: "가격이 얼마에 도달하면 팔린다",
    ZYBL: "자르기 축척",
    ZDYLDSSMC: "최소 수익이 얼마면 팔린다",
    ZHU: "주: 차감 비율에 도달하면 직접 매도하지 않고, 콜백할 때 매도한다.",
    ZTZE: "총투자액",
    ZXTZ: "최소 투자",
    KYZJ: "가용 자금:",
    QRCS: "매개 변수 확인",
    ZSBL: "손실 방지 비율",
    SR0BZDZS: "0을 입력하면 자동으로 손상되지 않습니다.",
    HTMRBL: "콜백매입 비율",
    JGDDZDDDSSMR: "가격이 최저점에 도달하면 얼마만큼 되찾을 때 매수",
    HTMCBL: "콜백 매출 비율",
    JGDDZGDDSSMC: "가격이 최고점에 도달하면 얼마를 되찾으면 팔린다",
    XHCZBL: "루프 배율 재설정",
    XHSMRJGXTBL: "순환시 매입가격 인하비율",
    FQ: "포기하다",
    QRSZ: "확인",
    // 마틴
    ZXTZEW: "최소 투자 금액은 다음과 같습니다.",
    ZDSLQJW: "주문 수량 구간은 다음과 같습니다.",
    BCBLQJ: "보충 창고 비율 구간",
    BCZFBLQJW: "보충 창고 증가폭 비율 구간은 다음과 같다.",
    ZYBLQJW: "차단 비율 구간은 다음과 같습니다.",
    BCBSQJW: "보충 창고 배수 구간은 다음과 같다.",
    CJMDJQR: "마틴 로봇 만들기",
    GQQRNH: "지난 7일간의 세월",
    AICL: "AI 전략",
    DCZDTXCS: "이 자동 채우기 매개변수를 누릅니다.",
    SYAICL: "AI 전략 사용",
    SDSZ: "수동 설정",
    ZDSL: "주문 수량",
    ZDXXBCCS: "최대 하향 보충 횟수",
    BCBL: "보충 창고 비율",
    MCBCDBL: "매회 보충 창고의 비율",
    BCZFBL: "마진 증가",
    ZBCBLSDZF: "보충 창고 비율에서의 증가 폭",
    ZYBL: "자르기 축척",
    YJMCSDYLBL: "예상 매도 시점의 이익 비율",
    MCZF: "매번 증폭",
    BCBS: "보충 창고 배수",
    // 网格
    WGS: "메쉬 수",
    QJJGBHF: "구간 가격이 올바르지 않습니다.",
    MGZXLRW: "칸당 이윤은 최소",
    CJWWGJQR: "메시 로봇 만들기",
    NRW: "당신이 생각하기에",
    ZDJ: "최저가",
    ZGJ: "최고가",
    QJSXJG: "구간별 상한 가격",
    QJXXJG: "구간 하한 가격",
    WGSL: "메쉬 수",
    MGLR: "칸당 이윤:",
    JGFW: "가격대",
    HD: "슬라이더",
    // ---启动
    XDZ: "주문중",
    CZDTJQR: "고정 투입 로봇 생성",
    DQJG: "현재 가격",
    SJMRJSM:
      "실제 매입가는 거래소에서 돌아온 거래의 평균 가격을 기준으로 한다.",
    ZDSHCJ: "얼마나 오른 후에 거래가 이루어지다.",
    MCJG: "매도 가격",
    YJZTZE: "예상 총 투자액 ",
    KYYE: "가용 잔액",
    QDJQR: "로봇 시동",
    CJMDJQR: "마틴 로봇 만들기",
    JG: "가격",
    TZE: "投资额",
    SL: "수량 ",
    CJWGJQR: "메시 로봇 만들기",
    WGQJ: "그리드 구간",
  },
  // 机器人详情
  XIANGQING: {
    GDXQ: "주문 상세 정보",
    JYJL: "거래 기록",
    JQRCS: "로봇 매개변수",
    DTJQRXQ: "정투 로봇 상세 정보",
    DQJG: "현재 가격",
    BUY: "매입",
    SELL: "팔다",
    CCSL: "보유 수량",
    CCJJ: "합약보유평균가격",
    JQRZT: "로봇 상태",
    DDMR: "매입 대기",
    JHMRJ: "계획매입가격",
    ZDMRJ: "최저 매입 가격",
    JQRZT: "로봇 상태",
    DDMC: "매도를 기다리다",
    SJMRJ: "실매입가",
    MBZYJ: "목표 적립 가격",
    ZGMCJ: "최고 매도 가격",
    ZSJ: "손해 방지 가격",
    DQJGGYMR:
      "현재 가격이 최저 매입 가격보다 높고 계획 매입 가격보다 낮을 때 매입",
    DQJGDYMC:
      "현재가격이 목표흑자중지가보다 높고 최고매도가보다 낮을 경우 흑자중지매도. 현재 가격이 손실중지가보다 낮을 경우 손실중지매도",
    JQRYBTZ: "로봇이 중지되었습니다.",
    JXJQRBKCK: "미러링된 로봇을 볼 수 없습니다.",
    XXTLCS: "24H차리 횟수",
    ZTLCS: "총 차익 횟수",
    RJCS: "일 평균 횟수",
    TLJL: "차익 기록",
    DTJQRWCYCMRMC:
      "정투 로봇은 1회 매입과 대응매도를 완성한 후 1회 차익으로 간주합니다.",
    MDJQRWCYCMRMC:
      "마틴 로봇은 1회 매입과 대응매도를 완료한 후 1회 차익으로 간주합니다.",
    BDJQRWCYCMRMC:
      "마틴 로봇은 1회 매입과 대응매도를 완료한 후 1회 차익으로 간주합니다.",
    WGJQRWCYCMRMC:
      "메시 로봇은 1회 매수와 대응매도를 완료한 후 1회 차익으로 간주합니다.",
    HYJQRWCYCMRMC:
      "계약 로봇이 구매 및 해당 판매를 완료하면 차익 거래로 간주됩니다.",
    ZLR: "총이익",
    CJJJ: "거래 평균 가격",
    CJL: "거래량",
    CJE: "거래액",
    DTCLCS: "결정 투사 전략 매개변수",
    MDCLCS: "마틴 정책 매개변수",
    WGCLCS: "그리드 정책 매개 변수",
    JYBD: "거래대상",
    ZTZE: "총투자액",
    ZYBL: "자르기 축척",
    JHMRJG: "계획 매입 가격",
    HTMRBL: "콜백매입 비율",
    HTMCBL: "콜백 매출 비율",
    ZSBL: "손실 방지 비율",
    XHCZBL: "루프 배율 재설정",
    //
    MDJQRXQ: "마틴로보틱스 상세 정보",
    JG: "가격",
    TZE: "투자액",
    SL: "수",
    YMR: "매입됨",
    DHT: "콜백 대기 중",
    SDTZE: "첫 투자액",
    JHZD: "계획 주문",
    BCBL: "보충 창고 비율",
    BCZF: "보충 창고 증가폭",
    BCBS: "보충 창고 배수",
    //
    WGJQRXQ: "메쉬 로봇 상세 정보",
    MRJG: "매입 가격",
    ZDSHCJ: "얼마나 오른 후에 거래가 이루어지다.",
    MCJG: "매도 가격",
    JCSMRSL: "창고건설시 매입수량",
    JCSMRJJ: "창고 건설 시 매입 평균 가격",
    QJSXJG: "구간별 상한 가격",
    QJXXJG: "구간 하한 가격",
    WGSL: "메쉬 수",
    MGLR: "칸당 이윤",
    HD: "슬라이더",
    MGMMSL: "칸당 매매 수량",
  },
  ABOUTUS: {
    GYWM: "우리에 대해",
    SM: `
        <p>Tradease Quantitative Robot은 Tradease Technology에서 출시 한 암호 화폐 거래 플랫폼 전반에 걸친 제 3 자 거래 전략 소프트웨어로, 사용자가 자유롭게 구성 가능한 정량적 전략을 통해 거래 차익 거래를 자동화 할 수 있도록 도와줍니다.</p >
        <p>이 플랫폼은 그리드 트레이딩 전략 / 마틴 트레이딩 전략 / 고정 투자 트레이딩 전략과 같은 다양한 기능을 포함하되 이에 국한되지 않고 사용자에게 서비스를 제공 할 수있는 정량적 도구를 지속적으로 혁신하고 심화하여 사용자가 쳐다 보지 않고도 쉽게 수익을 올릴 수 있도록 할 것입니다. 시장에서.</p >
        <p>신뢰 해주셔서 감사합니다. 의견이나 제안이 있으시면 작업 지시 시스템을 통해 고객 서비스에 제출 하시거나 WeChat 및 Weibo로 문의 해주십시오.</p >
        <p></p >
        <p></p >
        `,
  },
};
