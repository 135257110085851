export default {
  YISHUPLUSH: {
    HUOBI: "Huobi",
    XINSHOUZHINAN: "คู่มือ",
    JIARUSHEQU: "ชุมชน",
    HUODELIRUNKOUCHU: "หักเมื่อได้กำไร",
    JQRXQ: "รายละเอียด",
    MC: "เต็ม",
    FC: "บางส่วน",
    HY: "ฟิวเจอร์",
    XH: "สปอต",
    HYSYPH: "อันดับฟิวเจอร์",
    XHSYPH: "อันดับสปอต",
    ZCGZ: "มูลค่า",
    ZCZH: "พอร์ตการลงทุน",
    FCGL: "การจัดการบางส่วน",
    CJMCJQR: "สร้างหุ่นยนต์",
    CJFCJQR: "สร้างหุ่นยนต์",
    MCCLZYL: "กำไรวันนี้",
    MCCLLSZYL: "กำไรรวม",
    FCCLZYL: "กำไรวันนี้",
    FCCLLSZYL: "กำไรรวม",
    BEI: "ครั้ง",
    GUZHI: "มูลค่า",
    LIRUN: "กำไร",
    KCW: "ตำแหน่งเปิด",
    ZYFS: "วิธี TP",
    BFB: "เปอร์เซ็นต์",
    KD: "ลอง",
    PD: "ปิดลอง",
    KK: "ลอง",
    PK: "ปิดลอง",
    SK: "L&S",
    CLCS: "พารามิเตอร์",
    KCFX: "ทิศทาง",
    GGBS: "การเลือก",
    KDZB: "ตัวบ่งชี้ลอง",
    XZDDKCW: "ตำแหน่งลอง",
    DDZYFS: "TP สำหรับ Long",
    DDZYZB: "ตัวบ่งชี้ TP สำหรับ Long",
    DDZYBL: "เปอร์เซ็นต์ TP สำหรับ Long",
    DDZYCHBL: "Trailing TP สำหรับ Long",
    DDZSBL: "เปอร์เซ็นต์การขาดทุนสำหรับ Long",
    XT: "ทางเลือก",
    KKZB: "ตัวบ่งชี้ Short",
    XZKDKCW: "ตำแหน่ง Short",
    KDZYFS: "วิธี TP สำหรับ Short",
    KDZYZB: "ตัวบ่งชี้ TP สำหรับ Short",
    KDZYBL: "เปอร์เซ็นต์ TP สำหรับ Short",
    KDZYHCCBL: "Trailing TP สำหรับ Short",
    KDZSBL: "เปอร์เซ็นต์การขาดทุนสำหรับ Short",

    KCZB: "ตัวชี้วัดการเปิดใช้",
    ZYTJ: "เงื่อนไขการบริหารกำไร",
    BCFZZB: "ตัวชี้วัดเสริมเติม",
    XZBCW: "เลือกตำแหน่ง",
    DX: "คูณ",
    KCTJ: "เงื่อนไขการเปิดใช้งาน",
    FCJQRXQ: "รายละเอียด",
    MCJQRXQ: "รายละเอียด",
    ZDSL: "จำนวนสั่งซื้อ",
    ZYBL: "อัตราส่วน TP",
    ZYHCBL: "อัตราส่วน TP และ Drawdown",
    HTMCBL: "อัตราส่วนขายทำกำไร",
    YL: "ดูตัวอย่าง",
    BCW: "ตำแหน่งเสริมเติม",
    DENGDAI: "รอ",
    FCCL: "ยานย่อยแผนการ",
    JYCL: "ยุทธวิธีการซื้อขาย",
    ZYZB: "ตัวชี้วัด TP",
    DUODAN: "ซื้อ",
    KONGDAN: "ขาย",
    ZJMCJ: "ราคาขายที่ดีที่สุด",
  },

  NEWGN: {
    BD: "พุดดิ้ง",
    BDCL: "กลยุทธ์พุดดิ้ง",
    CJBDJRR: "สร้างหุ่นยนต์พุดดิ้ง",
    BDCLJRZYL: "กลยุทธ์พุดดิ้ง กำไรรวมวันนี้",
    BDLSZYL: "กำไรรวมในอดีตของพุดดิ้ง",
    BDLR: "กำไรพุดดิ้ง",
    BDJRRXQ: "รายละเอียดหุ่นยนต์พุดดิ้ง",
    XYGZCW: "ระดับการสนับสนุนถัดไป",
    ZCWZDSLBF:
      "จำนวนระดับแนวรับไม่ตรงกับจำนวนคำสั่งซื้อขาย จำเป็นต้องเลือกจำนวนระดับแนวรับดังนี้:",
    YL: "ดูตัวอย่าง",
    XRCLK: " ไลบรารีกลยุทธ์ ",
    ZCW: "สนับสนุน",
    FZXG: "นาทีนอกเส้นทาง",
    XSXG: "ปิดชั่วโมง",
    RXG: "ใต้รางรถไฟ",

    ZCFX: "การวิเคราะห์สินทรัพย์",
    ZZC: "สินทรัพย์รวม",
    ZRSY: "รายได้เมื่อวาน",
    RSY7: "รายได้ 7 วัน",
    RSY30: "Penghasilan 30 hari",
    LSSY: "ผลตอบแทนทางประวัติศาสตร์",
    SYFX: "การวิเคราะห์กำไร",
    TLFX: "การวิเคราะห์อนุญาโตตุลาการ",
    LJSYL: "อัตราผลตอบแทนสะสม",
    LJSY: "รายได้สะสม",
    MRSY: "รายได้รายวัน",
    LJTL: "การเก็งกำไรสะสม",
    MRTL: "การเก็งกำไรรายวัน",
    DAY: "วัน",
    MONTH: "ดวงจันทร์",
  },

  XROBOT: {
    XSHUOMING:
      "X-Robot คือห้องปฏิบัติการที่ให้ผู้ใช้ตั้งค่าพารามิเตอร์กลยุทธ์อย่างเปิดเผยมากขึ้น และสามารถบันทึกกลยุทธ์และนำไปใช้กับ Martin Robots ได้อย่างรวดเร็ว",
    ZDSL: "ปริมาณการสั่งซื้อ",
    ZYBL: "อัตราส่วนกำไร",
    BCBS: "มาร์จิ้นทวีคูณ",
    HTMCBL: "อัตราส่วนการโทรกลับเพื่อขาย",
    YL: "ดูตัวอย่าง",
    XTCLBKBJ: "แก้ไขนโยบายระบบไม่ได้",
    BJ: "แก้ไข",
    ZWCL: "ไม่มีกลยุทธ์",
    TJXCL: "เพิ่มกลยุทธ์ใหม่",
    BDTS: "อัตราการซื้อกลับในกลยุทธ์พุดดิ้งไม่มีผล",
    XBSYD: "เมื่อเทียบกับอัตราส่วนการเติมเต็มคำสั่งซื้อก่อนหน้า",
    XBSYD2: "เมื่อเทียบกับการสั่งซื้อครั้งก่อน อัตราส่วนการซื้อจะถูกเรียกกลับ",
    BJCL: "กลยุทธ์การแก้ไข",
    SC: "ลบ",
    SZZDSL: "กำหนดจำนวนการสั่งซื้อ",
    SZBCJH: "ตั้งค่าแผนการเรียกมาร์จิ้น",
    SZZYJH: "ตั้งค่าแผนทำกำไร",
    ZXCL: "เลือกกลยุทธ์",
    QD: "แน่นอน",
    CLMC: "ชื่อกลยุทธ์",
    QTXCLMC: "กรุณากรอกชื่อกลยุทธ์",
    ZDBCS: "จำนวนการเติมลงสูงสุด สูงสุด 10 สามารถเติมใน",
    BCBSSM:
      "ทวีคูณของจำนวนการเติมเต็มแต่ละครั้งเมื่อเทียบกับจำนวนการเติมเต็มครั้งสุดท้าย",
    ZDSLZD: "จำนวนคำสั่งซื้อสูงสุดคือ10",
    ZDSLZX: "ปริมาณการสั่งซื้อขั้นต่ำคือ2",
    XYB: "ขั้นตอนต่อไป",
    SYB: "ก่อนหน้า",
    XBYCCJJZYBL: "Take Profit Ratio เทียบกับราคาโพซิชั่นเฉลี่ย",
    XBYCCJJHTMCBL: "อัตราส่วนการขายโทรกลับเทียบกับราคาตำแหน่งเฉลี่ย",
    WC: "เสร็จ",
  },
  MONI: {
    MNZH: "บัญชีทดลอง",
    MNZHCSJE: "จำนวนเงินเริ่มต้นของบัญชีทดลอง",
    MNSM: "บัญชีทดลองใช้เพื่อจำลองธุรกรรมของผู้ใช้บน Binance Exchange เท่านั้น และจะไม่สนใจปัญหาต่างๆ เช่น ความลึกของธุรกรรม เมื่อสร้างหุ่นยนต์ ให้เลือกธุรกรรมจำลองเพื่อเริ่มต้นหุ่นยนต์และชำระราคา Binance Exchange แบบเรียลไทม์ จำนวนเงินเริ่มต้นของการทำรายการจำลองคือ ระบบจะตั้งค่าให้อัตโนมัติและไม่นับยอดเทิร์นโอเวอร์ โปรดตรวจสอบรายละเอียดผ่านแผงข้อมูล",
    CKMNZH: "ดูบัญชีทดลอง",
    MN: "การจำลอง",
    MNZHZCZE: "รวมสินทรัพย์จำลอง(U)",
  },
  HEYUE: {
    HY: "สัญญา",
    HYCL: "กลยุทธ์สัญญา",
    HYCLJRZYL: "กลยุทธ์สัญญา กำไรรวมวันนี้",
    HYCLLSZYL: "กำไรในอดีตของสัญญาทั้งหมด:",
    BCTP: "บันทึกภาพ",
    BAHY: "สัญญา Binance",
    CJHYJQR: "สร้างบอทสัญญา",
    KD: "BUY",
    KK: "SELL",
    KC: "เปิดตำแหน่ง",
    PC: "การชำระบัญชี",
    ZYJ: "ขายทำกำไร",
    ZSJ: "ราคาหยุด",
    BZJLX: "ประเภทมาร์จิ้น",
    QUANC: "เต็มโกดัง",
    ZHUC: "โกดังตามโกดัง",
    HYJQRXQ: "รายละเอียดหุ่นยนต์ทำสัญญา",
    DDKC: "รอเปิดตำแหน่ง",
    JHKCJ: "ราคาเปิดตามแผน",
    DDPC: "等อยู่ระหว่างการชำระบัญชี",
    SJKCJ: "ราคาเปิดจริง",
    HYJQRWCYCMRMC:
      "หลังจากที่หุ่นยนต์ทำสัญญาเสร็จสิ้นการซื้อและการขายที่เกี่ยวข้อง จะถือเป็นการเก็งกำไร",
    KCJG: "ราคาเปิด",
    GGBS: "การงัด",
    KCFX: "เปิดทิศทาง",
    TDHYCCL: "กลยุทธ์การทำสัญญาของ TA",
    QDMRJG: "กรุณายืนยันราคาซื้อก่อน",
    ZSBL: "อัตราส่วนการหยุดขาดทุน",
    XGCS: "แก้ไขใหม่",
    CSCW: "️ข้อผิดพลาดของพารามิเตอร์!",
  },

  // 补充
  ADD: {
    MDGD: "มาร์ติน",
    DTGD: "การลงทุนคงที่",
    WGGD: "กริด",
    WG: "กริด",
    MD: "มาร์ติน",
    DT: "การลงทุนคงที่",
    HB: "Huobi",
    BA: "Binance",
    FX: "แบ่งปัน",
    JYBD: "เรื่อง",
    SYCL: "กลยุทธ์",
    SJSYL: "รายได้",
    YSJQR: "Tradease",
    NDLHGJ: "Make trade easy",
    ZSYL: "ฉันเอา 100 เพราะคะแนนสมบูรณ์แบบเพียง 100",
    FSYL: "วงกลมสกุลเงินนั้นโหดเหี้ยมและน่ารัก",
  },
  // ----------其他----------
  ORDER: {
    FZCG: "คัดลอกสำเร็จ",
    FZSB: "คัดลอกล้มเหลว",
    ZWSJ: "ไม่มีข้อมูล",
    QSRZFMM: "กรุณาใส่รหัสผ่านการชำระเงิน",
    QD: "ยืนยัน",
    KY: "ใช้ได้",
    TIAN: "ท้องฟ้า",
    SHI: "กาละ",
    FEN: "บทบาท",
  },
  MIANBAN: {
    HB: "Huobi",
    BA: "Binance",
    OY: "OKEX",
    SYZJ: "เงินคงเหลือ",
    MDCL: "Martin Strategy",
    WGCL: "กลยุทธ์กริด",
    DTCL: "การลงทุนคงที่",
    CXJJ: "โหลดซ้ำ",
    BAZCZE: "Binance สินทรัพย์รวม",
    HBZCZE: "สินทรัพย์รวมของ Huobi",
    SYZJZ: "หมายเหตุ : เงินที่เหลือเทียบเท่าทรัพย์สินที่ไม่เข้าร่วมกลยุทธ์",
    ZCXQ: "รายละเอียดทรัพย์สิน",
    DQMYKYPC: "ขณะนี้ไม่มีสกุลเงินที่สามารถปิดได้",
    YJPC: "การชำระบัญชี",
    BZ: "สกุลเงิน",
    SL: "ปริมาณ",
    YE: "สมดุล",
    KJY: "ซื้อขายได้",
    ZWZC: "ไม่มีทรัพย์สิน โปรดไปที่การแลกเปลี่ยนเพื่อเติมเงิน",
    ZWBDJYSAPI: "API การแลกเปลี่ยนยังไม่ถูกผูกไว้",
    LJBD: "ผูกตอนนี้",
    ZYSX: "หมายเหตุ: หลังจากการชำระบัญชีด้วยคลิกเดียว สกุลเงินทั้งหมดจะถูกขายในราคาตลาดปัจจุบันและกลายเป็น USDT และหุ่นยนต์ที่เกี่ยวข้องจะถูกปิดโดยอัตโนมัติ โปรดดำเนินการด้วยความระมัดระวัง! ! !",
    YXBZSL: "จำนวนสกุลเงินที่เลือก:",
    PCYJKD: "ตำแหน่งปิดคาดว่าจะว่าง is",
    QUANXUAN: "เลือกทั้งหมด",
    QRPC: "ยืนยันการปิด",
    XUXIAO: "ยกเลิก",
    QQRPC: "กรุณาใส่รหัสความปลอดภัยและยืนยันการปิด",
    LHCL: "กลยุทธ์เชิงปริมาณ",
    ZCFB: "การกระจายสินทรัพย์",
  },
  // ---------------------------------登录-------------------------------------------
  LOGIN: {
    // ------------------login--------------
    LOGIN_SUCCESS: "เข้าสู่ระบบเรียบร้อยแล้ว",
    PLEASE_RIGHT_PHONE_EMAIL:
      "กรุณาป้อนหมายเลขโทรศัพท์มือถือที่ถูกต้องหรือกล่องจดหมาย",
    NUM_ERROR: "รหัสผ่านบัญชีผิดพลาด",
    WELCOME: "ยินดีต้อนรับเข้าสู่ระบบ",
    Tradease: "Tradease",
    LOGIN_TYPE: "กรุณาเข้าสู่ระบบผ่านทางอีเมลของคุณ",
    PLEASE_INPUT: "กรุณาใส่ชื่อผู้ใช้สำหรับบัญชีอีเมลของคุณ",
    PLEASE_PASSWORD: "กรุณาใส่รหัสผ่าน",
    FORGET_PASSWORD: "ลืมรหัสผ่าน",
    LOGIN_NOW: "เข้าสู่ระบบทันที",
    NO_ACCOUNT: "ไม่มีบัญชี？",
    REG_NOW: "ลงทะเบียนทันที",
    AGREE_XY: "继เข้าสู่ระบบต่อไปจะถือว่าได้รับการอนุมัติ",
    AGREE_XY2: "《ข้อตกลงผู้ใช้》",
  },
  DOWNLOAD: {
    ZCWC: "ลงทะเบียนเสร็จสมบูรณ์",
    GXN: "ยินดีด้วยนะ",
    SLWCZC: "ลงทะเบียนเสร็จเรียบร้อยแล้ว",
    DJZZAPP: "คลิกที่ปุ่มด้านล่างเพื่อดาวน์โหลด",
    XZAZ: "ดาวน์โหลดแอมป์",
    XZIOS: "ดาวน์โหลดเพลง",
  },
  // 修改设置支付密码
  OTCPASSWORD: {
    ZFMM: "จ่ายรหัสผ่าน",
    // PLEASE_INPUT_CODE: "ป้อนรหัสการตรวจสอบกราฟิก",
    NUM_ERROR: "ข้อผิดพลาดในการป้อนข้อมูลบัญชี",
    PASSWORD_LEAST: "รหัสผ่านอย่างน้อยแปดบิตตัวอักษรและตัวเลข",
    PASSWORD_NOSAME: "สองรหัสผ่านไม่ตรงกัน",
    MODIFY_PAY_PASSWORD: "แก้ไขรหัสผ่านการชำระเงิน",
    SET_PAY_PASSWORD: "ตั้งค่ารหัสผ่านการชำระเงิน",
    // TX_YANZHENGMA: "รหัสการตรวจสอบกราฟิก",
    // PLEASE_TX_YANZHENGMA: "ป้อนรหัสการตรวจสอบกราฟิก",
    HQ_YANZHENGMA: "รับ",
    YZM: "รหัสการตรวจสอบ",
    PLEASE_YZM: "กรุณาใส่รหัสการตรวจสอบ",
    PLEASE_PASSWORD: "กรุณาใส่รหัสผ่าน",
    CONFIRM_PASSWORD: "ยืนยันรหัสผ่าน",
    PLEASE_CONFIRM_PASSWORD: "กรุณายืนยันรหัสผ่าน",
    OK: "ยืนยัน",
    ERROR: "ล้มเหลวในการรับบัญชีกรุณาเข้าสู่ระบบอีกครั้ง",
  },
  // 修改密码
  PASSWORD: {
    PLEASE_RIGHT_NUMBER: "กรุณาใส่หมายเลขบัญชีที่ถูกต้อง",
    NUM_ERROR: "ข้อผิดพลาดในการป้อนข้อมูลบัญชี",
    PASSWORD_LEAST: "รหัสผ่านต้องมีอักขระอย่างน้อย 8 ตัว ตัวอักษรบวกตัวเลข",
    PASSWORD_NOSAME: "รหัสผ่านสองอันไม่สอดคล้องกัน",
    ZHAOHUI_PASSWORD: "ดึงรหัสผ่าน",
    TIPS: "กรุณาดึงรหัสผ่านเข้าสู่ระบบตามรหัสยืนยันของหมายเลขโทรศัพท์มือถือที่ลงทะเบียน",
    EMAIL_NUM: "กล่องจดหมาย",
    PHONE_NUM: "หมายเลขโทรศัพท์",
    HQ_YANZHENGMA: "รับ",
    PLEASE_YZM: "กรุณากรอกรหัสยืนยัน",
    PASSWORD: "รหัสผ่าน",
    PLEASE_PASSWORD: "กรุณาใส่รหัสผ่าน",
    PLEASE_PASSWORD2: "กรุณาใส่รหัสผ่านอีกครั้ง",
    // TISHI: "รหัสผ่านเข้าสู่ระบบคือรหัสผ่านการชำระเงิน และรหัสผ่านการชำระเงินสามารถเปลี่ยนได้ในศูนย์ความปลอดภัยหลังจากเข้าสู่ระบบ",
    CONFIRM_PASSWORD: "ยืนยันรหัสผ่าน",
    PLEASE_CONFIRM_PASSWORD: "กรุณายืนยันรหัสผ่านของคุณ",
    OK: "ยืนยัน",

    XGDLMM: "แก้ไขรหัสผ่านเข้าสู่ระบบ",
    QSRYMM: "กรุณาใส่รหัสผ่านเดิม",
  },
  // 注册
  REGISTER: {
    ZCXZH: "ลงทะเบียนบัญชีใหม่",
    HYZC: "ยินดีต้อนรับสู่การลงทะเบียนบัญชีใหม่",
    SJH: "กรุณากรอกหมายเลขโทรศัพท์",
    YHM: "ชื่อผู้ใช้",
    YQM: "กรุณาใส่รหัสเชิญ",
    PLEASE_RIGHT_PHONE: "โปรดป้อนหมายเลขโทรศัพท์ที่ถูกต้อง",
    PLEASE_RIGHT_EMAIL: "กรุณากรอกอีเมล์ของคุณva",
    PLEASE_RIGHT_PHONE_EMAIL: "กรุณากรอกบัญชีที่ถูกต้อง",
    PLEASE_INPUT_CODE: "กรุณาใส่รหัสยืนยันกราฟิก",
    NUM_ERROR: "ข้อผิดพลาดในการป้อนข้อมูลบัญชี",
    PASSWORD_LEAST: "รหัสผ่านต้องมีอักขระอย่างน้อย 8 ตัว ตัวอักษรบวกตัวเลข",
    PASSWORD_NOSAME: "รหัสผ่านสองอันไม่สอดคล้องกัน",
    PHONE_REGISTER: "ลงทะเบียนโทรศัพท์ของคุณ",
    EMAIL_REGISTER: "การลงทะเบียนอีเมล",
    PHONE_NUM: "หมายเลขโทรศัพท์",
    EMAIL_NUM: "กล่องจดหมาย",
    YANZHENGMA: "รหัสยืนยัน",
    PLEASE_YANZHENGMA: "กรุณากรอกรหัสยืนยัน",
    HQ_YANZHENGMA: "รับ",
    PASSWORD: "รหัสผ่าน",
    PLEASE_PASSWORD: "กรุณาใส่รหัสผ่าน",
    PLEASE_PASSWORD2: "กรุณาใส่รหัสผ่านอีกครั้ง",
    TISHI:
      "รหัสผ่านการชำระเงินจะเหมือนกับรหัสผ่านเข้าสู่ระบบโดยค่าเริ่มต้นและสามารถแก้ไขได้ในศูนย์ความปลอดภัย",
    NOW_REGISTER: "สมัครตอนนี้เลย",
    HAD_ZHANGHAO: "มีบัญชีอยู่แล้ว?",
    HAD_ZHANGHAO2: "เข้าสู่ระบบทันที",
    REGISTER_SUCESS: "ลงทะเบียนสำเร็จ",
    PLEASW_NUM: "กรุณาใส่บัญชี",
    LJXZ: "ดาวน์โหลดแอปเลย",
  },
  // 选择国家
  // TELPREFIX: {
  //   CHOOSE_CITY: "เลือกประเทศ",
  //   OK: "กำหนด",
  //   SEARCH_CITY: "ค้นหาประเทศ",
  // },
  // ------------------------------------登录注册结束----------------------------
  // ----------------------------------------跟单-------------------
  GENDAN: {
    QBD: "ได้โปรดผูกมัด",
    DTGD: "สั่งซื้อสารคดี",
    CLYJNH: "กลยุทธ์นี้คาดว่าจะเป็นรายปี",
    YYX: "ได้รับการดำเนินการ:",
    TIAN: "วัน",
    CLZPM: "การจัดอันดับรายสัปดาห์ของกลยุทธ์นี้",
    DTCLCS: "พารามิเตอร์กลยุทธ์การลงทุนคงที่",
    JYPT: "แพลตฟอร์มการซื้อขาย",
    JYBD: "เรื่อง",
    MRJG: "ราคาซื้อ",
    ZYBL: "อัตราส่วนกำไร",
    ZSBL: "อัตราส่วนการหยุดขาดทุน",
    HTMRBL: "อัตราส่วนการซื้อโทรกลับ",
    HTMCBL: "อัตราส่วนการโทรกลับเพื่อขาย",
    XHCZBL: "อัตราส่วนการรีเซ็ตวงจร",
    GSGCL: "ทำตามกลยุทธ์",
    MDGD: "มาร์ติน สารคดี",
    MDCLCS: "พารามิเตอร์กลยุทธ์มาร์ติน",
    ZDSL: "ปริมาณการสั่งซื้อ",
    BCBL: "อัตราส่วนการโทร",
    BCZFBL: "เพิ่มอัตราส่วนของ Margin Call",
    BCBS: "มาร์จิ้นทวีคูณ",
    WGGD: "สารคดีกริด",
    WGCLCS: "พารามิเตอร์กลยุทธ์กริด",
    JGFW: "ช่วงราคา",
    WGSL: "จำนวนกริด",
    GE: "อา",
    MGLR: "กำไรต่อดิวิชั่น",
    HD: "เลื่อนหลุด",
  },
  // -------------------------------------资产相关开始-----------------------
  // 转出
  EXTRACT: {
    // LMC: "ชื่อลูกโซ่",
    PLEASE_LOGIN: "กรุณาเข้าสู่ระบบก่อน",
    ERROR: "บัญชีผิดพลาด โปรดออกจากระบบและเข้าสู่ระบบอีกครั้ง",
    YVE_BUZHU: "ยอดเงินไม่เพียงพอ",
    MORE_TIBI: "โอนออกสูงสุดคือ",
    LESS_TIBI: "โอนออกขั้นต่ำคือ",
    INPUT_TX_YANZHENGMA: "กรุณาใส่รหัสยืนยันกราฟิก",
    TIBI: "การถอนเงิน",
    TIBIJILU: "บันทึกการถอน",
    KE_YONG: "ที่มีจำหน่าย:",
    TIBI_ADRESS: "ที่อยู่ถอนเงิน",
    INPUT_OR_LANGAN_ADRESS: "วางที่อยู่ถอน USDT-BEP2020",
    NUM_USDT: "จำนวนการถอน", //-
    LESS_TIBI_NUM: "จำนวนเงินโอนขั้นต่ำ",
    PHONE_YANZHENGMA: "รหัสยืนยันโทรศัพท์",
    EMAIL_YANHENGMAL: "รหัสยืนยันอีเมล",
    PLEASE_YANZHENGMA: "กรุณากรอกรหัสยืนยัน",
    HQ_YANZHENGMA: "รับ",
    ZHIFU_PWD: "รหัสรักษาความปลอดภัย",
    PLEASE_ZHIFU_PWD: "กรุณากรอกรหัสผ่านการชำระเงิน",
    TIXIAN_SUCCESS: "โอนออกเรียบร้อยแล้ว",
    DAOZHANG_NUM: "มาถึงแล้วจริงๆ",
    SHOUXVFEI: "ค่าธรรมเนียมการจัดการ：",
    ITXIAN: "ยืนยันการถอน",
    TISHI: "เตือนความจำที่เป็นมิตร:",
    TISHI_1: "ปัจจุบันสามารถโอนออกได้สูงสุดต่อคนต่อวัน",
    TISHI_2: ",ขีด จำกัด การโอนเดียวคือ",
    TISHI_5:
      "เพื่อความปลอดภัยของเงินทุน เราจะตรวจสอบการโอนเงินด้วยตนเอง โปรดรออย่างอดทน",
    DQGAS: "ก๊าซปัจจุบัน:",
    QHBZ: "เปลี่ยนสกุลเงิน",
    ZCJL: "บันทึกการส่งออก",
    KZ: "สามารถโอนได้:",
  },
  // 资产页面DMO
  WALLET: {
    XLSX: "ดึงลงเพื่อรีเฟรช",
    SKLJSX: "ปล่อยรีเฟรชทันที",
    JIFEN: "อินทิกรัล:",
    GMJF: "ซื้อคะแนน",
    APISQ: "API",
    YQHY: "เชิญ",
    FWJF: "จุดบริการ",
    ZCXQ: "ทรัพย์สิน",
    RILI: "ปฏิทิน",
    YUJING: "ข้อแม้",
    JQQD: "คอยติดตาม",
    MONI: "เสมือน",
    ZWPM: "ไม่มีอันดับ",
    PAIHANG: "อันดับ",
    JIAOYIDUI: "คู่ซื้อขาย",
    PINGTAI: "แพลตฟอร์ม",
    YUJINIANHUA: "คาดหวังรายปี",

    ZCXQ: "ทรัพย์สิน",
    FWJF: "จุดบริการ",
    JFYE: "ยอดคะแนน (1 จุด = 1USDT)",
    ZHYE: "ยอดเงินในบัญชี (USDT)",

    JFLS: "การไหลแบบบูรณาการ",
    SYLS: "น้ำไหลทั้งหมด",
    ZRLS: "โอนให้ไหล",
    ZCLS: "โอนออก",
    PLEASE_LOGIN: "กรุณาเข้าสู่ระบบก่อน",
    GMJF: "ซื้อคะแนน",

    CHONG_BI: "เติมเงิน",
    TI_BI: "การถอนเงิน",
    DUI_HUAN: "แลกเปลี่ยน",
    ALL_ASSETS: "แลกเปลี่ยน",
    CAN_USE: "มีจำหน่าย",
    ONE_PRICE: "ราคาต่อหน่วย (USDT)",
    ZHE_HE: "USDT เทียบเท่า",
    ZHE_HE_CNY: "แปลงเป็น CNY",
    LOAD: "กำลังโหลด",
    LOADING: "รอขายครับ",
    PENDING: "รอขายครับ",
    SUCCESS: "ขายแล้วครับ",
    LOCK: "แช่แข็ง",
    DAI_CHUSHOU: "แช่แข็ง",
    JIAOYI_XIANGQING: "รายละเอียดการทำธุรกรรม",
    ALL_NUM: "รวม",
    SHENGYV: "ยังขายไม่ออก",
    ZHICHANLIUSHUI: "การหมุนเวียนของสินทรัพย์",
  },
  // 充值页面
  RECHARGE: {
    CHONGBI: "เติมเงิน",
    LIANNAME: "ชื่อลูกโซ่",
    PLEASE_LOGIN: "กรุณาเข้าสู่ระบบก่อน",
    FAIL_COPY: "คัดลอกไม่สำเร็จ กรุณากดค้างไว้เพื่อคัดลอก",
    SUCCESS_COPY: "คัดลอกสำเร็จ",
    CHONG_BI: "เติมเงิน",
    USDT_CHONG_BI: "โอนเข้า",
    JIEPING_SAVE_QRCODE: "ภาพหน้าจอบันทึกรหัส QR",
    COPY_ADDRESS: "คัดลอกที่อยู่",
    ZHUANRU_SHUOMING: "โอนไปยังคำแนะนำ:",
    SHUOMING_1:
      "ที่อยู่นี้เป็นที่อยู่สำหรับโอนเข้าเพียงแห่งเดียวที่คุณใช้ด้วยตัวเอง ที่อยู่สำหรับโอนเข้าของคุณจะไม่เปลี่ยนแปลงบ่อยและสามารถชาร์จใหม่ได้ หากมีการเปลี่ยนแปลง เราจะพยายามแจ้งให้คุณทราบผ่านประกาศหรืออีเมลอย่างดีที่สุด",
    SHUOMING_2:
      "กรุณาอย่าฝากสินทรัพย์ที่ไม่ใช่ ERC20_USDT ใด ๆ ไปยังที่อยู่ด้านบน มิฉะนั้น สินทรัพย์จะไม่ถูกเรียกคืน",
    SHUOMING_4:
      "โปรดอย่าฝากสินทรัพย์ที่ไม่ใช่ TRC20_USDT ใด ๆ ไปยังที่อยู่ด้านบน มิฉะนั้น สินทรัพย์จะไม่ถูกเรียกคืน ",
    SHUOMING_3:
      "กรุณาอย่าฝากทรัพย์สินที่ไม่ใช่ DMO ใด ๆ ไปยังที่อยู่ข้างต้น มิฉะนั้น ทรัพย์สินจะไม่ถูกเรียกคืน",
    QHBZ: "เปลี่ยนสกุลเงิน",
  },

  // 转出记录
  TIBIRECORD: {
    TIBIJILU: "บันทึกการถอน",
    PLEASE_LOGIN: "กรุณาเข้าสู่ระบบก่อน",
    DAI_SHEN_HE: "อยู่ระหว่างการพิจารณา",
    TI_BI_ZHONG: "การบรรจุ",
    SUCCESS: "ความสำเร็จ",
    FAIL: "ความสำเร็จ",
    TIBI_JILU: "บันทึกการส่งออก",
    NO_DATA: "ไม่มีข้อมูล",
    BIAN_HAO: "การนับ",
    SHU_LIANG: "ปริมาณ",
    TIME: "เวลา",
    ADDRESS: "ที่อยู่",
  },
  // -------------------------------------资产相关结束-----------------------
  // ------------------------镜像----------------

  JINGXIANG: {
    JXZBJ: "กระจกสำรอง",
    KSSJ: "เวลาเริ่มต้น",
    TZE: "จำนวนเงินลงทุน (U)",
    LJTZE: "เงินลงทุนสะสม (U)",
    LJSYE: "รายได้สะสม (U)",
    LJSYL: "อัตราผลตอบแทนสะสม",
    LJJXSC: "เวลามิเรอร์สะสม",
    JYPT: "แพลตฟอร์มการซื้อขาย",
    JXRC: "เยี่ยมชมกระจก",
    CLANDSYZB: "กลยุทธ์และอัตราส่วนรายได้",
    JXYHSZBKJ: "มิเรอร์การตั้งค่าผู้ใช้ไม่สามารถมองเห็นได้",
    WG: "กริด",
    MD: "มาร์ติน",
    DT: "การลงทุนคงที่",
    SYZJ: "เงินคงเหลือ",
    MDCL: "Martin Strategy",
    WGCL: "กลยุทธ์กริด",
    DTCL: "การลงทุนคงที่",
    GDY: "พ่อค้า",
    CLZB: "อัตราส่วนกลยุทธ์",
    ZWSY: "ไม่มีรายได้",
    TACL: "กลยุทธ์ของ TA",

    JYBD: "เรื่อง",
    CJSJ: "เวลาสร้าง",
    SYL: "อัตราผลตอบแทน",
    ZWSJ: "ไม่มีข้อมูล",

    TUIJIAN: "แนะนำ",
    YIJINGXIANG: "มิเรอร์",
    JX: "มิเรอร์",

    JXKQSJ: "กระจกตรงเวลา",
    XQ: "รายละเอียด",
    YGB: "ปิด",

    JXXQ: "รายละเอียดกระจก",
    QBZBJCZ: "เปิดการมิเรอร",
    QRJX: "ยืนยันมิเรอร์",
    QX: "ยกเลิก",
    QRRISHI:
      "โปรดป้อนรหัสผ่านการชำระเงินและยืนยันกลยุทธ์การติดตามของบัญชีมิเรอร์ โปรดตรวจสอบให้แน่ใจว่าบัญชีซื้อขายที่เกี่ยวข้องมีเงินสำรองเพียงพอ ผู้ใช้เป็นผู้รับผิดชอบการขาดทุนจากการมิเรอร์ด้วยตนเอง โปรดยืนยันอีกครั้ง ",
    QSRMM: "กรุณาใส่รหัสผ่าน",
    ZHYEBZ: "ยอดซื้อขายในบัญชีของคุณต่ำกว่ามาตรฐานสำรอง",
    LIJIJX: "กระจกตอนนี้",

    SSYH: "ค้นหาผู้ใช้",
    ZWJG: "ไม่มีผลลัพธ์",
    CCXQ: "รายละเอียดตำแหน่ง",
    CZRZ: "บันทึกการทำงาน",
    QXJX: "Unmirroring",
    ZWJL: "ไม่มีบันทึก",
    CKXQ: "ดูรายละเอียด",
    QXZH: "หลังจากยกเลิกการมิเรอร์แล้ว จะไม่ทำตามบัญชีมิเรอร์โดยอัตโนมัติเพื่อดำเนินการอีกต่อไป โรบ็อตที่กำลังดำเนินอยู่ยังคงดำเนินกลยุทธ์ต่อไป และสามารถปิดได้เอง คุณสามารถยืนยันเพื่อยกเลิกการมิเรอร์ได้หรือไม่?",
    FQ: "ยอมแพ้",
    QRQX: "ยืนยัน ยกเลิก",
  },
  // -------------------------------------个人中心相关开始-------------------
  GOUMAIJIFEN: {
    GMJF: "ซื้อคะแนน",
    DQJFYE: "ซื้อคะแนน",
    JFTC: "แพ็คเกจคะแนน",
    JFTCSM: "สามารถเพิ่มคะแนนแพ็กเกจต่างๆ ลงในยอดคะแนนได้โดยตรงหลังการซื้อ",
    JF: "อินทิกรัล",
    WZK: "ไม่มีส่วนลด",
    ZK: "ส่วนลด",
    JFSM: "คำอธิบายคะแนน",
    JFSM1:
      "1. หลังจากซื้อแพ็คเกจคะแนนแล้ว (ประเภทแพ็คเกจ/จำนวนครั้งไม่ จำกัด ) คุณสามารถกลายเป็นคะแนน VIP และคะแนน VIP สามารถเชิญเพื่อน / เพลิดเพลินกับส่วนลดชุมชนอื่น ๆ และผลประโยชน์อื่น ๆ",
    JFSM2:
      "2. คะแนนใช้เพื่อหัก 20% ของรายได้ที่เกิดจากกลยุทธ์หุ่นยนต์เท่านั้น และไม่สามารถโอน/แลกเปลี่ยนได้",
    JFSM3: "3. คะแนนไม่สามารถเพิกถอนได้/ไม่สามารถคืนเงินได้หลังจากซื้อ;",
    JFSM4:
      " 4. เมื่อแต้มเหลือน้อยกว่า 10 กลยุทธ์ใหม่จะไม่สามารถดำเนินการต่อได้ และกลยุทธ์ที่ดำเนินอยู่จะไม่ได้รับผลกระทบ แต่ยอดคะแนนจะยังคงถูกหักออก และสามารถเริ่มกลยุทธ์ใหม่ได้หลังจากการต่ออายุ",
    KYYE: "ยอดเงินคงเหลือ",
    QRGM: "ยืนยันการซื้อ",
    QX: "ยกเลิก",
    QSRMM: "กรุณาใส่รหัสผ่าน",
    KYUSDT: "USDT ที่มีจำหน่าย:",
    QSRAQMM: "กรุณาใส่รหัสความปลอดภัยและยืนยันการสั่งซื้อ",
    GMHLJSX: "มีผลทันทีหลังจากซื้อ",
  },
  JXSETTING: {
    JXGL: "การจัดการภาพ",
    YXBJX: "อนุญาตให้มิเรอร์",
    YXJXSM:
      "หลังจากที่ได้รับอนุญาตให้ทำมิเรอร์แล้ว ผู้ใช้รายอื่นสามารถดูโฮมเพจของคุณได้โดยการค้นหาชื่อผู้ใช้ เฉพาะเมื่อโรบ็อตทั้งหมดถูกปิดเท่านั้นที่พวกเขาสามารถทำมิเรอร์และยกเลิกการอนุญาตให้มิเรอร์ได้",
    JXZH: "บัญชีมิเรอร์",
    JXZYKJ: "มิเรอร์โฮมเพจสามารถมองเห็นได้",
    OK: "ยืนยัน",
    CLZB: "อัตราส่วนกลยุทธ์",
    SYZB: "สัดส่วนรายได้",
    WGCL: "กลยุทธ์กริด",
    DTCL: "การลงทุนคงที่",
    MDCL: "Martin Strategy",
  },
  CUSTOMERSERVIXE: {
    MORE_INPUT: "ป้อนอักขระสูงสุด 200 ตัว",
    LIXNXI_KEFU: "ติดต่อฝ่ายบริการลูกค้า",
    PLEASE_TELL_QUE:
      "กรุณากรอกคำถามและฝ่ายบริการลูกค้าจะตอบกลับในเวลา 9:00 ถึง 18:00 น.",
    UPLOADING: "กำลังอัปโหลด...",
    TIJIAO: "ส่ง",
    HISTORY: "บันทึกประวัติศาสตร์",
    HUIFU: "ตอบ:",
    NO_DATA: "ไม่มีบันทึก",
    GDJL: "บันทึกการสั่งงาน",
    TJGD: "ส่งตั๋ว",
  },
  // 邀请好友
  FRINDSCOME: {
    PLEASE_LOGIN: "กรุณาเข้าสู่ระบบก่อน",
    FRIND_COME: "เชิญ",
    YQHYZC:
      "ชวนเพื่อนลงทะเบียนหุ่นยนต์เชิงปริมาณ Tradease เพื่อส่งเสริมการคืนเงิน",
    SMXZ: "สแกนรหัส QR ด้านล่างเพื่อลงทะเบียนและดาวน์โหลดแอป",
    FZYQLJ: "คัดลอกลิงก์คำเชิญ",
  },
  // 选择语言
  LANGSWITCH: {
    CHOOSE_CITY: "เลือกภาษา",
  },

  // 我的
  MINELIST: {
    GMJF: "ซื้อคะแนน",
    CSSYHDK: "หักหลังสร้างรายได้",
    APISQ: "การอนุญาต",
    JFZX: "ศูนย์คะแนน",
    XTGG: "ระบบแจ้งเตือน",
    BZZX: "ศูนย์ช่วยเหลือ",
    WDSQ: "ชุมชนของฉัน",
    JXGL: "การจัดการภาพ",
    AQSZ: "ตั้งค่าความปลอดภัย",
    TJGD: "ส่งตั๋ว",
    GYWM: "เกี่ยวกับเรา",
    TCDL: "ออกจากระบบ",
    QDTC: "แน่ใจว่าจะออก?",
    QX: "ยกเลิก",
    OK: "กำหนด",
  },
  // 公告
  NOTICE: {
    GONG_GAO_LIST: "ระบบแจ้งเตือน system",
    NO_DATA: "ไม่มีประกาศ",
    GONG_GAO_INFO: "รายละเอียดประกาศ",
    BZZX: "ศูนย์ช่วยเหลือ",
  },
  // 安全中心
  SAFETY: {
    ANQUAN_ZHONGXIN: "ศูนย์รักษาความปลอดภัย",
    ZHIFU_PWD: "เปลี่ยนรหัสผ่านการชำระเงิน",
    XIUGAI_PWD: "แก้ไขรหัสผ่านเข้าสู่ระบบ",
  },
  // http
  HTTP: {
    ERROR_LOG: "ไม่สามารถรับข้อมูลการเข้าสู่ระบบ",
    ERROR_WANGLUO: "คำขอล้มเหลว",
  },
  // footer

  FOOTER: {
    SHOUYE: "บ้าน",
    CELUE: "กลยุทธ์",
    MIANBAN: "แผงหน้าปัด",
    JINGXIANG: "มิเรอร์",
    ZHUANGHU: "บัญชีผู้ใช้",
  },
  // -----------------------------个人中心结束------------------------------------
  //  社区
  SHEQU: {
    SQ: "ชุมชน",
    GZ: "กฎ",
    WU: "ไม่",
    SHEQUDENGJI: "ระดับชุมชน",
    BZYJ: "ในสัปดาห์นี้",
    ZYJ: "รวม",
    WODESHEQU: "ชุมชนของฉัน",
    QXGMJF: "กรุณาซื้อคะแนนก่อนเพื่อเป็นสมาชิกวีไอพี",
    YQHY: "เชิญ",
    ZWSQ: "ไม่มีชุมชน",

    VIP1: "VIP1",
    VIP2: "VIP2",
    VIP3: "VIP3",
    VIP4: "VIP4",
    GZSM: "คำอธิบายกฎ",
    GUIZHE: `
    <h3>สมาชิกวีไอพี:</h3>
          <p>ตราบใดที่ผู้ใช้ที่ซื้อแพ็คเกจคะแนนครั้งเดียวเป็นสมาชิกวีไอพี ผู้ใช้ที่ไม่ได้ทำมิเรอร์ผู้อื่นจะหัก 10% ของกำไรที่เทียบเท่ากันหลังจากสิ้นสุดหุ่นยนต์ ผู้ใช้ที่มิเรอร์ผู้อื่นจะหัก 20% ของกำไรที่เท่ากันหลังจากสิ้นสุดหุ่นยนต์</p>

          <h3>กลไกจูงใจรางวัลหนึ่งคำแนะนำ (ปลายที่สอง):</h3>
          <p>
            ผลักดันผู้ใช้โดยตรงเพื่อรับคะแนนบริการ 10% เป็นรางวัล<br></br>
            ผู้ใช้ทางอ้อมสามารถรับคะแนนบริการ 5% เป็นรางวัล</p>

          <h3>รางวัลกลไกจูงใจสองชุมชน (ส่วนต่างระดับ/สิ้นสุดรายวัน):</h3>
          <p>
            VIP2: ผลักดันสมาชิก VIP 5 คนโดยตรงให้กลายเป็น VIP2 และรับคะแนนบริการ 5% เป็นรางวัล<br></br>
            VIP3: ดัน 3 VIP2 โดยตรงเพื่อเป็น VIP3 และรับ 10% ของคะแนนการบริการเป็นรางวัล<br></br>
            VIP4: ดัน 3 VIP3 โดยตรงเพื่อเป็น VIP4 และรับ 16% ของคะแนนการบริการเป็นรางวัล
          </p>

          <h3>กลไกจูงใจ โบนัสสามเงินปันผล (สิ้นรายวัน):</h3>
          <p>
            VIP2 ทั้งหมดถูกแบ่งออกเป็น 3% ถ่วงน้ำหนักตามอัตราส่วนประสิทธิภาพ<br></br>
            VIP3 ทั้งหมดแบ่งออกเป็น 3% ถ่วงน้ำหนักตามอัตราส่วนประสิทธิภาพ<br></br>
            VIP4 ทั้งหมดมีน้ำหนัก 3% ตามอัตราส่วนประสิทธิภาพ。
          </p>
          <h3>กลไกจูงใจ เงินปันผลสี่กระจก (กำหนดโดยกลยุทธ์):</h3>
          <p>
           หากคุณเปิดใช้งานกลยุทธ์การมิเรอร์ คุณจะได้รับ 40% ของจุดบริการที่ผู้ใช้มิเรอร์ใช้เป็นรางวัล<br></br>
          </p>
          <h3>หมายเหตุพิเศษ:</h3>
          <p>  
           กฎข้างต้นจะทำงานโดยอัตโนมัติผ่านไคลเอ็นต์ APP ของหุ่นยนต์ Tradease หากมีคนขอค่าคอมมิชชันในรูปแบบใด ๆ เช่น escrow โปรดอย่าเชื่อ!
          </p> 
    `,
  },
  // 用户协议
  AGREEMENTS: {
    USER_AGREEMENTS: "ข้อตกลงการใช้",
    XIEYI: ` <p>Tradease Robot ขอเตือนคุณ (ผู้ใช้) ให้อ่าน "ข้อตกลงผู้ใช้" นี้อย่างละเอียด (ต่อไปนี้จะเรียกว่า "ข้อตกลงผู้ใช้หุ่นยนต์ Tradease") ก่อนลงทะเบียนเป็นผู้ใช้เพื่อให้แน่ใจว่าคุณเข้าใจข้อกำหนดของข้อตกลงนี้อย่างครบถ้วน โปรดอ่านอย่างละเอียดและเลือกยอมรับหรือไม่ยอมรับข้อตกลงนี้ ถ้าคุณไม่ยอมรับเงื่อนไขทั้งหมดของข้อตกลงนี้ คุณไม่มีสิทธิ์ลงทะเบียน เข้าสู่ระบบ หรือใช้บริการที่ครอบคลุมโดยข้อตกลงนี้ การลงทะเบียน การเข้าสู่ระบบ การใช้งาน ฯลฯ ของคุณจะถือเป็นการยอมรับข้อตกลงนี้ และคุณตกลงที่จะผูกพันตามเงื่อนไขของข้อตกลงนี้
    ข้อตกลงนี้กำหนดสิทธิ์และภาระผูกพันของบริการซอฟต์แวร์ "Tradease Robot" (ต่อไปนี้จะเรียกว่า "บริการ") ระหว่าง Tradease Robot และผู้ใช้ "ผู้ใช้" หมายถึงบุคคลที่ลงทะเบียน เข้าสู่ระบบ และใช้บริการนี้ ข้อตกลงนี้สามารถอัปเดตโดยหุ่นยนต์ Tradease ได้ตลอดเวลา
    เงื่อนไขข้อตกลงที่ปรับปรุงใหม่จะแทนที่เงื่อนไขข้อตกลงเดิมทันทีที่มีการประกาศ โดยไม่ต้องแจ้งให้ทราบล่วงหน้า ผู้ใช้สามารถตรวจสอบเงื่อนไขข้อตกลงเวอร์ชันล่าสุดได้บนเว็บไซต์ หลังจากที่หุ่นยนต์ Tradease แก้ไขข้อกำหนดของข้อตกลงแล้ว หากผู้ใช้ไม่ยอมรับข้อกำหนดที่แก้ไข โปรดหยุดใช้บริการของหุ่นยนต์ Tradease ทันที หากผู้ใช้ยังคงใช้บริการของหุ่นยนต์ Tradease ต่อไป จะถือว่ายอมรับ ข้อตกลงที่แก้ไข
</p>
    <p>ข้อตกลงนี้กำหนดสิทธิ์และภาระผูกพันของบริการซอฟต์แวร์ "Tradease Robot" (ต่อไปนี้จะเรียกว่า "บริการ") ระหว่าง Tradease Robot และผู้ใช้ "ผู้ใช้" หมายถึงบุคคลที่ลงทะเบียน เข้าสู่ระบบ และใช้บริการนี้ ข้อตกลงนี้สามารถอัปเดตโดยหุ่นยนต์ Tradease ได้ตลอดเวลา
     เงื่อนไขข้อตกลงที่ปรับปรุงใหม่จะแทนที่เงื่อนไขข้อตกลงเดิมทันทีที่มีการประกาศ โดยไม่ต้องแจ้งให้ทราบล่วงหน้า ผู้ใช้สามารถตรวจสอบเงื่อนไขข้อตกลงเวอร์ชันล่าสุดได้ในเว็บไซต์นี้ หลังจากที่หุ่นยนต์ Tradease แก้ไขข้อกำหนดของข้อตกลงแล้ว หากผู้ใช้ไม่ยอมรับข้อกำหนดที่แก้ไข โปรดหยุดใช้บริการของหุ่นยนต์ Tradease ทันที หากผู้ใช้ยังคงใช้บริการของหุ่นยนต์ Tradease ต่อไป จะถือว่ายอมรับ ข้อตกลงที่แก้ไข</p>
    <h3>1. การลงทะเบียนบัญชี</h3>
    <p>1. ผู้ใช้ต้องลงทะเบียนบัญชี "Tradease Robot" ก่อนใช้บริการนี้ บัญชี "Tradease Robot" ควรลงทะเบียนด้วยการผูกหมายเลขโทรศัพท์มือถือ ผู้ใช้จะต้องลงทะเบียนบัญชี "Tradease Robot" ด้วยหมายเลขโทรศัพท์มือถือที่ไม่ได้ผูกกับบัญชี "Tradease Robot" และหมายเลขโทรศัพท์มือถือ ที่ไม่ได้ถูกบล็อกโดย Tradease Robot ตามข้อตกลงนี้ หุ่นยนต์ Tradease สามารถเปลี่ยนวิธีการลงทะเบียนบัญชีและผูกมัดตามความต้องการของผู้ใช้หรือความต้องการของผลิตภัณฑ์โดยไม่ต้องแจ้งให้ผู้ใช้ทราบล่วงหน้า</p>
    <p>2. "Tradease Robot" เป็นผลิตภัณฑ์โซเชียลบนมือถือที่อนุญาตตำแหน่งทางภูมิศาสตร์ของ Tradease Robot และข้อมูลส่วนบุคคลที่เกี่ยวข้องทั้งหมด ดังนั้น เมื่อผู้ใช้เสร็จสิ้นการลงทะเบียน ผู้ใช้ยินยอมให้หุ่นยนต์ Tradease ดึงข้อมูล เปิดเผย และใช้ข้อมูลตำแหน่งทางภูมิศาสตร์ของผู้ใช้</p>
    <p>3. ในมุมมองของวิธีการลงทะเบียนที่มีผลผูกพันของบัญชี "Tradease Robot" คุณยอมรับว่า Tradease Robot จะใช้หมายเลขโทรศัพท์มือถือที่คุณให้ไว้และ/หรือดึงหมายเลขโทรศัพท์มือถือของคุณโดยอัตโนมัติและดึงรหัสประจำตัวอุปกรณ์มือถือของคุณโดยอัตโนมัติเมื่อทำการลงทะเบียน ลงทะเบียน</p>
    <p>4. เมื่อผู้ใช้ลงทะเบียนและใช้บริการนี้ หุ่นยนต์ Tradease จำเป็นต้องรวบรวมข้อมูลส่วนบุคคลที่สามารถระบุผู้ใช้เพื่อให้หุ่นยนต์ Tradease สามารถติดต่อผู้ใช้เมื่อจำเป็น หรือมอบประสบการณ์ที่ดีขึ้นแก่ผู้ใช้ ข้อมูลที่รวบรวมโดยหุ่นยนต์ Tradease รวมถึงแต่ไม่จำกัดเพียงชื่อผู้ใช้ เพศ อายุ วันเดือนปีเกิด หมายเลข ID ที่อยู่ สถานการณ์ในโรงเรียน สถานการณ์ของบริษัท อุตสาหกรรม งานอดิเรก สถานที่บ่อย และคำอธิบายส่วนบุคคล หุ่นยนต์ Tradease ตกลงที่จะ การใช้ข้อมูลนี้จะอยู่ภายใต้ข้อจำกัดของการคุ้มครองข้อมูลส่วนบุคคลของผู้ใช้รายที่สาม</p>
    <h3>2. เนื้อหาการบริการ</h3>
    <p>เนื้อหาเฉพาะของบริการนี้จัดทำโดยหุ่นยนต์ Tradease ตามสถานการณ์จริง ซึ่งรวมถึงแต่ไม่จำกัดเพียงผู้ใช้ที่ได้รับอนุญาตให้สื่อสารแบบเรียลไทม์ผ่านบัญชีของตน เพิ่มเพื่อน เข้าร่วมกลุ่ม ติดตามผู้อื่น และโพสต์ข้อความ หุ่นยนต์ Tradease สามารถเปลี่ยนบริการที่พวกเขาให้ และเนื้อหาบริการของหุ่นยนต์ Tradease อาจเปลี่ยนแปลงได้ตลอดเวลา ผู้ใช้จะได้รับการแจ้งเตือนจากหุ่นยนต์ Tradease เกี่ยวกับการเปลี่ยนแปลงบริการ</p>
    <h3>3. การปกป้องข้อมูลส่วนบุคคลของผู้ใช้</h3>
    <p>1. ในกระบวนการลงทะเบียนบัญชีหรือใช้บริการนี้ ผู้ใช้บริการอาจจำเป็นต้องกรอกหรือส่งข้อมูลที่จำเป็นบางอย่าง เช่น ข้อมูลระบุตัวตนที่กฎหมายกำหนด เอกสารกำกับดูแล (ต่อไปนี้จะเรียกว่า "กฎหมายและระเบียบข้อบังคับ" ). หากข้อมูลที่ผู้ใช้ส่งมาไม่ครบถ้วนหรือไม่เป็นไปตามข้อกำหนดของกฎหมายและระเบียบข้อบังคับ ผู้ใช้บริการอาจไม่สามารถใช้บริการหรือถูกจำกัดกระบวนการใช้บริการได้</p>
    <p>2. ข้อมูลความเป็นส่วนตัวส่วนบุคคล หมายถึง ข้อมูลที่เกี่ยวข้องกับอัตลักษณ์ส่วนบุคคลของผู้ใช้หรือความเป็นส่วนตัว เช่น ชื่อจริงของผู้ใช้ หมายเลขประจำตัวประชาชน หมายเลขโทรศัพท์มือถือ รหัสประจำตัวอุปกรณ์เคลื่อนที่ ที่อยู่ IP และประวัติการแชทของผู้ใช้ ข้อมูลความเป็นส่วนตัวที่ไม่ใช่ข้อมูลส่วนบุคคลหมายถึงข้อมูลบันทึกพื้นฐานของสถานะการทำงานของผู้ใช้และพฤติกรรมการใช้งานของบริการ ซึ่งสะท้อนให้เห็นอย่างชัดเจนและเป็นกลางบนเซิร์ฟเวอร์หุ่นยนต์ Tradease
ข้อมูลทั่วไปอื่น ๆ นอกขอบเขตของข้อมูลส่วนบุคคลและข้อมูลส่วนบุคคลที่กล่าวถึงข้างต้นที่ผู้ใช้ตกลงที่จะเปิดเผย</p>
    <p>3. การเคารพในความเป็นส่วนตัวของข้อมูลส่วนบุคคลของผู้ใช้เป็นระบบที่สอดคล้องกันของ Tradease Robots Tradease Robots จะใช้มาตรการทางเทคนิคและมาตรการที่จำเป็นอื่น ๆ เพื่อความปลอดภัยของข้อมูลส่วนบุคคลของผู้ใช้และป้องกันการรั่วไหลและความเสียหายของข้อมูลส่วนบุคคลของผู้ใช้ ข้อมูลส่วนบุคคลที่เก็บรวบรวมในบริการนี้ หรือสูญหาย เมื่อสถานการณ์ดังกล่าวเกิดขึ้นหรือหุ่นยนต์ Tradease พบความเป็นไปได้ของสถานการณ์ดังกล่าว จะใช้มาตรการแก้ไขทันท่วงที</p>
    <p>Tradease Robot ไม่เปิดเผยหรือเปิดเผยข้อมูลส่วนบุคคลของผู้ใช้กับบุคคลที่สามโดยไม่ได้รับความยินยอมจากผู้ใช้ ยกเว้นในสถานการณ์เฉพาะดังต่อไปนี้:
      <br></br>(1)หุ่นยนต์ Tradease ให้ข้อมูลความเป็นส่วนตัวของผู้ใช้ตามกฎหมายและระเบียบข้อบังคับหรือคำแนะนำจากหน่วยงานผู้มีอำนาจ
      <br></br>(2)การรั่วไหลของข้อมูลส่วนบุคคลที่เกิดจากผู้ใช้บอกรหัสผ่านผู้ใช้แก่ผู้อื่นหรือแบ่งปันบัญชีและรหัสผ่านที่ลงทะเบียนกับผู้อื่น หรือข้อมูลความเป็นส่วนตัวอื่น ๆ ที่ไม่ได้เกิดจากหุ่นยนต์ Tradease
      <br></br>(3)ผู้ใช้เปิดเผยข้อมูลส่วนบุคคลของตนต่อบุคคลที่สามด้วยตนเอง
      <br></br>(4)ผู้ใช้และหุ่นยนต์ Tradease และหน่วยสหกรณ์ได้บรรลุข้อตกลงในการใช้และเปิดเผยข้อมูลส่วนตัวของผู้ใช้ ดังนั้น หุ่นยนต์ Tradease จะเปิดเผยข้อมูลส่วนตัวของผู้ใช้ให้กับหน่วยสหกรณ์
      <br></br>(5)การรั่วไหลของข้อมูลส่วนบุคคลของผู้ใช้เนื่องจากการโจมตีของแฮ็กเกอร์ การบุกรุกของไวรัสคอมพิวเตอร์ และเหตุสุดวิสัยอื่นๆ
      <br></br>(6)ในกระบวนการใช้งาน ผู้ใช้อย่างแข็งขันแพร่กระจายที่เป็นอันตรายทางการเมือง ลามกอนาจารและภาพลามกอนาจาร โฆษณา ความรุนแรงที่นองเลือด การใส่ร้ายและการดูหมิ่น และเปิดเผยข้อมูลส่วนบุคคลส่วนบุคคล
    </p>
    <p>5. ผู้ใช้ตกลงให้หุ่นยนต์ Tradease สามารถใช้ข้อมูลส่วนบุคคลของผู้ใช้ในเรื่องต่อไปนี้
      <br></br>(1)หุ่นยนต์ Tradease ส่งการแจ้งเตือนที่สำคัญไปยังผู้ใช้ในเวลาที่เหมาะสม เช่น การอัปเดตซอฟต์แวร์และการเปลี่ยนแปลงข้อกำหนดของข้อตกลงนี้
      <br></br>(2) Tradease Robot ดำเนินการตรวจสอบภายใน วิเคราะห์ข้อมูลและวิจัย ฯลฯ เพื่อปรับปรุงผลิตภัณฑ์ บริการ และการสื่อสารกับผู้ใช้ของ Tradease Robot
      <br></br>(3)ตามข้อตกลงนี้ หุ่นยนต์ Tradease จะจัดการ ตรวจสอบข้อมูลผู้ใช้ และดำเนินมาตรการการประมวลผล
      <br></br>(4)เรื่องอื่นๆ ที่กำหนดโดยกฎหมายและข้อบังคับที่บังคับใช้
      <br></br>ยกเว้นเรื่องข้างต้น หุ่นยนต์ Tradease จะไม่ใช้ข้อมูลส่วนบุคคลของผู้ใช้เพื่อวัตถุประสงค์อื่นใดโดยไม่ได้รับความยินยอมจากผู้ใช้ก่อน
    </p>
    <p>6. หุ่นยนต์ Tradease ให้ความสำคัญอย่างยิ่งกับการปกป้องข้อมูลส่วนบุคคลของผู้เยาว์
    หุ่นยนต์ Tradease จะใช้ข้อมูลส่วนบุคคลที่ผู้ใช้ให้มาเพื่อพิจารณาว่าผู้ใช้เป็นผู้เยาว์หรือไม่
     ผู้เยาว์ที่มีอายุต่ำกว่า 18 ปีซึ่งลงทะเบียนสำหรับบัญชีหรือใช้บริการนี้ควรได้รับความยินยอมเป็นลายลักษณ์อักษรล่วงหน้าจากพ่อแม่หรือผู้ปกครองตามกฎหมาย (ต่อไปนี้จะเรียกว่า "ผู้ปกครอง")
     นอกจากการเปิดเผยตามบทบัญญัติของกฎหมายและระเบียบข้อบังคับและคำสั่งของเจ้าหน้าที่ผู้มีอำนาจแล้ว
     หุ่นยนต์ Tradease จะไม่ใช้หรือเปิดเผยประวัติการแชทและข้อมูลส่วนบุคคลอื่น ๆ ของผู้เยาว์แก่บุคคลที่สาม ยกเว้นข้อยกเว้นที่กำหนดไว้ในข้อตกลงนี้
       หากไม่ได้รับความยินยอมล่วงหน้าจากผู้ปกครอง หุ่นยนต์ Tradease จะไม่ใช้หรือเปิดเผยข้อมูลส่วนบุคคลของผู้เยาว์แก่บุคคลที่สาม</p>
    <p>7. ผู้ใช้ยืนยันว่าข้อมูลตำแหน่งทางภูมิศาสตร์ของเขาเป็นข้อมูลที่ไม่ใช่ข้อมูลส่วนบุคคล และผู้ใช้ได้ลงทะเบียนบัญชีหุ่นยนต์ Tradease เรียบร้อยแล้วเพื่อยืนยันการอนุญาตของหุ่นยนต์ Tradease
แยก เปิดเผย และใช้ข้อมูลตำแหน่งทางภูมิศาสตร์ของผู้ใช้ ข้อมูลตำแหน่งทางภูมิศาสตร์ของผู้ใช้จะเป็นหนึ่งในข้อมูลสาธารณะของผู้ใช้
       หุ่นยนต์ Tradease เปิดเผยต่อผู้ใช้รายอื่น หากผู้ใช้ต้องการยุติการเปิดเผยข้อมูลตำแหน่งทางภูมิศาสตร์ของตนแก่ผู้ใช้รายอื่น เขาสามารถตั้งค่าให้ซ่อนได้ตลอดเวลา</p>
    <p>8. เพื่อปรับปรุงเทคโนโลยีและบริการของหุ่นยนต์ Tradease ให้ผู้ใช้มีประสบการณ์การบริการที่ดีขึ้น
       หุ่นยนต์ Tradease อาจรวบรวมและใช้ข้อมูลความเป็นส่วนตัวที่ไม่ใช่ข้อมูลส่วนบุคคลของผู้ใช้หรือจัดหาข้อมูลดังกล่าวให้กับบุคคลที่สาม</p>
    <h3>ประการที่สี่ ข้อกำหนดเนื้อหา</h3>
    <p>1. เนื้อหาที่กล่าวถึงในบทความนี้หมายถึงเนื้อหาใดๆ ที่ผลิต อัปโหลด คัดลอก ตีพิมพ์ หรือเผยแพร่ในระหว่างการใช้บริการนี้ของผู้ใช้
     รวมถึงแต่ไม่จำกัดเฉพาะรูปประจำตัวของบัญชี ชื่อ คำอธิบายผู้ใช้ และข้อมูลการลงทะเบียนอื่น ๆ และข้อมูลการรับรองความถูกต้อง หรือข้อความ เสียง รูปภาพ วิดีโอ กราฟิก และการส่งอื่นๆ
       ตอบกลับหรือตอบกลับข้อความและหน้าที่เชื่อมโยงโดยอัตโนมัติ รวมถึงเนื้อหาอื่น ๆ ที่สร้างขึ้นโดยใช้บัญชีหรือบริการ</p>
    <p>2. ผู้ใช้ไม่ได้รับอนุญาตให้ใช้บัญชีของ Tradease Robot หรือบริการนี้ในการผลิต อัปโหลด คัดลอก เผยแพร่ หรือเผยแพร่เนื้อหาต่อไปนี้ซึ่งห้ามโดยกฎหมาย ข้อบังคับ และนโยบาย:
      <br></br>(1)คัดค้านหลักการพื้นฐานที่รัฐธรรมนูญกำหนดขึ้น
      <br></br>(2) คุกคามความมั่นคงของชาติ เปิดเผยความลับของรัฐ บ่อนทำลายอำนาจรัฐ และบ่อนทำลายความสามัคคีของชาติ
      <br></br>(3) ความเสียหายต่อเกียรติยศและผลประโยชน์ของชาติ
      <br></br>(4) ยุยงให้เกิดความเกลียดชังทางชาติพันธุ์ การเลือกปฏิบัติทางชาติพันธุ์ และบ่อนทำลายความสามัคคีทางชาติพันธุ์
      <br></br>(5) บ่อนทำลายนโยบายทางศาสนาของรัฐ ส่งเสริมลัทธิและไสยศาสตร์ศักดินา
      <br></br>(6) กระจายข่าวลือ ทำลายระเบียบสังคม และบ่อนทำลายความมั่นคงทางสังคม
      <br></br>(7) เผยแพร่ความลามกอนาจาร ภาพลามกอนาจาร การพนัน ความรุนแรง การฆาตกรรม การก่อการร้ายหรือยุยงให้เกิดอาชญากรรม
      <br></br>(8) ดูหมิ่นหรือใส่ร้ายผู้อื่น และละเมิดสิทธิและผลประโยชน์ที่ชอบด้วยกฎหมายของผู้อื่น
      <br></br>(9)ความล้มเหลวในการปฏิบัติตาม "เจ็ดบรรทัดล่าง" ของบรรทัดล่างของกฎหมายและข้อบังคับ บรรทัดล่างของระบบสังคมนิยม บรรทัดล่างของผลประโยชน์ของชาติ บรรทัดล่างของสิทธิและผลประโยชน์อันชอบธรรมของพลเมือง บรรทัดล่างของสังคม ความสงบเรียบร้อยของประชาชน บรรทัดล่างของศีลธรรม และบรรทัดล่างสุดของความถูกต้องของข้อมูล
      <br></br>(10) ข้อมูลที่มีเนื้อหาอื่นๆ ต้องห้ามตามกฎหมายและระเบียบการบริหาร
    </p>
    <p>3. ผู้ใช้ไม่ได้รับอนุญาตให้ใช้บัญชีของหุ่นยนต์ Tradease หรือบริการนี้เพื่อสร้าง อัปโหลด คัดลอก เผยแพร่
     เผยแพร่เนื้อหาต่อไปนี้ซึ่งขัดขวางการทำงานปกติของหุ่นยนต์ Tradease และละเมิดสิทธิ์และผลประโยชน์ที่ถูกต้องตามกฎหมายของผู้ใช้รายอื่นหรือบุคคลที่สาม:
      <br></br>(1) มีข้อเสนอแนะทางเพศหรือเรื่องเพศ
      <br></br>(2)มีเนื้อหาที่ดูหมิ่น ข่มขู่ หรือข่มขู่
      <br></br>(3) มีการล่วงละเมิด สแปม ข้อมูลที่เป็นอันตราย หรือข้อมูลหลอกลวง
      <br></br>(4) เกี่ยวข้องกับความเป็นส่วนตัว ข้อมูลส่วนบุคคล หรือข้อมูลของผู้อื่น
      <br></br>(5) ละเมิดสิทธิ์ทางกฎหมายของผู้อื่น เช่น สิทธิ์ในชื่อเสียง สิทธิ์ในภาพเหมือน สิทธิ์ในทรัพย์สินทางปัญญา และความลับทางการค้า
      <br></br>(6) 含有其他干扰本服务正常运营和侵犯其他用户或第三方合法权益内容的信息。

    </p>
    <h3>ห้า ใช้กฎ</h3>
    <p>1. เนื้อหาใด ๆ ที่ส่งหรือเผยแพร่โดยผู้ใช้ในหรือผ่านบริการนี้ไม่ได้สะท้อนหรือเป็นตัวแทน
       จะไม่ถือว่าเป็นการสะท้อนหรือแสดงถึงมุมมอง ตำแหน่ง หรือนโยบายของ Tradease Robot และ Tradease Robot จะไม่รับผิดชอบต่อสิ่งนี้</p>
    <p>2. ผู้ใช้ไม่ได้รับอนุญาตให้ใช้บัญชีของหุ่นยนต์ Tradease หรือบริการนี้เพื่อทำสิ่งต่อไปนี้:
      <br></br>(1) ส่งหรือเผยแพร่ข้อมูลเท็จ หรือการปลอมแปลงรูปประจำตัวหรือเอกสารของผู้อื่น การแอบอ้างหรือใช้ชื่อของผู้อื่น
      <br></br>(2) บังคับหรือชักจูงให้ผู้ใช้รายอื่นติดตาม คลิกบนเพจที่เชื่อมโยง หรือแบ่งปันข้อมูล
      <br></br>(3) ข้อเท็จจริงที่สมมติขึ้น ปิดบังความจริงเพื่อหลอกลวงหรือหลอกลวงผู้อื่น
      <br></br>(4) ใช้วิธีการทางเทคนิคเพื่อสร้างบัญชีปลอมเป็นชุด
      <br></br>(5) ใช้บัญชี Tradease Robot" หรือบริการนี้เพื่อมีส่วนร่วมในกิจกรรมที่ผิดกฎหมายหรือทางอาญา
      <br></br>(6) ผลิตและเผยแพร่วิธีการและเครื่องมือที่เกี่ยวข้องกับพฤติกรรมข้างต้น หรือดำเนินการหรือเผยแพร่วิธีการและเครื่องมือดังกล่าว
       ไม่ว่าการกระทำเหล่านี้จะมีวัตถุประสงค์เพื่อการค้าหรือไม่
      <br></br>(7) การละเมิดกฎหมายและข้อบังคับอื่น ๆ การละเมิดสิทธิ์และผลประโยชน์ที่ถูกต้องตามกฎหมายของผู้ใช้รายอื่น
       รบกวนการทำงานปกติของ "Tradease Robot" หรือพฤติกรรมที่ Shenzhen Yuejie Innovation Technology Co., Ltd. ไม่ได้อนุญาตไว้โดยชัดแจ้ง
    </p>
    <p>3. ผู้ใช้ต้องตระหนักถึงความถูกต้อง ความถูกต้องตามกฎหมาย ความไม่เป็นอันตราย ความถูกต้อง และความถูกต้องของข้อมูลที่ส่งโดยใช้บัญชีของหุ่นยนต์ Tradease หรือบริการนี้
     เราเป็นผู้รับผิดชอบแต่เพียงผู้เดียวสำหรับความถูกต้อง ฯลฯ ความรับผิดชอบทางกฎหมายใด ๆ ที่เกี่ยวข้องกับข้อมูลที่เผยแพร่โดยผู้ใช้จะเป็นภาระของผู้ใช้ และไม่มีส่วนเกี่ยวข้องกับหุ่นยนต์ Tradease
       หากเกิดความเสียหายกับหุ่นยนต์ Tradease หรือบุคคลที่สาม ผู้ใช้จะต้องชดใช้ตามกฎหมาย</p>
    <p>4. บริการที่หุ่นยนต์ Tradease จัดหาให้อาจรวมถึงโฆษณา และผู้ใช้ตกลงที่จะแสดงหุ่นยนต์ Tradease และซัพพลายเออร์บุคคลที่สาม
     โฆษณาโดยพันธมิตร เว้นแต่จะระบุไว้อย่างชัดเจนโดยกฎหมายและข้อบังคับ ผู้ใช้จะต้องรับผิดชอบการทำธุรกรรมตามข้อมูลโฆษณา
       Tradease Robot จะไม่รับผิดชอบต่อความสูญเสียหรือความเสียหายใด ๆ ที่ผู้ใช้ได้รับเนื่องจากธุรกรรมที่ดำเนินการบนพื้นฐานของข้อมูลโฆษณาหรือเนื้อหาที่ผู้โฆษณาดังกล่าวให้ไว้</p>
    <h3>หก การจัดการบัญชี</h3>
    <p>1. ความเป็นเจ้าของบัญชีของ Tradease Robot เป็นของ Tradease Robot
     หลังจากที่ผู้ใช้เสร็จสิ้นขั้นตอนการสมัครและการลงทะเบียน เขาได้รับสิทธิ์ในการใช้บัญชีของหุ่นยนต์ Tradease
     สิทธิ์ในการใช้งานเป็นของผู้ยื่นคำร้องครั้งแรกเท่านั้น และห้ามมิให้บริจาค ยืม ให้เช่า โอน หรือขาย หุ่นยนต์ Tradease เนื่องจากความต้องการทางธุรกิจ
       มีสิทธิ์เรียกคืนบัญชีหุ่นยนต์ Tradease ของผู้ใช้</p>
    <p>2. ผู้ใช้สามารถเปลี่ยนแปลงหรือลบข้อมูลส่วนบุคคลในบัญชีของหุ่นยนต์ Tradease
       ลงทะเบียนข้อมูลและเนื้อหาการส่ง แต่โปรดทราบว่าการลบข้อมูลที่เกี่ยวข้องจะลบข้อความและรูปภาพที่จัดเก็บโดยผู้ใช้ในระบบด้วย และผู้ใช้จะต้องรับความเสี่ยง</p>
    <p>3. ผู้ใช้ต้องรับผิดชอบต่อความปลอดภัยของข้อมูลบัญชีที่ลงทะเบียนและรหัสผ่านบัญชี
     ผู้ใช้จะต้องรับผิดชอบต่อการขโมยบัญชีหรือการขโมยรหัสผ่านอันเนื่องมาจากการจัดเก็บที่ไม่เหมาะสมโดยผู้ใช้
     ผู้ใช้จะต้องรับผิดชอบตามกฎหมายสำหรับพฤติกรรมภายใต้บัญชีและรหัสผ่านที่ลงทะเบียนไว้ ผู้ใช้ตกลงที่จะไม่ใช้บัญชีหรือรหัสผ่านของผู้ใช้รายอื่นไม่ว่าในกรณีใด
       . เมื่อผู้ใช้สงสัยว่าบุคคลอื่นใช้บัญชีหรือรหัสผ่านของตน ผู้ใช้ตกลงที่จะแจ้งให้หุ่นยนต์ Tradease ทราบทันที</p>
    <p>4. ผู้ใช้บริการต้องปฏิบัติตามเงื่อนไขของข้อตกลงนี้และใช้บริการอย่างถูกต้องและเหมาะสม หากผู้ใช้ฝ่าฝืนเงื่อนไขใด ๆ ในข้อตกลงนี้
     หลังจากแจ้งผู้ใช้แล้ว Tradease Robot มีสิทธิ์ระงับหรือยุติการให้บริการบัญชี "Tradease Robot" ของการละเมิดผู้ใช้ตามข้อตกลง
       ในเวลาเดียวกัน Tradease Robot ขอสงวนสิทธิ์ในการถอนบัญชีและชื่อผู้ใช้ของ Tradease Robot ได้ตลอดเวลา</p>
    <p>5. หากผู้ใช้ไม่เข้าสู่ระบบในหนึ่งปีหลังจากลงทะเบียนบัญชี "หุ่นยนต์ Tradease" หุ่นยนต์ Tradease สามารถถอนบัญชีหลังจากแจ้งให้ผู้ใช้ทราบ
       เพื่อหลีกเลี่ยงการสูญเสียทรัพยากร ผู้ใช้ต้องรับผิดชอบผลเสียที่เกิดจากสิ่งนี้</p>
    <h3>เซเว่น การจัดเก็บข้อมูล</h3>

    <p>1. หุ่นยนต์ Tradease จะไม่รับผิดชอบต่อความล้มเหลวของผู้ใช้ในการลบหรือจัดเก็บข้อมูลที่เกี่ยวข้องในบริการนี้</p>
    <p>2. หุ่นยนต์ Tradease สามารถกำหนดระยะเวลาการจัดเก็บข้อมูลสูงสุดของผู้ใช้ในบริการนี้ตามสถานการณ์จริง
       และจัดสรรพื้นที่จัดเก็บข้อมูลสูงสุดบนเซิร์ฟเวอร์ ผู้ใช้สามารถสำรองข้อมูลที่เกี่ยวข้องในบริการนี้ตามความต้องการของตนเอง</p>
    <p>3. หากผู้ใช้หยุดใช้บริการหรือบริการถูกยกเลิก หุ่นยนต์ Tradease สามารถลบข้อมูลของผู้ใช้ออกจากเซิร์ฟเวอร์ได้อย่างถาวร
       หลังจากบริการหยุดหรือยุติ หุ่นยนต์ Tradease ไม่จำเป็นต้องส่งคืนข้อมูลใด ๆ ให้กับผู้ใช้</p>
    <h3>8. สมมติฐานความเสี่ยง</h3>
    <p>1. ผู้ใช้เข้าใจและยอมรับว่า "หุ่นยนต์ Tradease" ให้แพลตฟอร์มสำหรับผู้ใช้ในการแบ่งปัน ส่งและรับข้อมูลเท่านั้น
     ผู้ใช้จะต้องรับผิดชอบต่อการกระทำทั้งหมดภายใต้บัญชีที่ลงทะเบียนของเขา รวมถึงเนื้อหาใด ๆ ที่ส่งโดยผู้ใช้และผลที่ตามมาใด ๆ ที่เกิดขึ้นจากมัน
     ผู้ใช้ควรใช้วิจารณญาณของตนเองเกี่ยวกับ "Tradease Robot" และเนื้อหาของบริการนี้ และรับความเสี่ยงทั้งหมดที่เกิดขึ้นจากการใช้เนื้อหา
     รวมถึงความเสี่ยงจากการพึ่งพาความถูกต้อง ความครบถ้วน หรือประโยชน์ของเนื้อหา
     Tradease Robot ไม่สามารถและจะไม่รับผิดชอบต่อความสูญเสียหรือความเสียหายที่เกิดจากการกระทำของผู้ใช้
     หากผู้ใช้พบว่ามีผู้ใดละเมิดข้อตกลงนี้หรือใช้บริการในลักษณะที่ไม่เหมาะสมอื่น
       โปรดรายงานหรือร้องเรียนต่อหุ่นยนต์ Tradease ทันที และหุ่นยนต์ Tradease จะจัดการตามข้อตกลง</p>
    <p>2. ผู้ใช้เข้าใจและยอมรับว่าเนื่องจากความต้องการในการพัฒนาธุรกิจ Tradease Robot ขอสงวนสิทธิ์การเปลี่ยนแปลงเพียงฝ่ายเดียวในเนื้อหาบริการทั้งหมดหรือบางส่วน
       ผู้ใช้จะต้องรับความเสี่ยงจากสิทธิ์ในการระงับ ยุติ หรือเพิกถอน</p>
    <h3>9. คำชี้แจงทรัพย์สินทางปัญญา</h3>
    <p>1. นอกจากสิทธิ์ในทรัพย์สินทางปัญญาที่เกี่ยวข้องกับโฆษณาในบริการนี้แล้ว ผู้โฆษณาที่เกี่ยวข้องยังชื่นชอบเนื้อหาที่จัดทำโดย (Tradease Robot) ในบริการนี้
     สิทธิ์ในทรัพย์สินทางปัญญา (รวมถึงแต่ไม่จำกัดเพียงหน้าเว็บ ข้อความ รูปภาพ เสียง วิดีโอ กราฟิก ฯลฯ) เป็นของ (Tradease Robot)
       อย่างไรก็ตาม ผู้ใช้ได้รับสิทธิ์ในทรัพย์สินทางปัญญาอย่างถูกกฎหมายสำหรับเนื้อหาที่เผยแพร่ก่อนที่จะใช้บริการนี้</p>
    <p>2. เว้นแต่จะระบุไว้เป็นอย่างอื่น (Tradease Robot) ให้บริการนี้พร้อมลิขสิทธิ์ของซอฟต์แวร์
       สิทธิ์ในสิทธิบัตรและสิทธิ์ในทรัพย์สินทางปัญญาอื่น ๆ เป็นของ (Tradease Robot)</p>
    <p>3, (หุ่นยนต์ Tradease) ข้อความหรือองค์ประกอบที่เกี่ยวข้องกับบริการนี้
     และโลโก้อื่นๆ (หุ่นยนต์ Tradease) และชื่อผลิตภัณฑ์และบริการ (ต่อไปนี้จะเรียกรวมกันว่า "โลโก้ (หุ่นยนต์ Tradease)")
     , ลิขสิทธิ์หรือเครื่องหมายการค้าเป็นของ (Tradease Robot) หากไม่มี (หุ่นยนต์ Tradease) ได้รับความยินยอมเป็นลายลักษณ์อักษรล่วงหน้า
     ผู้ใช้จะต้องไม่แสดงหรือใช้โลโก้ (หุ่นยนต์ Tradease) ในทางใดทางหนึ่งหรือดำเนินการอื่น ๆ
       และไม่ควรแสดงให้ผู้อื่นทราบว่าผู้ใช้มีสิทธิ์แสดง ใช้ หรือมีสิทธิ์ในการประมวลผลโลโก้ (หุ่นยนต์ Tradease)</p>
    <p>4. ด้านบนและอื่น ๆ (หุ่นยนต์ Tradease) หรือสิทธิ์ในทรัพย์สินทางปัญญาของผู้ลงโฆษณาที่เกี่ยวข้องซึ่งเป็นเจ้าของตามกฎหมายได้รับการคุ้มครองตามกฎหมาย
       หากไม่ได้รับอนุญาตเป็นลายลักษณ์อักษรจาก (Tradease Robot) หรือผู้โฆษณาที่เกี่ยวข้อง ผู้ใช้จะไม่ได้รับอนุญาตให้ใช้หรือสร้างงานลอกเลียนแบบที่เกี่ยวข้องในทุกรูปแบบ</p>
    <h3>X. ความรับผิดทางกฎหมาย</h3>

    <p>1. หากหุ่นยนต์ Tradease พบหรือรับรายงานหรือข้อร้องเรียนจากผู้อื่นว่าผู้ใช้ละเมิดข้อกำหนดของข้อตกลงนี้
     Tradease Robot มีสิทธิ์ตรวจสอบและลบเนื้อหาที่เกี่ยวข้อง ซึ่งรวมถึงแต่ไม่จำกัดเพียงข้อมูลผู้ใช้และบันทึกการแชท เมื่อใดก็ได้โดยไม่ต้องแจ้งให้ทราบ
       ทั้งนี้ขึ้นอยู่กับความรุนแรงของสถานการณ์ บทลงโทษรวมถึงแต่ไม่จำกัดเพียงคำเตือน การแบนบัญชี การแบนอุปกรณ์ และการแบนฟังก์ชันจะถูกกำหนดในบัญชีที่ละเมิด และผู้ใช้จะได้รับแจ้งผลการดำเนินการ</p>
    <p>2. ผู้ใช้ที่ถูกแบนเนื่องจากละเมิดข้อตกลงผู้ใช้สามารถติดต่อฝ่ายบริการลูกค้าหุ่นยนต์ Tradease เพื่อสอบถามเกี่ยวกับระยะเวลาการแบน
     และหลังจากหมดเวลาแบนก็จะปลดบล็อกเอง ในหมู่พวกเขา ผู้ใช้ที่ถูกแบนโดยฟังก์ชันจะคืนค่าฟังก์ชันที่ถูกแบนโดยอัตโนมัติหลังจากหมดระยะเวลาการแบน
       ผู้ใช้ที่ถูกบล็อกสามารถส่งคำอุทธรณ์ไปยังฝ่ายบริการลูกค้าหุ่นยนต์ Tradease ได้ และหุ่นยนต์ Tradease จะตรวจสอบคำอุทธรณ์และตัดสินตามสมควรด้วยตนเองเพื่อตัดสินใจว่าจะเปลี่ยนมาตรการลงโทษหรือไม่</p>
    <p>3. ผู้ใช้เข้าใจและยอมรับว่า Tradease Robot มีสิทธิ์ลงโทษการละเมิดกฎหมายและข้อบังคับที่เกี่ยวข้องหรือข้อกำหนดของข้อตกลงนี้ตามวิจารณญาณที่สมเหตุสมผล
     ดำเนินการทางกฎหมายอย่างเหมาะสมกับผู้ใช้ที่ละเมิดกฎหมายและระเบียบข้อบังคับ และบันทึกข้อมูลที่เกี่ยวข้องตามกฎหมายและระเบียบข้อบังคับเพื่อรายงานไปยังหน่วยงานที่เกี่ยวข้อง ฯลฯ
       ผู้ใช้จะต้องรับผิดชอบทางกฎหมายทั้งหมดที่เกิดขึ้น</p>
    <p>4. ผู้ใช้เข้าใจและตกลงว่าการเรียกร้อง ความต้องการ หรือความสูญเสียใดๆ ที่อ้างสิทธิ์โดยบุคคลที่สามซึ่งเกิดขึ้นหรือเกิดจากการที่ผู้ใช้ละเมิดข้อตกลงนี้
       รวมถึงค่าทนายความที่สมเหตุสมผล ผู้ใช้ควรชดเชยหุ่นยนต์ Tradease และบริษัทร่วมมือและบริษัทในเครือ และปกป้องพวกเขาจากความเสียหาย</p>
    <h3>11. เหตุสุดวิสัยและการยกเว้นอื่นๆ</h3>

    <p>1. ผู้ใช้เข้าใจและยืนยันว่าในกระบวนการใช้บริการนี้ อาจพบปัจจัยเสี่ยง เช่น เหตุสุดวิสัย
     ทำให้บริการนี้หยุดชะงัก เหตุสุดวิสัยหมายถึงเหตุการณ์วัตถุประสงค์ที่ไม่สามารถคาดการณ์ได้ ไม่สามารถเอาชนะ และหลีกเลี่ยงไม่ได้ และมีผลกระทบอย่างมีนัยสำคัญต่อฝ่ายใดฝ่ายหนึ่งหรือทั้งสองฝ่าย
     รวมถึงแต่ไม่จำกัดเฉพาะภัยธรรมชาติ เช่น น้ำท่วม แผ่นดินไหว โรคระบาดและพายุ และกิจกรรมทางสังคม เช่น สงคราม ความวุ่นวาย การดำเนินการของรัฐบาล เป็นต้น เมื่อเกิดสถานการณ์ข้างต้นขึ้น
     หุ่นยนต์ Tradease จะทำงานอย่างหนักเพื่อให้ความร่วมมือกับหน่วยงานที่เกี่ยวข้องในครั้งแรกเพื่อซ่อมแซมทันเวลา แต่ความสูญเสียที่เกิดขึ้นกับผู้ใช้หรือบุคคลที่สามจะเกิดจากสิ่งนี้
       หุ่นยนต์ Tradease และหน่วยสหกรณ์ได้รับการยกเว้นจากความรับผิดภายในขอบเขตที่กฎหมายอนุญาต</p>
    <p>2. บริการนี้เหมือนกับบริการอินเทอร์เน็ตส่วนใหญ่และขึ้นอยู่กับปัจจัยต่างๆ รวมถึงแต่ไม่จำกัดเพียงเหตุผลของผู้ใช้ คุณภาพบริการเครือข่าย
     ความแตกต่างของสภาพแวดล้อมทางสังคมและปัจจัยอื่นๆ อาจได้รับผลกระทบจากปัญหาด้านความปลอดภัยต่างๆ เช่น การใช้ข้อมูลผู้ใช้โดยผู้อื่น
     ก่อกวนในชีวิตจริง ซอฟต์แวร์อื่น ๆ ที่ดาวน์โหลดและติดตั้งโดยผู้ใช้หรือเว็บไซต์อื่น ๆ ที่เข้าชมมีไวรัสเช่น "ม้าโทรจัน"
     คุกคามความปลอดภัยของข้อมูลคอมพิวเตอร์และข้อมูลของผู้ใช้ จากนั้นส่งผลต่อการใช้บริการนี้ตามปกติ ฯลฯ ผู้ใช้ควรเสริมสร้างความตระหนักในความปลอดภัยของข้อมูลและการปกป้องข้อมูลของผู้ใช้
       ให้ความสำคัญกับการเสริมสร้างการป้องกันด้วยรหัสผ่านเพื่อหลีกเลี่ยงการสูญเสียและการล่วงละเมิด</p>
    <p>3. ผู้ใช้เข้าใจและยืนยันว่าบริการมีอยู่เนื่องจากเหตุสุดวิสัย ไวรัสคอมพิวเตอร์หรือการโจมตีของแฮ็กเกอร์ ความไม่เสถียรของระบบ ตำแหน่งของผู้ใช้
     ความเสี่ยงของการหยุดชะงักของบริการหรือความล้มเหลวในการตอบสนองความต้องการของผู้ใช้ที่เกิดจากการปิดระบบของผู้ใช้และเทคโนโลยีอื่น ๆ อินเทอร์เน็ต เหตุผลของสายการสื่อสาร ฯลฯ
       Tradease Robot จะไม่รับผิดชอบต่อการสูญเสียผู้ใช้หรือบุคคลที่สามที่เกิดจากสิ่งนี้</p>
    <p>4.ผู้ใช้เข้าใจและยืนยันว่ามีการหลอกลวง ทำให้เข้าใจผิด หรือ
     ข้อมูลหลอกลวง ข่มขู่ ทำให้เสื่อมเสียชื่อเสียง ล่วงละเมิดหรือผิดกฎหมาย หรือข้อมูลที่ไม่ระบุตัวตนหรือเป็นเท็จที่ละเมิดสิทธิ์ของผู้อื่น
       เช่นเดียวกับการกระทำที่มาพร้อมกับข้อมูลดังกล่าว Tradease Robot จะไม่รับผิดชอบต่อความสูญเสียใด ๆ ต่อผู้ใช้หรือบุคคลที่สามที่เกิดขึ้น</p>
    <p>5.ผู้ใช้เข้าใจและยืนยันว่าหุ่นยนต์ Tradease จำเป็นต้องยกเครื่องหรือบำรุงรักษาแพลตฟอร์ม "หุ่นยนต์ Tradease" หรืออุปกรณ์ที่เกี่ยวข้องเป็นประจำหรือไม่สม่ำเสมอ
       หากบริการถูกขัดจังหวะภายในเวลาที่เหมาะสมเนื่องจากสถานการณ์ดังกล่าว หุ่นยนต์ Tradease ไม่จำเป็นต้องรับผิดชอบใดๆ สำหรับสิ่งนี้ และหุ่นยนต์ Tradease ควรแจ้งล่วงหน้า</p>
    <p>6.หุ่นยนต์ Tradease ได้รับสิทธิ์ในการจัดการกับเนื้อหาที่ผิดกฎหมายหรือการละเมิดสัญญาตามกฎหมายและระเบียบข้อบังคับและข้อตกลง
       สิทธิ์นี้ไม่เป็นภาระผูกพันหรือข้อผูกมัดของ Tradease Robot และ Tradease Robot ไม่สามารถรับประกันได้ว่าจะมีการตรวจพบการละเมิดกฎหมายและระเบียบข้อบังคับหรือการละเมิดสัญญาในเวลาที่เหมาะสมหรือจะได้รับการจัดการตามนั้น</p>
    <p>7. ผู้ใช้เข้าใจและยืนยันว่าข้อบกพร่องด้านคุณภาพของผลิตภัณฑ์หรือบริการต่อไปนี้ที่หุ่นยนต์ Tradease มอบให้กับผู้ใช้และความสูญเสียที่เกิดจากสิ่งเหล่านี้คือ:
       หุ่นยนต์ Tradease ไม่จำเป็นต้องรับผิดชอบใด ๆ :(1) YISHU机器人向用户免费提供的服务；</p>
    <p>8. ไม่ว่าในกรณีใดๆ หุ่นยนต์ของ Tradease จะไม่ก่อให้เกิดความเสียหายทางอ้อม เป็นผลสืบเนื่อง การลงโทษ โดยอุบัติเหตุ พิเศษหรือทางอาญา
     รวมถึงการสูญเสียผลกำไรของผู้ใช้ที่เกิดจากการใช้หุ่นยนต์ Tradease" หรือบริการนี้
     รับผิดชอบ (แม้ว่าหุ่นยนต์ Tradease จะได้รับแจ้งถึงความเป็นไปได้ของการสูญเสียดังกล่าว) แม้ว่าข้อตกลงนี้อาจมีบทบัญญัติที่ขัดแย้ง
     ความรับผิดชอบทั้งหมดของหุ่นยนต์ Tradease ต่อผู้ใช้ โดยไม่คำนึงถึงเหตุผลหรือพฤติกรรม จะไม่เกินกว่าที่ผู้ใช้ใช้หุ่นยนต์ Tradease
ให้บริการและชำระเงินให้กับหุ่นยนต์ Tradease (ถ้ามี)</p>
    <h3>12. การเปลี่ยนแปลง การหยุดชะงัก และการยกเลิกบริการ</h3>
    <p>1. เนื่องจากความเฉพาะเจาะจงของบริการเครือข่าย ผู้ใช้ตกลงว่าหุ่นยนต์ Tradease มีสิทธิ์เปลี่ยนแปลง
     ยุติหรือยุติบริการบางส่วนหรือทั้งหมด (รวมถึงบริการที่คิดค่าธรรมเนียม) หุ่นยนต์ Tradease เปลี่ยนไป
     สำหรับบริการที่ถูกขัดจังหวะหรือถูกยกเลิก Tradease Robot จะแจ้งให้ผู้ใช้ทราบก่อนการเปลี่ยนแปลง การหยุดชะงัก หรือการยกเลิก
       และควรให้บริการทางเลือกที่เทียบเท่ากับผู้ใช้ที่ได้รับผลกระทบ</p>
    <p>2. ในกรณีที่เกิดสถานการณ์ใด ๆ ต่อไปนี้ Tradease Robot มีสิทธิ์ในการเปลี่ยนแปลง ระงับ หรือยุติบริการฟรีที่มอบให้แก่ผู้ใช้
     โดยไม่รับผิดชอบต่อผู้ใช้หรือบุคคลที่สามใดๆ:
    (1) ตามกฎหมาย ผู้ใช้ควรส่งข้อมูลจริง และข้อมูลส่วนบุคคลที่ผู้ใช้ให้มาไม่เป็นความจริง
     หรือไม่สอดคล้องกับข้อมูลในขณะที่ลงทะเบียนและไม่สามารถให้หลักฐานที่เหมาะสม (2) ผู้ใช้ละเมิดกฎหมายและระเบียบที่เกี่ยวข้องหรือข้อกำหนดของข้อตกลงนี้;
     (3) ตามกฎหมายหรือข้อกำหนดของผู้มีอำนาจ (4) ด้วยเหตุผลด้านความปลอดภัยหรือพฤติการณ์อื่นที่จำเป็น
    </p>
    <h3>13. อื่นๆ</h3>

    <p>1. หุ่นยนต์ Tradease เตือนผู้ใช้อย่างจริงจังให้ใส่ใจกับข้อกำหนดในข้อตกลงนี้ซึ่งยกเว้นความรับผิดชอบและจำกัดสิทธิ์ของผู้ใช้
       โปรดอ่านอย่างระมัดระวังและพิจารณาความเสี่ยงอย่างอิสระ ผู้เยาว์ควรอ่านข้อตกลงนี้พร้อมกับผู้ปกครองตามกฎหมาย</p>
    <p>2. ความถูกต้อง การตีความ และการระงับข้อพิพาทของข้อตกลงนี้มีผลบังคับใช้กับกฎหมายของสาธารณรัฐประชาชนจีน
     หากมีข้อพิพาทหรือข้อพิพาทใด ๆ ระหว่างผู้ใช้กับหุ่นยนต์ Tradease ควรยุติการเจรจาอย่างเป็นมิตรก่อน หากการเจรจาล้มเหลว
       ผู้ใช้ตกลงที่จะยื่นข้อพิพาทหรือข้อพิพาทต่อศาล</p>
    <p>3. ไม่ว่าข้อใด ๆ ของข้อตกลงนี้จะไม่ถูกต้องหรือไม่สามารถบังคับใช้ได้ด้วยเหตุผลใดก็ตาม ประโยคที่เหลือยังคงมีผลใช้บังคับและมีผลผูกพันทั้งสองฝ่าย</p> 
    <p  class='redtext'>
   การลงทุนต้องระวัง มีความเสี่ยงในการเข้าสู่ตลาด!<br>
    กลยุทธ์ของซอฟต์แวร์นี้และการใช้ฟังก์ชันต่างๆ เช่น มิเรอร์ในซอฟต์แวร์มีไว้เพื่อการอ้างอิงเท่านั้น ความหลากหลายในการลงทุนและผลิตภัณฑ์ไม่ถือเป็นคำแนะนำในการลงทุน ผู้ใช้เองเป็นผู้รับผิดชอบความสูญเสียที่เกิดจากมิเรอร์และฟังก์ชันอื่น ๆ อีกครั้ง</p>
    `,
  },
  EXCEPTION: {},
  // 策略相关
  CELUE: {
    // 新增排序
    ORDERBY_START: "กดเริ่มเวลา",
    ORDERBY_INVEST: "ตามปริมาณการลงทุน",
    ORDERBY_DEFAULT: "เริ่มต้นการเรียงลำดับ",
    ORDERBY: "โหมดการสั่งซื้อ",
    // 策略页面
    MDCL: "Martin",
    WGCL: "กลยุทธ์กริด",
    DTCL: "การลงทุนคงที่",
    HYCL: "กลยุทธ์สัญญา",
    GBZ: "ปิด",
    CZCG: "การดำเนินงานที่ประสบความสำเร็จ",
    QBDAPI: "กรุณาผูก API",
    CJMDJQR: "สร้างหุ่นยนต์มาร์ติน",
    MDCLJRZYL: "กำไรวันนี้ (USDT)",
    MDLSZYL: "ผลกำไรในอดีต:",
    WDJQR: "หุ่นยนต์ของฉัน",
    QB: "ทั้งหมด",
    LSJQR: "ประวัติศาสตร์",
    ZWJRR: "ไม่มีหุ่นยนต์",
    YGB: "ปิด",
    YCZ: "รีเซ็ต",
    YXSC: "เวลาทำงาน:",
    CJ: "สร้าง",
    TZE: "จำนวนเงินลงทุน",
    ZLR: "กำไรทั้งหมด",
    MDLR: "กำไรมาร์ติน",
    CCZE: "ตำแหน่งทั้งหมด",
    CCL: "เปิดดอกเบี้ย",
    CCDS: "จำนวนคำสั่งถือครอง",
    CCJJ: "ราคาโพซิชั่นเฉลี่ย",
    DQJG: "ราคาปัจจุบัน",
    QDJQR: "เริ่มหุ่นยนต์",
    ZTJQR: "หยุดหุ่นยนต์ชั่วคราว",
    KQMR: "เปิดซื้อ",
    ZTMR: "ระงับการซื้อ",
    JQRXQ: "รายละเอียดหุ่นยนต์",
    JXJQRBKCZ: "หุ่นยนต์กระจกไม่สามารถใช้งานได้ is",
    ZWBDJYSAPI: "API การแลกเปลี่ยนยังไม่ถูกผูกไว้",
    LJBD: "ผูกตอนนี้",
    CJWGJRQ: "สร้างหุ่นยนต์กริด",
    WGCLJRZYL: "กำไรวันนี้ (USDT)",
    WGLSZYL: "ผลกำไรในอดีต：",
    WGLR: "กำไรกริด Grid",
    WGZDJ: "กริดราคาต่ำสุด",
    WGZGJ: "กริดราคาสูงสุด",
    CJDTJQR: "สร้างหุ่นยนต์การลงทุนคงที่",
    DTCLJRZYL: "กำไรวันนี้ (USDT)",
    DTLSZYL: "ผลกำไรในอดีต:",
    DTLR: "กำไรจากการลงทุนคงที่",
    MRJ: "ราคาซื้อ",
    ZYBL: "อัตราส่วนกำไร",
    ZSBL: "อัตราส่วนการหยุดขาดทุน",
    TZCF: "หยุดพูดซ้ำ",
    CFZX: "ทำซ้ำการดำเนินการ",
    GBJQR: "ปิดหุ่นยนต์",
    GBJQRHWFCQ:
      "หลังจากปิดหุ่นยนต์แล้ว จะไม่สามารถรีสตาร์ทได้ โปรดยืนยันว่าคุณจำเป็นต้องขายหุ่นยนต์ให้กับ USDT โดยอัตโนมัติหรือไม่",
    QBWASCJMC: "ช่วยขายราคาตลาดหน่อย",
    BYLWZJM: "ไม่ต้อง ผมขายเอง",
    GBJQRWFCQ:
      "หลังจากปิดหุ่นยนต์แล้ว จะไม่สามารถรีสตาร์ทได้ โปรดยืนยันเพื่อปิด?",
    QX: "ยกเลิก",
    OK: "ยืนยัน",
    XZCFJQRH:
      "หลังจากเลือกหยุดการดำเนินการซ้ำแล้วซ้ำอีกหุ่นยนต์จะหยุดดำเนินการตามกลยุทธ์ต่อไปหลังจากทำกำไรในครั้งนี้ คุณแน่ใจหรือไม่ว่าจะหยุดการดำเนินการซ้ำ",
    XZTZJQRH:
      "หลังจากเลือกการดำเนินการซ้ำแล้วซ้ำอีก หุ่นยนต์จะดำเนินการตามกลยุทธ์ต่อไปที่ราคาซื้อเป้าหมายหลังจาก take-profit นี้ คุณแน่ใจหรือไม่ว่าจะดำเนินการซ้ำ",
    ZTJQRH:
      "หลังจากระงับหุ่นยนต์ ระบบจะไม่ซื้อหรือขาย คุณแน่ใจหรือไม่ว่าต้องการระงับหุ่นยนต์?",
    QDJQRH:
      "หลังจากสตาร์ทหุ่นยนต์แล้ว ระบบจะทำการซื้อและขายต่อ คุณแน่ใจหรือไม่ว่าต้องการสตาร์ทหุ่นยนต์",
    KQMRH:
      "หลังจากเปิดการซื้อ ระบบจะซื้อและขายโดยอัตโนมัติ คุณแน่ใจหรือไม่ว่าต้องการเปิดการซื้อ?",
    ZTMRH:
      "หลังจากระงับการซื้อแล้ว ระบบจะไม่ซื้ออัตโนมัติแต่ไม่มีผลกับการขาย คุณแน่ใจหรือว่าต้องการระงับการซื้อ?",

    // api授权
    APISQ: "การอนุญาต",
    QSRKEY: "โปรดป้อน API KEY และ API SECRET ที่สร้างจากการแลกเปลี่ยน",
    CHAPIJC: "สร้างบทช่วยสอน API ",
    OK: "ยืนยัน",
    APITS:
      "กลยุทธ์สามารถเริ่มต้นได้หลังจากที่การให้สิทธิ์ API เสร็จสิ้น โปรดไปที่การแลกเปลี่ยนที่เกี่ยวข้องเพื่อสร้าง API และดำเนินการให้สิทธิ์การผูกข้อมูลในหน้านี้",
    QSQ: "ในการอนุญาต",
    // 选择币种
    XZBZ: "เลือกสกุลเงิน",
    SSBZ: "ค้นหาสกุลเงิน",
    ZX: "ไม่จำเป็น",
    QB: "ทั้งหมด",
    ZWZX: "ไม่มีทางเลือก",
    // 历史机器人
    MD: "มาร์ติน",
    WG: "กริด",
    DT: "การลงทุนคงที่",
    HY: "สัญญา",
    //เลือกการแลกเปลี่ยน
    XZJYS: "เลือกการแลกเปลี่ยน",
    XZJYSSM: "เลือกหนึ่งในการแลกเปลี่ยนที่ได้รับอนุญาตเพื่อเริ่มกลยุทธ์",
    JYS: "แลกเปลี่ยน",
  },
  // 创建机器人
  CHUANGJIAN: {
    // 定投
    ZYJBDXYXJ: "ราคาทำกำไรจะต้องไม่ต่ำกว่าราคาปัจจุบัน",
    ZXTZ: "การลงทุนขั้นต่ำ",
    ZYBLBDXY: "อัตราส่วนการทำกำไรต้องไม่น้อยกว่า",
    ZTZEBDXY: "เงินลงทุนทั้งหมดต้องไม่น้อยกว่า",
    XHCZBLZX: "อัตราส่วนการรีเซ็ตรอบขั้นต่ำคือ 0.5%",
    CJDTJQR: "สร้างหุ่นยนต์การลงทุนคงที่",
    SZ: "ติดตั้ง",
    YSJMR: "ซื้อที่ราคาตลาด",
    YXJMR: "ซื้อในราคาจำกัด",
    MRJG: "ราคาซื้อ",
    ZSMJGMR: "ราคาเท่าไหร่",
    ZYJ: "ขายทำกำไร",
    JGDDDSSMC: "ขายเมื่อราคาถึง",
    ZYBL: "อัตราส่วนกำไร",
    ZDYLDSSMC: "ขายเมื่อกำไรขั้นต่ำคือ",
    ZHU: "หมายเหตุ: ห้ามขายโดยตรงหลังจากถึงอัตราส่วนการทำกำไรแล้ว ขายเมื่อโทรกลับ",
    ZTZE: "รวมการลงทุน",
    ZXTZ: "การลงทุนขั้นต่ำ",
    KYZJ: "เงินทุนที่มีอยู่:",
    QRCS: "ยืนยันพารามิเตอร์",
    ZSBL: "อัตราส่วนการหยุดขาดทุน",
    SR0BZDZS: "ป้อน 0 เพื่อไม่มีการหยุดการสูญเสียอัตโนมัติ automatic",
    HTMRBL: "อัตราส่วนการซื้อโทรกลับ",
    JGDDZDDDSSMR: "ซื้อเมื่อราคาถึงจุดต่ำสุด",
    HTMCBL: "อัตราส่วนการโทรกลับเพื่อขาย",
    JGDDZGDDSSMC: "ขายเมื่อราคาถึงจุดสูงสุด",
    XHCZBL: "อัตราส่วนการรีเซ็ตวงจร",
    XHSMRJGXTBL: "อัตราส่วนราคาซื้อระหว่างรอบ",
    FQ: "ยอมแพ้",
    QRSZ: "ยืนยันการตั้งค่า",
    // 马丁
    ZXTZEW: "การลงทุนขั้นต่ำคือ",
    ZDSLQJW: "ช่วงปริมาณการสั่งซื้อคือ",
    BCBLQJ: "ช่วงอัตราส่วนความครอบคลุม",
    BCZFBLQJW: "ช่วงของอัตราส่วนการเพิ่มของการโทรคือ",
    ZYBLQJW: "ช่วงอัตราส่วนการทำกำไรคือ",
    BCBSQJW: "ช่วงเวลาของการเรียกหลักประกันหลายเท่าคือ",
    CJMDJQR: "สร้างหุ่นยนต์มาร์ติน",
    GQQRNH: "เป็นรายปีในช่วงเจ็ดวันที่ผ่านมา",
    AICL: "กลยุทธ์ AI",
    DCZDTXCS: "คลิกที่นี่เพื่อกรอกพารามิเตอร์โดยอัตโนมัติ",
    SYAICL: "ใช้กลยุทธ์ AI",
    SDSZ: "การตั้งค่าด้วยตนเอง",
    ZDSL: "ปริมาณการสั่งซื้อ",
    ZDXXBCCS: "จำนวนดาวน์เซลล์สูงสุด",
    BCBL: "อัตราส่วนการโทร",
    MCBCDBL: "เปอร์เซ็นต์ของการโทรแต่ละครั้ง",
    BCZFBL: "เพิ่มอัตราส่วนของ Margin Call",
    ZBCBLSDZF: "เพิ่มเปอร์เซ็นต์การโทร percentage",
    ZYBL: "อัตราส่วนกำไร",
    YJMCSDYLBL: "อัตรากำไรโดยประมาณ ณ เวลาขาย",
    MCZF: "เพิ่มขึ้นแต่ละครั้ง",
    BCBS: "มาร์จิ้นทวีคูณ",
    // 网格
    WGS: "จำนวนกริด",
    QJJGBHF: "ราคาช่วงเป็นสิ่งผิดกฎหมาย",
    MGZXLRW: "กำไรขั้นต่ำต่อตารางคือ",
    CJWWGJQR: "สร้างหุ่นยนต์กริด",
    NRW: "คุณคิดว่า",
    ZDJ: "ราคาต่ำสุด",
    ZGJ: "ราคาสูงสุด",
    QJSXJG: "ราคาช่วงบนสุด",
    QJXXJG: "ราคาขีดจำกัดล่าง",
    WGSL: "จำนวนกริด",
    MGLR: "กำไรต่อแผนก:",
    JGFW: "ช่วงราคา",
    HD: "เลื่อนหลุด",
    // ---启动
    XDZ: "สั่งซื้อเลย",
    CZDTJQR: "สร้างหุ่นยนต์การลงทุนคงที่",
    DQJG: "ราคาปัจจุบัน",
    SJMRJSM:
      "ราคาซื้อจริงขึ้นอยู่กับราคาซื้อขายเฉลี่ยที่ส่งคืนโดยการแลกเปลี่ยน",
    ZDSHCJ: "หลังราคาขึ้น",
    MCJG: "ราคาขาย",
    YJZTZE: "ประมาณการเงินลงทุนทั้งหมด",
    KYYE: "ยอดเงินคงเหลือ",
    QDJQR: "เริ่มหุ่นยนต์",
    CJMDJQR: "สร้างหุ่นยนต์มาร์ติน",
    JG: "ราคา",
    TZE: "จำนวนเงินลงทุน",
    SL: "ปริมาณ",
    CJWGJQR: "สร้างหุ่นยนต์กริด",
    WGQJ: "ช่วงเวลากริด",
  },
  // 机器人详情
  XIANGQING: {
    GDXQ: "รายละเอียด",
    JYJL: "การบันทึก",
    JQRCS: "พารามิเตอร์",
    DTJQRXQ: "รายละเอียด",
    DQJG: "ราคาปัจจุบัน",
    BUY: "ซื้อใน",
    SELL: "ขาย",
    CCSL: "",
    CCJJ: "ราคาโพซิชั่นเฉลี่ย",
    JQRZT: "สถานะหุ่นยนต์",
    DDMR: "รอซื้อ",
    JHMRJ: "ราคาซื้อที่วางแผนไว้",
    ZDMRJ: "ราคาซื้อขั้นต่ำ",
    JQRZT: "สถานะหุ่นยนต์",
    DDMC: "รอขายครับ",
    SJMRJ: "ราคาซื้อจริง",
    MBZYJ: "ราคาทำกำไรเป้าหมาย",
    ZGMCJ: "ราคาขายสูงสุด",
    ZSJ: "ราคาหยุด",
    DQJGGYMR:
      "ซื้อเมื่อราคาปัจจุบันสูงกว่าราคาซื้อขั้นต่ำและต่ำกว่าราคาซื้อที่วางแผนไว้",
    DQJGDYMC:
      "ขายเมื่อ take profit เมื่อราคาปัจจุบันสูงกว่าราคา take profit เป้าหมายและต่ำกว่าราคาขายสูงสุด ขายที่ stop loss เมื่อราคาปัจจุบันต่ำกว่าราคา stop loss",
    JQRYBTZ: "หุ่นยนต์ถูกหยุด",
    JXJQRBKCK: "ไม่สามารถดูหุ่นยนต์กระจกได้",
    XXTLCS: "24H Arbitrage Times",
    ZTLCS: "การเก็งกำไรทั้งหมด",
    RJCS: "จำนวนครั้งโดยเฉลี่ยต่อวัน",
    TLJL: "บันทึกการเก็งกำไร",
    DTJQRWCYCMRMC:
      "หุ่นยนต์การลงทุนคงที่ถือเป็นการเก็งกำไรหลังจากเสร็จสิ้นการซื้อและการขายที่เกี่ยวข้อง",
    MDJQRWCYCMRMC:
      "หุ่นยนต์ Martin ถือเป็นการเก็งกำไรหลังจากเสร็จสิ้นการซื้อและการขายที่เกี่ยวข้อง",
    BDJQRWCYCMRMC:
      "หุ่นยนต์ BD ถือเป็นการเก็งกำไรหลังจากเสร็จสิ้นการซื้อและการขายที่เกี่ยวข้อง",
    WGJQRWCYCMRMC:
      "หลังจากที่หุ่นยนต์กริดเสร็จสิ้นการซื้อและการขายที่เกี่ยวข้อง จะถือเป็นการเก็งกำไร",
    HYJQRWCYCMRMC:
      "หลังจากที่หุ่นยนต์ทำสัญญาเสร็จสิ้นการซื้อและการขายที่เกี่ยวข้อง จะถือเป็นการเก็งกำไร",

    ZLR: "กำไรทั้งหมด",
    CJJJ: "ราคาซื้อขายเฉลี่ย",
    CJL: "ปริมาณ",
    CJE: "มูลค่าการซื้อขาย",
    DTCLCS: "พารามิเตอร์กลยุทธ์การลงทุนคงที่",
    MDCLCS: "พารามิเตอร์กลยุทธ์มาร์ติน",
    WGCLCS: "พารามิเตอร์กลยุทธ์กริด",
    JYBD: "เรื่อง",
    ZTZE: "รวมการลงทุน",
    ZYBL: "อัตราส่วนกำไร",
    JHMRJG: "ราคาซื้อที่วางแผนไว้",
    HTMRBL: "ราคาซื้อที่วางแผน",
    HTMCBL: "อัตราส่วนการโทรกลับเพื่อขาย",
    ZSBL: "อัตราส่วนการหยุดขาดทุน",
    XHCZBL: "อัตราส่วนการรีเซ็ตวงจร",
    //
    MDJQRXQ: "รายละเอียด",
    JG: "ราคา",
    TZE: "จำนวนเงินลงทุน",
    SL: "ปริมาณ",
    YMR: "ซื้อแล้ว",
    DHT: "ที่จะถูกเรียกกลับ",
    SDTZE: "การลงทุนครั้งแรก",
    JHZD: "วางแผนการสั่งซื้อ",
    BCBL: "อัตราส่วนการโทร",
    BCZF: "มาร์จิ้นเพิ่มขึ้น",
    BCBS: "มาร์จิ้นทวีคูณ",
    //
    WGJQRXQ: "รายละเอียด",
    MRJG: "ราคาซื้อ",
    ZDSHCJ: "หลังราคาขึ้น",
    MCJG: "ราคาขาย",
    JCSMRSL: "จำนวนที่ซื้อเมื่อเปิดตำแหน่ง",
    JCSMRJJ: "ราคาซื้อเฉลี่ยเมื่อเปิดสถานะ",
    QJSXJG: "ราคาเพดานช่วง",
    QJXXJG: "ราคาขีดจำกัดล่าง",
    WGSL: "จำนวนกริด",
    MGLR: "กำไรต่อดิวิชั่น",
    HD: "เลื่อนหลุด",
    MGMMSL: "จำนวนธุรกรรมต่อกริด",
  },
  ABOUTUS: {
    GYWM: "เกี่ยวกับเรา",
    SM: `
        <p>Tradease Quantitative Robot เป็นซอฟต์แวร์กลยุทธ์การซื้อขายของบุคคลที่สามในแพลตฟอร์มการซื้อขายสกุลเงินดิจิทัลที่เปิดตัวโดย Tradease Technology ช่วยให้ผู้ใช้ทำการเก็งกำไรการซื้อขายโดยอัตโนมัติผ่านกลยุทธ์เชิงปริมาณที่กำหนดค่าได้อย่างอิสระ</p >
        <p>แพลตฟอร์มนี้จะยังคงคิดค้นและพัฒนาเครื่องมือเชิงปริมาณที่สามารถให้บริการผู้ใช้ได้อย่างต่อเนื่อง ซึ่งรวมถึงแต่ไม่จำกัดเพียงฟังก์ชันที่หลากหลาย เช่น กลยุทธ์การซื้อขายแบบกริด/กลยุทธ์การซื้อขายมาร์ติน/กลยุทธ์การซื้อขายการลงทุนแบบคงที่ เพื่อให้ผู้ใช้ทำกำไรได้อย่างง่ายดายโดยไม่ต้องจ้องมอง ที่ตลาด.</p >
        <p>ขอบคุณสำหรับความไว้วางใจ หากคุณมีความคิดเห็นหรือข้อเสนอแนะใด ๆ โปรดส่งไปยังฝ่ายบริการลูกค้าผ่านระบบสั่งงานหรือติดต่อเราทาง WeChat และ Weibo</p >
        <p></p >
        <p></p >
        `,
  },
};
