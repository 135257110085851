import http from "./http";

class RobotApi {
  /**
   * 关闭合约机器人
   **/
  static Hystop(options = {}) {
    return http.post("/robothy/stop", options);
  }
  /**
   * 合约交易详情
   **/
  static hyOrder(options = {}) {
    return http.post("/robothy/order", options);
  }
  /**
   * 合约交易详情
   **/
  static setzhiyingzhisun(options = {}) {
    return http.post("/robothy/setzhiyingzhisun", options);
  }

  /**
   * 合约挂单详情和机器人参数
   **/
  static hyView(options = {}) {
    return http.post("/robothy/view", options);
  }
  /**
   * 创建合约机器人
   * @required open_id
   * @required platform
   * @required symbol
   **/
  static hyAdd(options = {}) {
    return http.post("/robothy/add", options);
  }
  /**
   * 获取合约价格
   * @required open_id
   * @required platform
   * @required symbol
   **/
  static hyTickers(options = {}) {
    return http.post("/robothymc/tickers", options);
  }

  /**
   * 获取合约平台USDT余额

   * @required open_id
   * @required platform
 
   **/
  static hyBalance(options = {}) {
    return http.post("/robothy/balance", options);
  }
  /**
   * 添加或删除交易对

   * @required open_id
   * @required platform
   * @required symbol
   * @required type 新增：add，删除：del
 
   **/
  static addMySymbol(options = {}) {
    return http.post("/robotini/addmysymbol", options);
  }
  /**
   * 获取自选交易对

   * @required open_id
   * @required platform

   **/
  static mySymbol(options = {}) {
    return http.post("/robotini/mysymbol", options);
  }
  // ---------镜像------
  /**
   * 操作日志
   * @required user_id
   * @required page
   * @required row

   **/
  static logList(options = {}) {
    return http.post("/mirror/loglist", options);
  }
  /**
   * 镜像者的策略
   * @required open_id
   * @required platform
   * @required type
   * @required page
   * @required row
   **/
  static robotList(options = {}) {
    return http.post("/mirror/robotlist", options);
  }
  /**
   * 镜像详情
   * @required open_id
   * @required id

   **/
  static mirrorView(options = {}) {
    return http.post("/mirror/mirrorview", options);
  }

  /**
   * 取消跟随镜像
   * @required mirror_id
   * @required pay_password

   **/
  static cancel(options = {}) {
    return http.post("/mirror/cancel", options);
  }
  /**
   * 跟随镜像
   * @required mirror_id
   * @required pay_password

   **/
  static addMirror(options = {}) {
    return http.post("/mirror/addmirror", options);
  }

  /**
   * 镜像列表
   * @required page
   * @required row

   **/
  static mirrorList(options = {}) {
    return http.post("/mirror/mirrorlist", options);
  }
  /**
   * 跟随镜像列表
   * @required page
   * @required row

   **/
  static followList(options = {}) {
    return http.post("/mirror/followlist", options);
  }
  /**
   * 保存镜像篇配置
   * @required open_id
   * @required platform

   **/
  static mirrorConfig(options = {}) {
    return http.post("/mirror/mirrorconfig", options);
  }
  /**
   * 获取镜像篇配置
   * @required open_id
   * @required platform

   **/
  static getMirrorConfig(options = {}) {
    return http.post("/mirror/getmirrorconfig", options);
  }

  // -----------------
  /**
   * 平仓
   * @required symbol

   **/
  static pingCang(options = {}) {
    return http.post("/robotini/pingcang", options);
  }
  /**
   * 面板
   * @required symbol

   **/
  static assetView(options = {}) {
    return http.post("/robotini/assetview", options);
  }
  /**
   * 马丁跟单详情
   * @required symbol

   **/
  static MdGentou(options = {}) {
    return http.post("/robotmd/gentou", options);
  }
  /**
   * 定投跟单详情
   * @required symbol

   **/
  static DtGentou(options = {}) {
    return http.post("/robotdt/gentou", options);
  }
  /**
   * 网格跟单详情
   * @required symbol

   **/
  static WgGentou(options = {}) {
    return http.post("/robotwg/gentou", options);
  }
  /**
   * 定投跟单列表
   * @required symbol

   **/
  static DtGentoulist(options = {}) {
    return http.post("/robotdt/gentoulist", options);
  }
  /**
   * 网格跟单列表
   * @required symbol

   **/
  static WgGentoulist(options = {}) {
    return http.post("/robotwg/gentoulist", options);
  }

  /**
   * 马丁跟单列表
   * @required symbol

   **/
  static MdGentoulist(options = {}) {
    return http.post("/robotmd/gentoulist", options);
  }
  /**
   * 获取初始化配置
   * @required symbol

   **/
  static Iniconfig(options = {}) {
    return http.post("/robotini/getconfig", options);
  }
  /** 创建满仓策略初始化 */
  static IniconfigMcAdd(options = {}) {
    // if (options.platform === "模拟") {
    //   options.platform = "BINANCE";
    // }
    // if (options.platform) {
    //   options.platform = `${options.platform}合约`;
    // }
    return http.post("/robothymc/addini", options);
  }

  /**
   * 创建马丁机器人ai
   * @required symbol

   **/
  static Mdai(options = {}) {
    return http.post("/robotmd/ai", options);
  }
  /**
   * 创建网格机器人ai
   * @required symbol

   **/
  static Wgai(options = {}) {
    return http.post("/robotwg/ai", options);
  }
  /**
   * 关闭网格机器人
   * @required open_id
   * @required id
   * @required sell

   **/
  static Wgstop(options = {}) {
    return http.post("/robotwg/stop", options);
  }

  /**
   * 网格交易详情
   **/
  static wgOrder(options = {}) {
    return http.post("/robotwg/order", options);
  }
  /**
   * 网格挂单详情和机器人参数
   **/
  static wgView(options = {}) {
    return http.post("/robotwg/view", options);
  }
  /**
   * 暂停买入网格机器人
   **/
  static wgPause(options = {}) {
    return http.post("/robotwg/pause", options);
  }
  /**
   * 暂停网格机器人
   **/
  static wgStopbuy(options = {}) {
    return http.post("/robotwg/stopbuy", options);
  }
  /**
   * 网格机器人列表
   **/
  static wgList(options = {}) {
    return http.post("/robotwg/list", options);
  }
  /**
   * 创建网格机器人
   * @required open_id
   **/
  static WgAdd(options = {}) {
    return http.post("/robotwg/add ", options);
  }
  /**
   * 关闭马丁机器人
   * @required open_id
   * @required id
   * @required sell

   **/
  static Mdstop(options = {}) {
    return http.post("/robotmd/stop", options);
  }

  /**
   * 马丁交易详情
   **/
  static mdOrder(options = {}) {
    return http.post("/robotmd/order", options);
  }
  /**
   * 马丁挂单详情和机器人参数
   **/
  static mdView(options = {}) {
    return http.post("/robotmd/view", options);
  }
  /**
   * 暂停买入马丁机器人
   **/
  static mdPause(options = {}) {
    return http.post("/robotmd/pause", options);
  }
  /**
   * 暂停马丁机器人
   **/
  static mdStopbuy(options = {}) {
    return http.post("/robotmd/stopbuy", options);
  }
  /**
   * 马丁机器人列表
   **/
  static mdList(options = {}) {
    return http.post("/robotmd/list", options);
  }
  /**
   * 创建马丁机器人
   * @required open_id
   **/
  static MdAdd(options = {}) {
    return http.post("/robotmd/add ", options);
  }
  // ------------------------------布丁start------------
  /**
   * 布丁跟单列表
   **/
  static BdGentoulist(options = {}) {
    return http.post("/robotbd/gentoulist", options);
  }
  static BdHyGentoulist(options = {}) {
    return http.post("/robothymc/gentoulist", options);
  }
  /**
   * 布丁跟单详情
   **/
  static BdGentou(options = {}) {
    return http.post("/robotbd/gentou", options);
  }
  static BdHyGentou(options = {}) {
    return http.post("/robothyfc/gentou", options);
  }
  /**
   * 布丁下轨间隔列表
   * @required symbol

   **/
  static getTimeLimit(options = {}) {
    return http.post("/robotbd/gettimelimit", options);
  }
  /**
   * 创建布丁机器人ai
   * @required symbol

   **/
  static Bdai(options = {}) {
    return http.post("/robotbd/ai", options);
  }
  /**
   * 关闭布丁机器人
   * @required open_id
   * @required id
   * @required sell

   **/
  static Bdstop(options = {}) {
    return http.post("/robotbd/stop", options);
  }
  static BdHystop(options = {}) {
    return http.post("/robothyfc/stop", options);
  }

  /**
   * 布丁交易详情
   **/
  static bdOrder(options = {}) {
    return http.post("/robotbd/order", options);
  }
  static bdHyOrder(options = {}) {
    return http.post("/robothyfc/order", options);
  }
  /**
   * 布丁挂单详情和机器人参数
   **/
  static bdView(options = {}) {
    return http.post("/robotbd/view", options);
  }
  static bdHyView(options = {}) {
    return http.post("/robothyfc/view", options);
  }
  /**
   * 暂停买入布丁机器人
   **/
  static bdPause(options = {}) {
    return http.post("/robotbd/pause", options);
  }
  static bdHyPause(options = {}) {
    return http.post("/robothyfc/pause", options);
  }
  /**
   * 暂停布丁机器人
   **/
  static bdStopbuy(options = {}) {
    return http.post("/robotbd/stopbuy", options);
  }
  static bdHyStopbuy(options = {}) {
    return http.post("/robothyfc/stopbuy", options);
  }
  /**
   * 布丁机器人列表
   **/
  static bdList(options = {}) {
    return http.post("/robotbd/list", options);
  }
  static bdHyList(options = {}) {
    return http.post("/robothyfc/list", options);
  }

  /**
   * 创建布丁机器人
   * @required open_id
   **/
  static BdAdd(options = {}) {
    return http.post("/robotbd/add ", options);
  }
  /**
   * 创建逐仓机器人
   * @required open_id
   **/
  static ZcAdd(options = {}) {
    // if (options.platform !== "模拟") {
    //   options.platform = `${options.platform}合约`;
    // }
    options.platform = `${options.platform}`;
    return http.post("/robothyfc/add ", options);
  }
  // -----------------------------布丁end------------------
  /**
   * 定投交易记录
   * @required open_id
   * @required id

   **/
  static dtOrder(options = {}) {
    return http.post("/robotdt/order", options);
  }
  static dthyOrder(options = {}) {
    return http.post("/robothymc/order", options);
  }
  /**
   * 定投挂单详情和机器人参数
   * @required open_id
   * @required id

   **/
  static dtView(options = {}) {
    return http.post("/robotdt/view", options);
  }
  static dthyView(options = {}) {
    return http.post("/robothymc/view", options);
  }
  /**
   * 设置循环
   * @required open_id
   * @required id
   * @required type

   **/
  static Dtsetloop(options = {}) {
    return http.post("/robotdt/setloop", options);
  }
    /**
   * 设置循环
   * @required open_id
   * @required id
   * @required type

   **/
    static Mcsetloop(options = {}) {
      return http.post("/robothymc/setloop", options);
    }
  /**
   * 关闭定投机器人
   * @required open_id
   * @required id
   * @required sell

   **/
  static Dtstop(options = {}) {
    return http.post("/robotdt/stop", options);
  }
  /**
   * 关闭满仓机器人
   * @required open_id
   * @required id
   * @required sell

   **/
  static Mcstop(options = {}) {
    return http.post("/robothymc/stop", options);
  }
  /**
   * 创建定投机器人
   * @required open_id
   * @required platform
   * @required symbol
   * @required buy_type
   * @required invest
   * @required buy_price
   * @required buy_ratio_zhiying
   * @required buy_ratio_huitiao
   * @required isloop
   **/
  static Dtadd(options = {}) {
    return http.post("/robotdt/add", options);
  }
  /** 创建满仓机器人 */
  static Qcadd(options = {}) {

    options.platform = `${options.platform}`;

    return http.post("/robothymc/add", options);
  }
  /**
   * 平台列表
   **/
  static platformlist(options = {}) {
    return http.post("/robotini/platformlist", options);
  }
  /**
   * 合约交易对
   **/
  static getSymbol(options = {}) {
    return http.post("/robothy/getsymbol", options);
  }
  /**
   * 定投机器人列表
   **/
  static dtList(options = {}) {
    return http.post("/robotdt/list", options);
  }

  /**
   * 满仓机器人列表
   **/
  static mcList(options = {}) {
    return http.post("/robothymc/list", options);
  }
  /**
   * 合约机器人列表
   **/
  static hyList(options = {}) {
    return http.post("/robothy/list", options);
  }
  /**
   * 获取平台USDT余额
   * @required open_id
   * @required platform
   **/
  static balance(options = {}) {
    return http.post("/robotini/balance", options);
  }

  /**
   * 获取实时价格
   * @required open_id
   * @required platform
   * @required symbol
   **/
  static tickers(options = {}) {
    return http.post("/robotini/tickers", options);
    // if (options.platform === "模拟") {
    //   options.platform = "BINANCE";
    // }
    // if (options.platform.indexOf("合约") == -1) {
    //   options.platform = `${options.platform}`;
    // }
    // return http.post("/robothymc/tickers", options);
  }

  /**
   * API授权(平台绑定)
   *
   * @required huobi_access_key 
   * @required huobi_secret_key 
   * @required binance_access_key 
   * @required binance_secret_key 
   * @required zb_access_key 
   * @required zb_secret_key 

   **/
  static bindplatform(options = {}) {
    return http.post("/user/bindplatform", options);
  }

  /**
   * 添加/编辑策略添加自定义马丁策略
   * @required open_id
   * @required robot_name
   * @required grid_count
   * @required buy_ratio
   * @required grid_param
   * @required zhiying
   * @required sell_ratio_huitiao
   * @required id
   **/
  static robotxAdd(options = {}) {
    return http.post("/robotmd/robotxadd", options);
  }
  /**
   * 策略列表
   * @required open_id
   **/
  static robotxList(options = {}) {
    return http.post("/robotmd/robotxlist", options);
  }
  /**
   * 删除策略
   * @required open_id
   **/
  static robotxDel(options = {}) {
    return http.post("/robotmd/robotxdel", options);
  }
  /**
   * RobotX初始化
   * @required open_id
   **/
  static robotxIni(options = {}) {
    return http.post("/robotmd/robotxini", options);
  }
  /**
   * 收益分析
   * @required open_id
   * @required day
   * @required platform
   **/
  static getShouyi(options = {}) {
    return http.post("/robotini/shouyi", options);
  }
}

export default RobotApi;
