export default {
  YISHUPLUSH: {
    HUOBI:'Huobi',
    XINSHOUZHINAN: "ガイド",
    JIARUSHEQU: "コミュニティ",
    HUODELIRUNKOUCHU: "利益を得た時に控除",
    JQRXQ: "詳細",
    MC: "フル",
    FC: "一部",
    HY: "先物",
    XH: "スポット",
    HYSYPH: "先物ランキング",
    XHSYPH: "スポットランキング",
    ZCGZ: "価値",
    ZCZH: "ポートフォリオ",
    FCGL: "部分管理",
    CJMCJQR: "ロボットを作成",
    CJFCJQR: "ロボットを作成",
    MCCLZYL: "今日の利益",
    MCCLLSZYL: "総利益",
    FCCLZYL: "今日の利益",
    FCCLLSZYL: "総利益",
    BEI: "回数",
    GUZHI: "価値",
    LIRUN: "利益",
    KCW: "オープンポジション",
    ZYFS: "利益確定方法",
    BFB: "パーセンテージ",
    KD: "ロング",
    PD: "ロングクローズ",
    KK: "ショート",
    PK: "ショートクローズ",
    SK: "ロング&ショート",
    CLCS: "パラメーター",
    KCFX: "エントリー方向",
    GGBS: "レバレッジ",
    KDZB: "ロング指標",
    XZDDKCW: "ロングポジション",
    DDZYFS: "ロングの利益確定",
    DDZYZB: "ロングの利益確定指標",
    DDZYBL: "ロングの利益確定率",
    DDZYCHBL: "ロングの利益追跡率",
    DDZSBL: "ロングの損失切り率",
    XT: "オプション",
    KKZB: "ショート指標",
    XZKDKCW: "ショートポジション",
    KDZYFS: "ショートの利益確定",
    KDZYZB: "ショートの利益確定指標",
    KDZYBL: "ショートの利益確定率",
    KDZYHCCBL: "ショートの利益追跡率",
    KDZSBL: "ショートの損失切り率",

    KCZB: "エントリー指標",
    ZYTJ: "利益条件",
    BCFZZB: "補完ポジション補助指標",
    XZBCW: "補完ポジション",
    DX: "複数選択",
    KCTJ: "エントリー条件",
    FCJQRXQ: "一部ポジションロボットの詳細",
    MCJQRXQ: "フルポジションロボットの詳細",
    ZDSL: "注文数量",
    ZYBL: "利益率",
    ZYHCBL: "利益追跡率",
    HTMCBL: "コールバック販売率",
    YL: "プレビュー",
    BCW: "補完ポジション",
    DENGDAI: "待機",
    FCCL: "一部ポジション戦略",
    JYCL: "取引戦略",
    ZYZB: "利益指標",
    DUODAN: "ロングポジション",
    KONGDAN: "ショートポジション",

    ZJMCJ: "ベストセリング価格",
  },
  NEWGN: {
    BD: "プリン",
    BDCL: "プリン戦略",
    CJBDJRR: "プリンロボットを作成する",
    BDCLJRZYL: "今日のプリン戦略総利益",
    BDLSZYL: "プリンの過去の総利益",
    BDLR: "プリンの利益",
    BDJRRXQ: "プリンロボット詳細",
    XYGZCW: "次のサポートレベル",
    ZCWZDSLBF:
      "サポートレベルの数が注文の数と一致していません。サポートレベルの数は次のように選択する必要があります。",
    YL: "プレビュー",
    XRCLK: "Strategy Library ",
    ZCW: "サポート",
    FZXG: "トラックから数分",
    XSXG: "休み時間",
    RXG: "レールの下",

    ZCFX: "資産分析",
    ZZC: "総資産",
    ZRSY: "昨日の収益",
    RSY7: "7日間の収入",
    RSY30: "30日間の収益",
    LSSY: "歴史的リターン",
    SYFX: "利益分析",
    TLFX: "アービトラージ分析",
    LJSYL: "累積収益率",
    LJSY: "累積所得",
    MRSY: "毎日の収入",
    LJTL: "累積裁定取引",
    MRTL: "毎日の裁定取引",
    DAY: "日",
    MONTH: "月",
  },

  XROBOT: {
    XSHUOMING:
      "X-Robotは、ユーザーが戦略パラメーターをよりオープンに設定できるようにするラボであり、戦略を保存して、MartinRobotsにすばやく適用できます。",
    ZDSL: "注文数量",
    ZYBL: "利益率を取る",
    BCBS: "マージン複数",
    HTMCBL: "販売比率へのコールバック",
    YL: "プレビュー",
    XTCLBKBJ: "システムポリシーは編集できません",
    BJ: "編集",
    ZWCL: "戦略なし",
    TJXCL: "新しい戦略を追加する",
    BDTS: "プリン戦略のコールバック購入率は有効になりません",
    XBSYD: "以前の注文補充率との比較",
    XBSYD2: "前回の注文と比較して、購入率はコールバックされます",
    BJCL: "編集戦略",
    SC: "消去",
    SZZDSL: "注文数を設定する",
    SZBCJH: "マージンコールプランを設定する",
    SZZYJH: "テイクプロフィットプランを設定する",
    ZXCL: "戦略を選択する",
    QD: "もちろん",
    CLMC: "戦略名",
    QTXCLMC: "戦略の名前を記入してください",
    ZDBCS: "下向き補充の最大数、最大10を入力できます",
    BCBSSM: "最後の補充量と比較した各補充量の倍数",
    ZDSLZD: "注文の最大数は10です",
    ZDSLZX: "最小注文数量は2です",
    XYB: "次のステップ",
    SYB: "前",
    XBYCCJJZYBL: "平均ポジション価格と比較した利益率を取る",
    XBYCCJJHTMCBL: "平均ポジション価格と比較したコールバック販売比率",
    WC: "終了",
  },
  MONI: {
    MNZH: "デモアカウント",
    MNZHCSJE: "デモ口座の初期額",
    MNSM: "デモアカウントは、Binance Exchangeでのユーザーのトランザクションをシミュレートするためにのみ使用され、トランザクションの深さなどの問題は無視されます。ロボットを作成するときは、シミュレートされたトランザクションを選択してロボットを起動し、BinanceExchangeのリアルタイム価格を決済します。シミュレートされたトランザクションの初期金額は次のとおりです。システムは自動的に設定され、売上高はカウントされません。パネルで詳細を確認してください。 Exchangeでのユーザーのトランザクションをシミュレートするためにのみ使用され、トランザクションの深さなどの問題は無視されます。ロボットを作成するときは、シミュレートされたトランザクションを選択してロボットを起動し、BinanceExchangeのリアルタイム価格を決済します。シミュレートされたトランザクションの初期金額は次のとおりです。システムは自動的に設定され、売上高はカウントされません。パネルで詳細を確認してください。机器人并进行结算，模拟交易的初始金额为系统自动设置且不计入流水，请通过面板查看详情。",
    CKMNZH: "デモアカウントを見る",
    MN: "シミュレーション",
    MNZHZCZE: "総資産(U)",
  },
  HEYUE: {
    HY: "契約",
    HYCL: "契約戦略",
    HYCLJRZYL: "契約戦略今日の総利益",
    HYCLLSZYL: "契約実績総利益：",
    BCTP: "画像を保存",
    BAHY: "Binance契約",
    CJHYJQR: "契約ロボットの作成",
    KD: "Buy",
    KK: "Sell",
    KC: "開倉",
    PC: "平倉",
    ZYJ: "しまりか",
    ZSJ: "ていしそんか",
    BZJLX: "保証金の種類",
    QUANC: "全倉",
    ZHUC: "倉ごと",
    HYJQRXQ: "契約ロボットの詳細",
    DDKC: "開倉待ち",
    JHKCJ: "予定開倉価格",
    DDPC: "平穏を待つ",
    SJKCJ: "実際の開倉価格",
    HYJQRWCYCMRMC: "取引所の裁定を1回完了する",
    KCJG: "開倉価格",
    GGBS: "てこばいすう",
    KCFX: "開倉方向",
    TDHYCCL: "TAの契約戦略",
    QDMRJG: "まず購入価格を決めてください",
    ZSBL: "ストップスケール",
    XGCS: "改訂",
    CSCW: "️️️パラメータエラー!",
    FBT: "(不要)",
  },

  // 补充
  ADD: {
    MDGD: "マーティン",
    DTGD: "定投",
    WGGD: "グリッド",
    MD: "マーティン",
    DT: "定投",
    WG: "グリッド",
    HB: "Huobi",
    BA: "Binance",
    FX: "共有する",
    JYBD: "取引対象",
    SYCL: "戦略",
    SJSYL: "実際の収益率",
    YSJQR: "Tradease",
    NDLHGJ: "Make trade easy",
    ZSYL: "満点は100点しかないので100点取りました",
    FSYL: "通貨サークルは冷酷で愛情深い",
  },
  // ----------其他----------
  ORDER: {
    FZCG: "コピー成功",
    FZSB: "コピーに失敗しました",
    ZWSJ: "データがありません",
    QSRZFMM: "支払いパスワードを入力してください。",
    QD: "を選択します",
    KY: "利用可能:",
    TIAN: "空",
    SHI: "時刻",
    FEN: "ポイント",
  },
  MIANBAN: {
    HB: "Huobi",
    BA: "Binance",
    OY: "OKEX",
    SYZJ: "余剰資金",
    MDCL: "マーティンせんりゃく",
    WGCL: "グリッドポリシー",
    DTCL: "ていとう策",
    CXJJ: "リロード",
    BAZCZE: "Binanceの総資産額",
    HBZCZE: "Huobi総資産額",
    SYZJZ: "注意:余剰資金は、戦略に参加していない資産の換算額です",
    ZCXQ: "資産の詳細",
    DQMYKYPC: "現在平準化可能な通貨はありません",
    YJPC: "ワンタッチ平倉",
    BZ: "通貨",
    SL: "数量",
    YE: "ざんだか",
    KJY: "取引可能な",
    ZWZC: "資産はありません。取引所にチャージしてください",
    ZWBDJYSAPI: "取引所APIは一時的にバインドされていません",
    LJBD: "今すぐバインド",
    ZYSX: "注意事項:ワンタッチ平倉後、すべての通貨は現在の時価でUSDTに売却され、関連ロボットは自働的にオフになりますので、慎重に操作してください！ ！ ！",
    YXBZSL: "選択した通貨数:",
    PCYJKD: "平倉予想入手可能",
    QUANXUAN: "すべて選択",
    QRPC: "確認平倉",
    XUXIAO: "キャンセル",
    QQRPC: "安全なパスワードを入力して平倉を確認してください",
    LHCL: "数量化戦略",
    ZCFB: "資産配分",
  },
  // ---------------------------------登录-------------------------------------------
  LOGIN: {
    // ------------------login--------------
    LOGIN_SUCCESS: "ログイン成功",
    PLEASE_RIGHT_PHONE_EMAIL:
      "正しい携帯番号またはメールアドレスを入力してください。",
    NUM_ERROR: "アカウントのパスワードが間違っています",
    WELCOME: "ログインを歓迎します",
    Tradease: "Tradease",
    LOGIN_TYPE: "携帯番号/メールアドレス/ユーザー名で登録してください。",
    PLEASE_INPUT: "携帯電話番号/メールアドレス/ユーザ名を入力してください。",
    PLEASE_PASSWORD: "パスワードを入力してください",
    FORGET_PASSWORD: "パスワードを忘れます",
    LOGIN_NOW: "すぐに登録します",
    NO_ACCOUNT: "アカウントがありません？",
    REG_NOW: "即時登録",
    AGREE_XY: "ログインを続けると同意と見なされます。",
    AGREE_XY2: "《用户协议》",
  },
  DOWNLOAD: {
    ZCWC: "登録完了",
    GXN: "おめでとうございます",
    SLWCZC: "無事に登録を完了しました",
    DJZZAPP: "下のボタンをクリックしてAPPをダウンロードします。",
    XZAZ: "Androidアプリをダウンロード",
    XZIOS: "iosアプリをダウンロードします",
  },
  // 修改设置支付密码
  OTCPASSWORD: {
    ZFMM: "パスワードを支払う",
    // PLEASE_INPUT_CODE: "グラフィック認証コードを入力してください。",
    NUM_ERROR: "アカウント入力エラー",
    PASSWORD_LEAST: "パスワードは最低8桁で、数字はアルファベットで書きます。",
    PASSWORD_NOSAME: "二回のパスワードが一致しません。",
    MODIFY_PAY_PASSWORD: "支払いパスワードの変更",
    SET_PAY_PASSWORD: "支払パスワードの設定",
    // TX_YANZHENGMA: "グラフィック認証コード",
    // PLEASE_TX_YANZHENGMA: "グラフィック認証コードを入力してください。",
    HQ_YANZHENGMA: "認証コードを取得",
    YZM: "認証コード",
    PLEASE_YZM: "認証コードを入力してください",
    PLEASE_PASSWORD: "パスワードを入力してください",
    CONFIRM_PASSWORD: "パスワードを確認する",
    PLEASE_CONFIRM_PASSWORD: "パスワードを確認してください",
    OK: "を選択します",
    ERROR: "アカウントの取得に失敗しました。再登録してください。",
  },
  // 修改密码
  PASSWORD: {
    PLEASE_RIGHT_NUMBER: "正しいアカウントを入力してください。",
    NUM_ERROR: "アカウントの入力エラー",
    PASSWORD_LEAST: "最低8桁のパスワード、英数字",
    PASSWORD_NOSAME: "パスワードが2回一致しません",
    ZHAOHUI_PASSWORD: "パスワードを取り戻す",
    TIPS: "登録された携帯電話番号のCAPTCHAに基づいてログインパスワードを取得してください",
    EMAIL_NUM: "郵便ポスト",
    PHONE_NUM: "携帯電話番号",
    HQ_YANZHENGMA: "CAPTCHAの取得",
    PLEASE_YZM: "CAPTCHAを入力してください",
    PASSWORD: "パスワード",
    PLEASE_PASSWORD: "パスワードを入力してください",
    PLEASE_PASSWORD2: "パスワードを再入力してください",
    // TISHI: "ログインパスワードは支払いパスワードであり、ログイン後はセキュリティセンターで支払いパスワードを変更することができる",
    CONFIRM_PASSWORD: "パスワードの確認",
    PLEASE_CONFIRM_PASSWORD: "パスワードを確認してください",
    OK: "確定",

    XGDLMM: "ログインパスワードの変更",
    QSRYMM: "元のパスワードを入力してください",
  },
  // 注册
  REGISTER: {
    ZCXZH: "新しいアカウントの登録",
    HYZC: "新しいアカウントへようこそ",
    SJH: "携帯電話番号を入力してください",
    YHM: "ユーザー名",
    YQM: "招待コードを入力してください",
    PLEASE_RIGHT_PHONE: "正しい携帯電話番号を入力してください",
    PLEASE_RIGHT_EMAIL: "正しいメールボックスを入力してください",
    PLEASE_RIGHT_PHONE_EMAIL: "正しいアカウントを入力してください",
    PLEASE_INPUT_CODE: "グラフィックCAPTCHAを入力してください",
    NUM_ERROR: "アカウントの入力エラー",
    PASSWORD_LEAST: "最低8桁のパスワード、英数字",
    PASSWORD_NOSAME: "パスワードが2回一致しません",
    PHONE_REGISTER: "携帯電話の登録",
    EMAIL_REGISTER: "メールボックス登録",
    PHONE_NUM: "携帯電話番号",
    EMAIL_NUM: "郵便ポスト",
    YANZHENGMA: "CAPTCHA",
    PLEASE_YANZHENGMA: "CAPTCHAを入力してください",
    HQ_YANZHENGMA: "CAPTCHAの取得",
    PASSWORD: "パスワード",
    PLEASE_PASSWORD: "パスワードを入力してください",
    PLEASE_PASSWORD2: "パスワードを再入力してください",
    TISHI:
      "支払いパスワードはデフォルトでログインパスワードと一致しており、セキュリティセンターで変更可能",
    NOW_REGISTER: "今すぐ登録",
    HAD_ZHANGHAO: "既存のアカウント?",
    HAD_ZHANGHAO2: "今すぐログイン",
    REGISTER_SUCESS: "登録成功",
    PLEASW_NUM: "アカウントを入力してください",
    LJXZ: "今すぐアプリをダウンロード",
  },
  // 选择国家
  // TELPREFIX: {
  //   CHOOSE_CITY: "国を選択",
  //   OK: "確定する",
  //   SEARCH_CITY: "検索国",
  // },
  // ------------------------------------登录注册结束----------------------------
  // ----------------------------------------跟单-------------------
  GENDAN: {
    QBD: "バインドしてください",
    DTGD: "定投荷為替手形",
    CLYJNH: "戦略予測年",
    YYX: "実行済み：",
    TIAN: "日",
    CLZPM: "このポリシーの週間ランキング",
    DTCLCS: "ていとうせんりゃくパラメータ",
    JYPT: "取引プラットフォーム",
    JYBD: "取引の対象",
    MRJG: "買入価格",
    ZYBL: "しまりひ",
    ZSBL: "ストップスケール",
    HTMRBL: "コールバック購入比率",
    HTMCBL: "コールバック売り比率",
    XHCZBL: "サイクルスケール",
    GSGCL: "ポリシーに従う",
    MDGD: "マーティン・アンド・リスト",
    MDCLCS: "マーティン・ポリシー・パラメータ",
    ZDSL: "注文数量",
    BCBL: "在庫補充比率",
    BCZFBL: "倉庫補充増加率",
    BCBS: "在庫補充倍数",
    WGGD: "グリッドとリスト",
    WGCLCS: "グリッド・ポリシー・パラメータ",
    JGFW: "価格帯",
    WGSL: "メッシュの数",
    GE: "個",
    MGLR: "格当たり利益",
    HD: "すべりてん",
  },
  // -------------------------------------资产相关开始-----------------------
  // 转出
  EXTRACT: {
    // LMC: "チェーン名",
    PLEASE_LOGIN: "先にログインしてください",
    ERROR: "アカウントが間違っています。ログアウトして再ログインしてください",
    YVE_BUZHU: "残高不足",
    MORE_TIBI: "最大転出先",
    LESS_TIBI: "最小転出先",
    INPUT_TX_YANZHENGMA: "グラフィックCAPTCHAを入力してください",
    TIBI: "貨幣を持ち上げる",
    TIBIJILU: "貨幣引上げ記録",
    KE_YONG: "利用可能：",
    TIBI_ADRESS: "貨幣引上げ先",
    INPUT_OR_LANGAN_ADRESS: "USDT-BEP20デニムのアドレスを貼り付けます",
    NUM_USDT: "引上げ数量", //-
    LESS_TIBI_NUM: "最小転出数",
    PHONE_YANZHENGMA: "携帯電話CAPTCHA",
    EMAIL_YANHENGMAL: "メールボックスCAPTCHA",
    PLEASE_YANZHENGMA: "CAPTCHAを入力してください",
    HQ_YANZHENGMA: "CAPTCHAの取得",
    ZHIFU_PWD: "セキュリティパスワード",
    PLEASE_ZHIFU_PWD: "支払いパスワードを入力してください",
    TIXIAN_SUCCESS: "転出成功",
    DAOZHANG_NUM: "じっさい入金",
    SHOUXVFEI: "手数料：",
    ITXIAN: "引揚確認",
    TISHI: "フレンドリーなヒント：",
    TISHI_1: "現在、1人1日あたり最大転出可能",
    TISHI_2: ",1回の転出限度は次のとおりです",
    TISHI_5:
      "資金の安全を確保するために、転出を手動で審査しますので、お待ちください",
    DQGAS: "現在のGas:",
    QHBZ: "通貨の切替え",
    ZCJL: "転出レコード",
    KZ: "回転可能な：",
  },
  // 资产页面DMO
  WALLET: {
    XLSX: "ドロップダウン更新",
    SKLJSX: "今すぐ更新を解除",
    JIFEN: "せきぶん：",
    GMJF: "購入ポイント",
    APISQ: "APIライセンス",
    YQHY: "親友を招待する",
    FWJF: "サービスポイント",
    ZCXQ: "資産の詳細",
    RILI: "カレンダー",
    YUJING: "警告",
    JQQD: "「楽しみにしてください」",
    MONI: "シミュレーション",
    ZWPM: "ランキングなし",
    PAIHANG: "ランキング",
    JIAOYIDUI: "取引ペア",
    PINGTAI: "プラットフォーム",
    YUJINIANHUA: "予測年化",

    ZCXQ: "資産の詳細",
    FWJF: "サービスポイント",
    JFYE: "ポイント残高（1ポイント=1USDT）",
    ZHYE: "勘定残高(USDT)",

    JFLS: "せきぶんりゅうすい",
    SYLS: "すべての水",
    ZRLS: "りゅうすいに転じる",
    ZCLS: "転出水",
    PLEASE_LOGIN: "先にログインしてください",
    GMJF: "購入ポイント",

    CHONG_BI: "貨幣をチャージする",
    TI_BI: "貨幣を持ち上げる",
    DUI_HUAN: "ひきかえ",
    ALL_ASSETS: "総勘定資産換算(USDT)",
    CAN_USE: "利用可能",
    ONE_PRICE: "単価(USDT)",
    ZHE_HE: "USDT換算",
    ZHE_HE_CNY: "CNY換算",
    LOAD: "ロード中",
    LOADING: "売り待ち",
    PENDING: "部分売り",
    SUCCESS: "売れ残り",
    LOCK: "とうけつ",
    DAI_CHUSHOU: "売り物になる",
    JIAOYI_XIANGQING: "取引の詳細",
    ALL_NUM: "そうりょう",
    SHENGYV: "売れ残り",
    ZHICHANLIUSHUI: "資産の流れ",
  },
  // 充值页面
  RECHARGE: {
    CHONGBI: "貨幣をチャージする",
    LIANNAME: "チェーン名",
    PLEASE_LOGIN: "先にログインしてください",
    FAIL_COPY: "コピーに失敗しました。コピーを長押ししてください",
    SUCCESS_COPY: "複製に成功しました",
    CHONG_BI: "繰り入れる",
    USDT_CHONG_BI: "繰り入れる",
    JIEPING_SAVE_QRCODE: "スクリーンショット保存QRコード",
    COPY_ADDRESS: "コピーアドレス",
    ZHUANRU_SHUOMING: "転入の説明：",
    SHUOMING_1:
      "この住所はあなたが唯一かつ独自に使用する転入住所であり、あなたの転入住所は頻繁に変更されることはなく、繰り返しチャージすることができます。 変更があった場合は、お知らせまたはメールでお知らせします",
    SHUOMING_2:
      "上記の住所にERC20_USDT以外の資産をチャージしないでください。そうしないと、資産は回収されません",
    SHUOMING_4:
      "TRC20_USDT以外の資産は上記の住所にチャージしないでください。そうしないと資産は回収されません ",
    SHUOMING_3:
      "上記のアドレスにDMO以外の資産をチャージしないでください。そうしないと、資産は回収されません",
    QHBZ: "通貨の切替え",
  },

  // 转出记录
  TIBIRECORD: {
    TIBIJILU: "貨幣引上げ記録",
    PLEASE_LOGIN: "先にログインしてください",
    DAI_SHEN_HE: "レビュー保留中",
    TI_BI_ZHONG: "パッケージ中",
    SUCCESS: "成功",
    FAIL: "失敗",
    TIBI_JILU: "転出レコード",
    NO_DATA: "データは一時的にありません",
    BIAN_HAO: "番号付け",
    SHU_LIANG: "数量",
    TIME: "時間",
    ADDRESS: "アドレス",
  },
  // -------------------------------------资产相关结束-----------------------
  // ------------------------镜像----------------

  JINGXIANG: {
    JXZBJ: "ミラー引当金",
    KSSJ: "開始時刻",
    TZE: "投資額(U)",
    LJTZE: "累積投資額(U)",
    LJSYE: "累積利益(U)",
    LJSYL: "累積収益率",
    LJJXSC: "累積ミラー時間",
    JYPT: "取引プラットフォーム",
    JXRC: "延べ人数を鏡像化する",
    CLANDSYZB: "戦略とメリットの比率",
    JXYHSZBKJ: "ミラー化されたユーザ設定が表示されない",
    WG: "グリッド",
    MD: "マーティン",
    DT: "定投",
    SYZJ: "余剰資金",
    MDCL: "マーティンせんりゃく",
    WGCL: "グリッドポリシー",
    DTCL: "ていとう策",
    GDY: "荷役係",
    CLZB: "戦略比率",
    ZWSY: "当分収益がない",
    TACL: "TAの戦略",

    JYBD: "取引の対象",
    CJSJ: "作成時間",
    SYL: "収益率",
    ZWSJ: "データは一時的にありません",

    TUIJIAN: "推薦",
    YIJINGXIANG: "ミラーリング",
    JX: "ミラーリング",

    JXKQSJ: "ミラーオン時間（Mirror Open Time）",
    XQ: "詳細",
    YGB: "クローズ済み",

    JXXQ: "ミラーリングの詳細",
    QBZBJCZ: "十分な引当金を確保し、ミラーリングを開始",
    QRJX: "ミラーリングの確認",
    QX: "キャンセル",
    QRRISHI:
      "決済パスワードを入力し、ミラーリングされた口座のフォローアップポリシーを確認し、関連する取引口座に十分な引当金があることを確認し、ミラーリングによって発生した損失はユーザー自身が負担することになりますので、再度確認してください。",
    QSRMM: "パスワードを入力してください",
    ZHYEBZ: "あなたの口座の取引可能残高が引当基準を下回っています",
    LIJIJX: "今すぐミラーリング",

    SSYH: "ユーザーの検索",
    ZWJG: "まだ結果が出ていない",
    CCXQ: "在庫の詳細",
    CZRZ: "操作ログ",
    QXJX: "ミラーリングの解除",
    ZWJL: "レコードなし",
    CKXQ: "詳細を表示",
    QXZH: "ミラーリングをキャンセルすると、自動的にミラーリングアカウントに追従して操作を行うことはありません。進行中のロボットはポリシーを継続し、自分でオフにしてミラーリングをキャンセルすることを確認することができますか？",
    FQ: "放り出す",
    QRQX: "キャンセルの確認",
  },
  // -------------------------------------个人中心相关开始-------------------
  GOUMAIJIFEN: {
    GMJF: "購入ポイント",
    DQJFYE:
      "現在のポイント残高（収益が発生した時点で収益の20%相当分を差し引いたポイント）",
    JFTC: "ポイントセット",
    JFTCSM:
      "異なるパッケージポイントを購入した後、ポイント残高に直接重畳することができる",
    JF: "せきぶん",
    WZK: "割引なし",
    ZK: "割引",
    JFSM: "積分の説明",
    JFSM1:
      "1.購入したことがあるポイントセット（セットタイプ/回数は制限しない）はVIPポイントになることができて、VIPポイントは友達を招待することができて/その他のコミュニティの返利などの権益を享受することができます。",
    JFSM2:
      "2.ポイントは、ロボット戦略によって発生した収益の20%相当の控除にのみ使用され、譲渡/取引することはできません。",
    JFSM3: "3.ポイント購入後の取消・返金不可；",
    JFSM4:
      " 4.ポイント残高が10未満になると、新しい戦略を継続して開始することはできません。進行中の戦略は影響を受けませんが、ポイント残高を差し引いた後、料金を継続して新しい戦略を開始することができます。",
    KYYE: "使用可能残高",
    QRGM: "購入の確認",
    QX: "キャンセル",
    QSRMM: "パスワードを入力してください",
    KYUSDT: "使用可能なUSDT:",
    QSRAQMM: "セキュリティパスワードを入力し、購入を確認してください",
    GMHLJSX: "購入後すぐに有効",
  },
  JXSETTING: {
    JXGL: "ミラー管理",
    YXBJX: "ミラー化を許可",
    YXJXSM:
      "ミラーリングが許可された後、他のユーザーはユーザー名を検索してホームページを表示することができ、すべてのロボットをオフにした場合にのみミラーリングされ、ミラーリングの許可を解除することができます。",
    JXZH: "ミラーアカウント",
    JXZYKJ: "ミラーホームページが表示される",
    OK: "確認",
    CLZB: "戦略比率",
    SYZB: "収益率",
    WGCL: "グリッドポリシー",
    DTCL: "ていとう策",
    MDCL: "マーティンせんりゃく",
  },
  CUSTOMERSERVIXE: {
    MORE_INPUT: "最大200語を入力",
    LIXNXI_KEFU: "カスタマーサービスへの連絡",
    PLEASE_TELL_QUE:
      "質問を記入してください。9:00~18:00にカスタマーサービスが返信します",
    UPLOADING: "アップロード中。。。",
    TIJIAO: "提出",
    HISTORY: "履歴",
    HUIFU: "返答：",
    NO_DATA: "レコードなし",
    GDJL: "ジョブレコード",
    TJGD: "業務指示書を提出する",
  },
  // 邀请好友
  FRINDSCOME: {
    PLEASE_LOGIN: "先にログインしてください",
    FRIND_COME: "親友を招待する",
    YQHYZC: "友達を招待してYISHU数量化ロボットに登録してもらう",
    SMXZ: "以下のQRコードをスキャンして登録し、アプリをダウンロード",
    FZYQLJ: "招待リンクのコピー",
  },
  // 选择语言
  LANGSWITCH: {
    CHOOSE_CITY: "言語の選択",
  },

  // 我的
  MINELIST: {
    GMJF: "購入ポイント",
    CSSYHDK: "収益発生後控除",
    APISQ: "APIライセンス",
    JFZX: "せきぶんちゅう",
    XTGG: "アナウンス",
    BZZX: "ヘルプセンター",
    WDSQ: "マイコミュニティ",
    JXGL: "ミラー管理",
    AQSZ: "セキュリティ設定",
    TJGD: "業務指示書を提出する",
    GYWM: "私たちについて",
    TCDL: "ログアウトログイン",
    QDTC: "終了しますか？",
    QX: "キャンセル",
    OK: "確定",
  },
  // 公告
  NOTICE: {
    GONG_GAO_LIST: "「システムアナウンス」",
    NO_DATA: "「システムアナウンス」",
    GONG_GAO_INFO: "発表の詳細",
    BZZX: "ヘルプセンター",
  },
  // 安全中心
  SAFETY: {
    ANQUAN_ZHONGXIN: "あんぜんセンタ",
    ZHIFU_PWD: "支払いパスワードの変更",
    XIUGAI_PWD: "ログインパスワードの変更",
  },
  // http
  HTTP: {
    ERROR_LOG: "ログイン情報の取得に失敗しました",
    ERROR_WANGLUO: "要求に失敗しました",
  },
  // footer

  FOOTER: {
    SHOUYE: "ホーム",
    CELUE: "せんりゃく",
    MIANBAN: "パネル",
    JINGXIANG: "鏡",
    ZHUANGHU: "アカウント",
  },
  // -----------------------------个人中心结束------------------------------------
  //  社区
  SHEQU: {
    SQ: "コミュニティ",
    GZ: "規則",
    WU: "なし",
    SHEQUDENGJI: "コミュニティレベル",
    BZYJ: "今週の業績",
    ZYJ: "総業績",
    WODESHEQU: "マイコミュニティ",
    QXGMJF: "まずポイントを購入してVIP会員になってください",
    YQHY: "親友を招待する",
    ZWSQ: "コミュニティは一時的にありません",

    VIP1: "VIP1",
    VIP2: "VIP2",
    VIP3: "VIP3",
    VIP4: "VIP4",
    GZSM: "ルールの説明",
    GUIZHE: `
    <h3>VIP会員:</h3>
          <p>ポイントパッケージを一度購入したことがあるユーザーはVIP会員となります。 他人をミラーしていないユーザーがロボットを終了した後、利益相当部分の10%のポイントを統一的に控除する。 他人を鏡像化したユーザは、ロボットを終了した後、利益相当部分の20%のポイントを一律に控除する。</p>

          <h3>インセンティブ1-推薦賞（秒単位）:</h3>
          <p>
            ユーザーはサービスポイントの10%をインセンティブとして受け取ることができます；<br></br>
            間プッシュユーザーはサービスポイントの5%をインセンティブとして受け取ることができる。</p>

          <h3>インセンティブ2-コミュニティ賞（格差/日結）:</h3>
          <p>
            \VIP 2：VIP会員5人を推すとVIP2になり、サービスポイントの5%をインセンティブとして受け取ることができる；<br></br>
         \VIP 3：VIP2を3つプッシュするとVIP 3になり、サービスポイントの10%がボーナスとして支給される；<br></br>
            VIP 4：VIP 3を3つプッシュするとVIP 4になり、サービスポイントの16%がボーナスとして提供されます。
          </p>

          <h3>インセンティブ3-配当賞（日結）:</h3>
          <p>
            すべてのVIP2はパフォーマンス比で3%に加重されます；<br></br>
            すべてのVIP3はパフォーマンス比で3%に加重されます；<br></br>
           すべてのVIP4はパフォーマンス比で3%に加重されます。
          </p>
          <h3>インセンティブ4-ミラー配当（戦略による決済）:</h3>
          <p>
           ミラーリングポリシーをオンにした場合は、ミラーリングユーザーがサービスポイントを消費した場合の40%をインセンティブとして受け取ることができます。<br></br>
          </p>
          <h3>特記事項:</h3>
          <p>  
            以上のルールはすべてYISHUロボットAPPクライアントを通じて自働的に実行して、もし誰かがエスクローなどのいかなる形式でコミッションを要求して、信じないでください！
          </p> 
    `,
  },
  // 用户协议
  AGREEMENTS: {
    USER_AGREEMENTS: "ユーザープロトコル",
    XIEYI: ` <p>YISHUロボティクスは、ユーザーとして登録する前に、本ユーザー契約書（以下「YISHUロボティクスユーザー契約書」）をよくお読みください。また、本契約書の条項を十分に理解していることを確認してください。 慎重にお読みの上、本契約に同意するか否かを選択してください。 お客様は、本契約のすべての条項に同意しない限り、本契約の対象となるサービスに登録、ログイン、または使用する権利を有しません。 お客様の登録、ログイン、使用などの行為は、本契約の受諾とみなされ、本契約の条項に同意するものとします。 
本契約は、「YISHUロボット」ソフトウェアサービス（以下「サービス」という）に関するYISHUロボットとユーザーとの間の権利義務を規定しています。 「利用者」とは、本サービスに登録、ログイン、および利用する個人を意味します。 このプロトコルは、YISHUロボットがいつでも更新できます、 
更新された契約条項は、予告なしに元の契約条項に代わるものであり、ユーザーは本サイトで最新版の契約条項を参照することができます。 YISHUロボットが契約条件を変更した後、変更した契約条件に同意しない場合は、直ちにYISHUロボットが提供するサービスの利用を中止してください。YISHUロボットが提供するサービスを継続して利用することは、変更した契約条件に同意したものとみなされます。 
</p>
    <p>本契約は、「YISHUロボット」ソフトウェアサービス（以下「サービス」という）に関するYISHUロボットとユーザーとの間の権利義務を規定しています。 「利用者」とは、本サービスに登録、ログイン、および利用する個人を意味します。 このプロトコルは、YISHUロボットがいつでも更新できます、 
更新された契約条項は、予告なしに元の契約条項に代わるものであり、ユーザーは本サイトで最新版の契約条項を参照することができます。 YISHUロボットが契約条件を変更した後、変更した契約条件に同意しない場合は、直ちにYISHUロボットが提供するサービスの利用を中止してください。YISHUロボットが提供するサービスを継続して利用することは、変更した契約条件に同意したものとみなされます。 
</p>
    <h3>一、アカウント登録</h3>
    <p>1、利用者は本サービスを利用する前に「YISHUロボット」アカウントを1つ登録する必要があります。 「YISHUロボティクス」アカウントは携帯電話番号バインドを使用して登録する必要があります。ユーザーは、「YISHUロボティクス」アカウントにバインドされていない携帯電話番号、および本契約に基づいてYISHUロボティクスによって禁止されていない携帯電話番号を使用して「YISHUロボティクス」アカウントを登録してください。 YISHUロボットは、ユーザーに事前に通知することなく、ユーザーのニーズや製品のニーズに応じてアカウントの登録やバインドの方法を変更することができます。</p>
    <p>2、“YISHUロボット」はYISHUロボットの地理的位置と壱切関連の個人情報を授権するモバイルソーシャル製品であるため、ユーザーが登録を完了すれば、ユーザーはYISHUロボットがユーザーの地理的位置情報を抽出、公開、使用することに同意することを表明する。</p>
    <p>3、「YISHUロボット」アカウントのバインド登録方法を考慮して、あなたはYISHUロボットが登録時にあなたから提供された携帯電話番号および/またはあなたの携帯電話番号の自動抽出およびあなたの携帯電話デバイスの識別コードの自動抽出などの情報を登録に使用することに同意します。</p>
    <p>4、ユーザーが本サービスを登録して利用する際には、YISHUロボットが必要に応じてユーザーに連絡したり、ユーザーの操作性を向上させたりするために、YISHUロボットはユーザーを識別する個人情報を収集する必要があります。 YISHUロボットが収集する情報には、ユーザーの氏名、性別、年齢、生年月日、身分証明書番号、住所、学校状況、会社状況、所属業界、趣味、よく出没する場所、個人説明が含まれるが、これらに限定されない。 YISHUロボットは、これらの情報の使用が第3条ユーザーのプライバシー情報保護の制限に限定されることに合意しました。</p>
    <h3>二、サービス内容</h3>
    <p>本サービスの具体的な内容は、YISHUロボットが実際の状況に応じて提供します。これには、許可されたユーザーがアカウントを介してリアルタイムで通信すること、友達を追加すること、グループに参加すること、他人に注意を払うこと、メッセージを投稿することなどが含まれますが、これらに限定されるもので YISHUロボットは、その提供するサービスを変更することができ、YISHUロボットが提供するサービスの内容はいつでも変更される可能性がある。 ユーザーにはYISHUロボットからサービス変更の通知が届きます。</p>
    <h3>三、ユーザーのプライバシー保護</h3>
    <p>1、ユーザーは、アカウントの登録または本サービスの利用中に、法令、規則規範文書（以下「法令」）に規定されている識別情報など、必要な情報を記入または提出する必要がある場合があります。 不完全な情報、または法令に準拠していない情報を提出したユーザーは、本サービスを利用できないか、または本サービスの利用に制限を受けることがあります。</p>
    <p>2、プライバシー情報とは、ユーザの実名、ID番号、携帯電話番号、携帯電話デバイス識別コード、IPアドレス、ユーザのチャット記録など、ユーザの個人識別またはプライバシーに関わる情報である。 非プライバシー情報とは、本サービスに対するユーザの操作状態や使用習慣などがYISHUロボットのサーバ側に明確かつ客観的に反映される基本的な記録情報をいう、 
個人のプライバシー情報の範囲外のその他の一般的な情報、およびユーザが開示することに同意した上記のプライバシー情報。 </p>
    <p>3、ユーザーのプライバシー情報の私有性を尊重することはYISHUロボットの壱貫制度であり、YISHUロボットは技術的措置その他必要な措置を講じて、ユーザーのプライバシー情報の安全性を確保し、本サービスで収集したユーザーのプライバシー情報の漏洩、毀損、紛失を防止します。 前述の状況が発生した場合、またはYISHUロボットが前述の状況が発生する可能性があることを発見した場合には、速やかに救済措置を講じる。</p>
    <p>YISHUロボットは、ユーザーの同意を得ずに、ユーザーのプライバシー情報を第三者に公開、開示しません。 但し、以下の特定の状況を除く：
      <br></br>(1)YISHUロボットは、法令の規定または権限のある機関の指示に基づいて、ユーザーのプライバシー情報を提供します；
      <br></br>(2)利用者がパスワードを他人に知らせたり、登録アカウントとパスワードを他人と共有したりしたことによる個人情報の漏洩その他YISHUロボットに起因しないプライバシー情報の漏洩；
      <br></br>(3)ユーザーが自分のプライバシー情報を第三者に公開する；
      <br></br>(4）ユーザとYISHUロボット及び協力機関との間でユーザのプライバシー情報の使用公開について合意し，YISHUロボットは協力機関にユーザのプライバシー情報を公開する。
      <br></br>(5)ハッカー攻撃、コンピュータウイルスの侵入、およびその他の不可抗力によるユーザーのプライバシー情報の漏洩。
      <br></br>(6)利用者は、政治的有害性、わいせつポルノ、広告、血なまぐさい暴力、誹謗侮辱、プライバシー漏洩などの情報を積極的に流布している。
    </p>
    <p>5、ユーザは，YISHUロボットが以下の事項においてユーザのプライバシー情報を利用できることに同意する。
      <br></br>(1)YISHUロボットは、ソフトウェアの更新、本契約条件の変更などの重要な通知をユーザーにタイムリーに送信します；
      <br></br>(2) YISHUロボットの内部監査、データ分析、研究などを行い、YISHUロボットの製品、サービス、ユーザーとのコミュニケーションを改善する;
      <br></br>(3) 本契約に基づき、YISHUロボットはユーザー情報を管理、審査、処理を行う；
      <br></br>(4) その他法令の規定を適用する事項。
      <br></br>上記以外にも、利用者の事前の同意がない限り、YISHUロボットは利用者のプライバシー情報を他の用途に使用することはありません。。
    </p>
    <p>6、YISHUロボットは未成年者のプライバシー情報保護を重視。
    YISHUロボットは，ユーザから提供された個人情報に依存して，ユーザが未成年者であるか否かを判断する。 
18歳未満の未成年者がアカウントを登録したり、本サービスを利用するには、保護者またはその法定後見人（以下「後見人」）の書面による事前の同意が必要です。 
法令の規定及び権限を有する機関の指示に基づく開示を除き， 
YISHUロボットは、未成年者のチャット記録やその他のプライバシー情報を使用したり、第三者に開示したりすることはありません。 本契約に定める例外を除き、 
保護者の事前の同意なしに、YISHUロボットは未成年者のプライバシー情報を使用したり、第三者に開示したりすることはありません。 </p>
    <p>7、ユーザーは、その地理的位置情報が非プライバシー情報であることを確認し、ユーザーがYISHUロボットのアカウントを登録したことを、YISHUロボットの認証を確認したものと見なす 
ユーザーの地理的位置情報を抽出、公開、および使用します。 ユーザーの地理的位置情報は、ユーザーの公開資料として使用されます、 
YISHUロボットから他のユーザーに公開されています。 ユーザが他のユーザへの地理的位置情報の公開を中止する必要がある場合は、いつでも自分でステルス状態に設定することができる 。</p>
    <p>8、YISHUロボットの技術とサービスを改善し、ユーザーにより良いサービス体験を提供するために、 
YISHUロボットは、ユーザーのプライバシー以外の情報を収集して使用したり、第三者に提供したりすることができます 。</p>
    <h3>四、コンテンツ仕様</h3>
    <p>1、本条では、本サービスの利用中にユーザーによって作成、アップロード、複製、公開、または配布されたすべてのコンテンツを意味します、 
アカウントのアバター、名称、ユーザー説明などの登録情報及び認証資料、又は文字、音声、画像、動画、画像文などの送信を含むがこれらに限定されない、 
アカウントまたは本サービスを使用した結果として発生したメッセージおよび関連リンクページへの返信または自動返信 。</p>
    <p>2、ユーザーは、YISHUロボティクスアカウントまたは本サービスを利用して、以下の法律、規制、およびポリシーで禁止されているコンテンツを作成、アップロード、複製、公開、配信することはできません：
      <br></br>(1) 憲法によって定められた基本原則に反対するさま；
      <br></br>(2) 国家の安全を害し、国家の秘密を漏洩し、国家の政権を転覆し、国家の統一を破壊するもの；
      <br></br>(3) 国家の名誉と利益を損なうさま；
      <br></br>(4) 煽民族の憎悪、民族差別を働かし、民族の団結を破壊するもの；
      <br></br>(5) 国家の宗教政策を破壊し，邪教や封建的迷信を宣伝するもの；
      <br></br>(6)デマを流し、社会秩序を乱し、社会の安定を破壊する；
      <br></br>(7) わいせつ、ポルノ、賭博、暴力、殺人、恐怖を流布し、又は犯罪を教唆した場合；
      <br></br>(8) 他人を侮辱又は誹謗し、他人の合法的権益を侵害した場合；
      <br></br>(9) 法律法規の最低ライン、社会主義制度の最低ライン、国家利益の最低ライン、公民の合法的権益の最低ライン、社会公共秩序の最低ライン、道徳・風習の最低ライン及び情報の真実性の最低ラインの「7つの最低ライン」の要求を遵守しない場合；
      <br></br>(10) 法律、行政法規で禁止されているその他の内容を含む情報。
    </p>
    <p>3、ユーザーは、YISHUロボティクスアカウントまたは本サービスを利用して、作成、アップロード、コピー、公開を行うことはできません、 
YISHUロボットの正常な運営を妨害し、他のユーザーや第三者の合法的権益を侵害する内容を次のように流布する ：
      <br></br>(1) 性的または性的暗示のいずれかを含むさま；
      <br></br>(2) 罵倒、恐喝、脅迫の内容を含むもの；
      <br></br>(3) 嫌がらせ、迷惑広告、悪意情報、欺瞞情報を含むもの；
      <br></br>(4) 他人のプライバシー、個人情報又は資料に関わる場合；
      <br></br>(5) 他人の名誉権、肖像権、知的財産権、営業秘密などの合法的権利を侵害した場合；
      <br></br>(6) 本サービスの正常な運用を妨害し、他のユーザーまたは第三者の正当な利益を侵害するその他の情報を含む。

    </p>
    <h3>五、規則の使用</h3>
    <p>1、本サービスにおいて、または本サービスを通じてユーザーが送信または公開するコンテンツは、本サービスを反映したり、代表したりするものではありません、 
また、YISHUロボットの見解、立場、方針を反映したり代表したりするものとはみなされず、YISHUロボットはこれに対していかなる責任も負わない 。</p>
    <p>2、利用者は、YISHUロボティクスアカウントや本サービスを利用して、以下の行為をしてはならない：
      <br></br>(1) 虚偽の情報を提出、発布し、又は他人の顔写真若しくは資料を盗用し、他人の名義を詐称、利用した場合；
      <br></br>(2) 他のユーザーに注意を促したり、リンクされたページをクリックしたり、情報を共有したりする場合；
      <br></br>(3) 事実を虚構し、真実を隠蔽して他人をミスリードし、欺瞞する場合；
      <br></br>(4) 技術的手段を利用して虚偽のアカウントを大量に作成した場合；
      <br></br>(5) YISHUロボティクスアカウントまたは本サービスを利用して違法犯罪活動を行った場合；
      <br></br>(6) YISHUロボティクスアカウントまたは本サービスを利用して違法犯罪活動を行った場合，
      これらの行為が商業目的であるか否かにかかわらず；
      <br></br>(7) その他法律法規の規定に違反し、その他ユーザーの合法的権益を侵害した場合、 
YISHUロボットの正常な運営を妨害し、又は深セン越境創新科技有限公司が明示的に授権していない行為 。
    </p>
    <p>3、ユーザーは、YISHUロボティクスアカウントまたは本サービスを利用して送信された情報の真正性、合法性、無害性、正確性を必要とする、
    利用者が発信した情報に関する法的責任は、YISHUロボットとは関係なく、利用者自身が負うものとし、有効性等については全面的に責任を負うものとする。
      このため、YISHUロボットや第三者に損害を与えた場合、ユーザーは法に基づいて賠償しなければならない。</p>
    <p>4、YISHUロボティクスが提供するサービスには、YISHUロボティクスおよびサードパーティベンダーを使用中に表示することに同意する広告が含まれている場合があります、 
パートナー提供の広告。 法令に明示的に規定されている場合を除き、利用者は、当該広告情報に基づいて行われた取引について、自ら責任を負うものとします、 
YISHUロボットは、当該広告情報に基づいて行われた取引又は当該広告主が提供したコンテンツによりユーザーが被った損失又は損害については一切責任を負わない 。</p>
    <h3>六、アカウント管理</h3>
    <p>1、 YISHUロボティクスのアカウントはYISHUロボティクスが所有しています、 
ユーザーは登録申請手続きを完了すると、YISHUロボット」アカウントの使用権を取得し、 
当該使用権は初期登録申請者のみに帰属し、贈与、借用、賃貸、譲渡又は販売を禁止する。 YISHUロボティクスは、経営上の必要性から、 
ユーザーのYISHUロボティクスアカウントを回収する権限があります 。</p>
    <p>2、ユーザーは、YISHUロボットアカウントのプロフィールを変更、削除することができます、 
情報や送信内容などを登録するが，関連情報を削除すると同時に，ユーザがシステムに保存した文字や画像も削除され，ユーザがそのリスクを負うことに注意する必要がある 。</p>
    <p>3、ユーザーは、YISHUロボットアカウントのプロフィールを変更、削除することができます、 
情報や送信内容などを登録するが，関連情報を削除すると同時に，ユーザがシステムに保存した文字や画像も削除され，ユーザがそのリスクを負うことに注意する必要がある 。</p>
    <p>4、ユーザーは、本契約の条項を遵守し、本サービスを適切かつ適切に使用するものとします。本契約の条項に違反した場合、 
YISHUロボティクスは、ユーザーに通知した後、契約に基づいて、契約違反ユーザーのYISHUロボティクスアカウントへのサービス提供を中断または終了する権利を有します。 
また、YISHUロボットは、いつでもYISHUロボットのアカウントやユーザー名を取り戻す権利を保有している 。</p>
    <p>5、ユーザーがYISHUロボットのアカウントを登録してから1年間ログインしない場合、ユーザーに通知した後、YISHUロボットはそのアカウントを回収することができます、 
リソースを無駄にしないようにし、ユーザーの責任でリソースを無駄にしないようにします 。</p>
    <h3>七、データストア</h3>

    <p>1、YISHUロボティクスは、本サービスにおけるユーザーの関連データの削除または保存の失敗に対して責任を負いません。</p>
    <p>2、YISHUロボットは、本サービスにおけるユーザーのデータの最長保存期間を、実際の状況に応じて独自に決定することができます、 
また、サーバ上にデータの最大記憶領域などを割り当てる。 お客様は、必要に応じて本サービスの関連データをバックアップすることができます 。</p>
    <p>3、利用者が本サービスの利用を中止した場合、または本サービスが終了した場合、YISHUロボットはサーバから利用者のデータを永久に削除することができます。 
本サービスの停止・終了後、YISHUロボはユーザーに一切のデータを返却する義務はありません 。</p>
    <h3>八、リスク負担</h3>
    <p>1、ユーザーは、YISHUロボットがユーザーに情報の共有、送信、および取得のためのプラットフォームを提供するだけであることを理解し、同意する、 
ユーザーは、ユーザーが送信したコンテンツおよびそれによって生じた結果を含め、自分の登録アカウントの下での壱切行為に責任を負わなければなりません。 
利用者は、YISHUロボット」および本サービスのコンテンツを自ら判断し、コンテンツの利用に起因するすべてのリスクを負うものとします、 
これには、コンテンツの正確性、完全性、または実用性への依存に起因するリスクが含まれます。 
YISHUロボットは、ユーザーの行動に起因する損失や損害に対して責任を負うことはできません。また、責任を負うことはありません。 
本契約に違反した者、または本サービスを不当に使用した者を発見した場合、 
直ちにYISHUロボティクスに通報または苦情を申し込んでください。YISHUロボティクスは本契約に基づいて処理します 。</p>
    <p>2、ユーザーは、ビジネスの発展のために、YISHUロボティクスが本サービスの全部または一部の変更を一方的に行うことを理解し、同意するものとします、 
ユーザーがリスクを負う、一時停止、終了、または取り消しの権利 。</p>
    <h3>九、知的財産権に関する声明</h3>
    <p>1、本サービスで提供されるコンテンツは、本サービスに関連する広告に関する知的財産権が該当する広告主に帰属する場合を除き、（YISHUロボティクス）本サービスで提供されるコンテンツ 
（Webページ、テキスト、画像、オーディオ、ビデオ、グラフなどを含みますが、これらに限定されません）の知的財産権は（YISHUロボット）に帰属します、 
ただし、ユーザーが本サービスを利用する前に自身が公開したコンテンツについて合法的に知的財産権を取得している場合はこの限りではない 。</p>
    <p>2、特に断りのない限り、本サービスを提供するソフトウェアの著作権、 
特許権やその他の知的財産権はすべて所有している 。</p>
    <p>3、（YISHUロボティクス）本サービスの対象となるグラフィック、テキスト、またはこれらの構成要素、 
その他（YISHUロボティクス）のロゴおよび製品・サービス名（以下「（YISHUロボティクス）ロゴ」と総称する） 
その著作権または商標権は（YISHUロボット）に帰属します。 （YISHUロボティクス）事前の書面による同意なしに、 
ユーザーは、（YISHUロボット）ロゴをいかなる方法でも表示したり使用したり、その他の処理をしたりしてはならない、 
また、ユーザがロゴを表示、使用、またはその他の処理する権利を有することを他人に表明してはならない（YISHUロボット） 。</p>
    <p>4、上記およびその他（YISHUロボット）または関連広告主が法により所有する知的財産権は、法律により保護されています、 
ユーザーは、（YISHUロボット）または関連広告主の書面による許可なく、いかなる形式でも関連する派生著作物を使用または作成してはならない 。</p>
    <h3>十、法的責任</h3>

    <p>1、YISHUロボットが、ユーザーが本契約の合意に違反しているという他人の通報または苦情を発見または受信した場合、 
YISHUロボットは、ユーザー資料、チャット記録を含むがこれらに限定されない関連コンテンツを通知なしにいつでも審査、削除する権利を有している、 
また、情状の軽重に応じて、違反アカウントに対して警告、アカウント禁止、設備禁止、機能禁止を含むがこれらに限定されない処罰を科し、かつユーザーに処理結果を通知する 。</p>
    <p>2、ユーザー契約に違反して禁止されたユーザーは、YISHUロボットカスタマーサービスに連絡して禁止期間を問い合わせることができます、 
また、禁止期間が満了した後に自己開封する。 このうち，機能封じ込めを実施されたユーザは，封じ込め期間が満了すると自動的に封じ込められた機能を再開する。 
禁止されたユーザーはYISHUロボットのカスタマーサービスに苦情を申し立てることができ、YISHUロボットは苦情を審査し、処罰措置を変更するかどうかを自ら合理的に判断して決定する 。</p>
    <p>3、ユーザーは、YISHUロボットが、関連する法令または本契約の規定に違反する行為を合理的な判断に基づいて処罰する権利を有することを理解し、同意します、 
法令に違反したユーザーに対して適切な法的措置をとるとともに、法令に基づいて関連情報を保存し、関係部門等に報告すること、 
ユーザーは、これに起因する法的責任を負うものとします 。</p>
    <p>4、ユーザーは、ユーザーが本契約に違反したことに起因する、または生じた第三者からの請求、請求、または損失を理解し、同意するものとします、 
合理的な弁護士費用を含め、ユーザーはYISHUロボットと協力会社、関連会社に賠償し、損害を与えないようにしなければならない 。</p>
    <h3>十一、不可抗力その他の免責事由</h3>

    <p>1、ユーザーは、本サービスを利用する際に不可抗力などのリスク要因に遭遇する可能性があることを理解し、確認します、 
本サービスを中断します。 不可抗力とは、予見することができず、克服することができず、回避することができず、かつ、壱方又は双方に重大な影響を与える客観的な事件をいう、 
これには、洪水、地震、疫病、嵐などの自然災害、戦争、動乱、政府行為などの社会的事件が含まれますが、これらに限定されません。 このような状況が発生した場合、。 
YISHUロボットは努力して第壱時間に関連単位と協力して、直ちに修復を行いますが、これによってユーザーや第三者にもたらした損失、 
YISHUロボティクスと協力会社は法律で認められる範囲で免責される 。</p>
    <p>2、本サービスは、ほとんどのインターネットサービスと同様に、ユーザーの理由、ネットワークサービスの品質、 
社会環境などの要因の違いに影響され、ユーザーのデータを他人が利用するなど、さまざまなセキュリティ上の問題にさらされる可能性があります、 
現実の生活の中で嫌がらせを引き起こす。 ユーザーがダウンロードしてインストールした他のソフトウェアやアクセスした他のWebサイトには、「トロイの木馬」などのウイルスが含まれています、 
ユーザーのコンピュータ情報やデータのセキュリティを脅かしたり、本サービスの正常な利用に影響を与えたりすることがあります。 ユーザーは情報セキュリティとユーザーデータの保護意識を強化しなければならない、 
損失や嫌がらせからパスワード保護を強化する必要がある 。</p>
    <p>3、ユーザーは、不可抗力、コンピュータウイルスまたはハッカー攻撃、システムの不安定性、ユーザーの所在、 
ユーザーのシャットダウン、その他のテクノロジー、インターネット、通信回線などの理由により、サービスが中断したり、ユーザーの要件を満たすことができなくなったりするリスクがあります、 
YISHUロボットは、ユーザーや第三者に損害を与えた場合、一切の責任を負わない 。</p>
    <p>4、ユーザーは、本サービスの利用中に、他のユーザーからの誤解を招くような、 
欺瞞的、脅迫的、中傷的、不快感、違法な情報、または他人の権利を侵害する匿名または偽名の情報、 
また、これらの情報に伴う行為によりユーザーや第三者に損害が生じた場合には、YISHUロボットは一切責任を負いません 。</p>
    <p>5、ユーザーは、YISHUロボットがYISHUロボットプラットフォームまたは関連機器を定期的または不定期にオーバーホールまたは保守する必要があることを理解し、確認する、 
このような状況により合理的な期間内にサービスが中断された場合には、YISHUロボットは責任を負う必要はなく、YISHUロボットは事前に通知しなければならない 。</p>
    <p>6、YISHUロボティクスは、法令および本契約に基づいて、法令違反または違約を処理する権利を取得します、 
この権利はYISHUロボットの義務や約束を構成するものではなく、YISHUロボットは法律違反や違約行為を適時に発見したり、相応の処理を行うことを保証するものではない 。</p>
    <p>7、ユーザーは、YISHUロボットがユーザーに提供する以下の製品またはサービスの品質上の欠陥およびそれに起因する損失について理解し、確認する、 
YISHUロボティクスは責任を負わない:（1）YISHUロボティクスが利用者に無料で提供するサービス ；</p>
    <p>8、いかなる場合においても、YISHUロボットは、間接的、結果的、懲罰的、偶発的、特殊的、または刑罰的な損害を与えるものではありません、 
Tradease Robotics」または本サービスを使用したことによる利益の損失を含みます、 
責任を負う（YISHUロボットが損失の可能性を知らされていたとしても）。 この協定に矛盾する規定が含まれる場合があるにも関わらず、 
YISHUロボットがユーザに対して負う全責任は、理由や行動様式にかかわらず、ユーザがYISHUロボットを使用したことによるものを超えない 
提供されたサービスのためにYISHUロボットに支払われた料金（ある場合） 。</p>
    <h3>十二、サービスの変更・中断・終了</h3>
    <p>1、ネットワークサービスの特殊性を考慮して、ユーザーはYISHUロボットがいつでも変更する権利を有することに同意した、 
サービス（有料サービスを含む）の一部または全部を中断または終了します。 YISHUロボット変更、 
サービスが中断または終了した場合、YISHUロボットは変更、中断、または終了する前にユーザーに通知する必要があります、 
また，影響を受けたユーザに同等の代替サービスを提供しなければならない 。</p>
    <p>2、YISHUロボットは、次のいずれかの状況が発生した場合、ユーザーに提供されている無料サービスを変更、中断、または終了する権利を有します 
ユーザーまたは第三者に対する責任なしで ：
    (1) 法律上、利用者は真実の情報を提出しなければならず、利用者が提供した個人データは真実ではない 
または登録時の情報と一致せず、合理的な証明を提供できなかった ；(2) ユーザーが関連法令または本契約の合意に違反した場合；
    (3) 法律の規定または権限のある機関の要求に応じて；(4) 安全上の理由又はその他の必要な状況から。
    </p>
    <h3>十三、その他</h3>

    <p>1、YISHUロボットは、責任を免除し、ユーザーの権利を制限する本契約の条項について、ユーザーに厳重に注意を促します、 
ユーザーはよく読んで、自主的にリスクを考えてください。 未成年者は、法定後見人とともに本契約書を読むものとする 。</p>
    <p>2、本契約の効力、解釈及び紛争の解決は、中華人民共和国の法律に適用される。 
もしユーザーとYISHUロボットの間にいかなる紛争や紛争が発生したら、まず友好的に協議して解决しなければならなくて、協議が成立しない場合、 
利用者が紛争または紛争を裁判所に付託することに同意すること 。</p>
    <p>3、本契約のいずれかの条項が無効であるか、またはいかなる理由で実行可能でないかにかかわらず、残りの条項は有効であり、両当事者を拘束する。</p> 
    <p  class='redtext'>
    投資には慎重が必要で、市場に入るにはリスクがある！<br>
    本ソフトウェアが提供する戦略やソフトウェア内のミラーリングなどの機能の使用は参考としてのみ使用されており、投資品目や商品は投資助言には該当しません。ミラーリングなどの機能による損失はユーザー自身が負担しますので、再確認してください。</p>
    `,
  },
  EXCEPTION: {},
  // 策略相关
  CELUE: {
    // 新增排序
    ORDERBY_START: "開始時間別",
    ORDERBY_INVEST: "投資額別",
    ORDERBY_DEFAULT: "デフォルト",
    ORDERBY: "ソート方法",
    // 策略页面
    MDCL: "マーティンせんりゃく",
    WGCL: "グリッドポリシー",
    DTCL: "ていとう策",
    HYCL: "契約戦略",
    GBZ: "クローズ中",
    CZCG: "操作に成功しました",
    QBDAPI: "APIをバインドしてください",
    CJMDJQR: "マーティンロボットの作成",
    MDCLJRZYL: "マーティン戦略今日の総利益(USDT)",
    MDLSZYL: "マーティンの歴史的総利益：",
    WDJQR: "私のロボット",
    QB: "すべて",
    LSJQR: "履歴ロボット",
    ZWJRR: "ロボットなし",
    YGB: "クローズ済み",
    YCZ: "リセットされました",
    YXSC: "実行時間：",
    CJ: "作成",
    TZE: "投資額",
    ZLR: "総利益",
    MDLR: "マーティン利潤",
    CCZE: "総保有高",
    CCL: "持ち高",
    CCDS: "チラシたんすう",
    CCJJ: "在庫平均価格",
    DQJG: "カレントプライス",
    QDJQR: "スタートロボット",
    ZTJQR: "ポーズロボット",
    KQMR: "購入開始",
    ZTMR: "買い入れを一時停止する",
    JQRXQ: "ロボットの詳細",
    JXJQRBKCZ: "ミラーロボットは動作しません",
    ZWBDJYSAPI: "取引所APIは一時的にバインドされていません",
    LJBD: "今すぐバインド",
    CJWGJRQ: "メッシュロボットを作成する",
    WGCLJRZYL: "グリッド戦略の今日の総利益(USDT)",
    WGLSZYL: "グリッド履歴の総利益：",
    WGLR: "グリッドマージン",
    WGZDJ: "グリッド安値",
    WGZGJ: "グリッドの最高値",
    CJDTJQR: "固定投影ロボットの作成",
    DTCLJRZYL: "定額投資戦略今日の総利益(USDT)",
    DTLSZYL: "定額投資過去総利益：",
    DTLR: "ていとう利潤",
    MRJ: "購入価格",
    ZYBL: "しまりひ",
    ZSBL: "ストップスケール",
    TZCF: "繰り返しを停止",
    CFZX: "くりかえしじっこう",
    GBJQR: "シャットダウンロボット",
    GBJQRHWFCQ:
      "ロボットをオフにすると再起動できません。USDTに自動的に販売する必要があるかどうかを確認してください？",
    QBWASCJMC: "市場価格で売ってください",
    BYLWZJM: "いいよ自分で売るから",
    GBJQRWFCQ:
      "ロボットをシャットダウンすると再起動できません。シャットダウンを確認してください？",
    QX: "キャンセル",
    OK: "確認‘",
    XZCFJQRH: "確認‘？",
    XZTZJQRH:
      "繰り返し実行を選択すると、ロボットは今回の締まり後に目標購入価格で戦略を実行し続け、繰り返し実行するかどうかを決定する？",
    ZTJQRH:
      "ロボットを一時停止した後、システムは購入も販売もしません。本当にロボットを一時停止しますか？",
    QDJQRH:
      "ロボットを起動した後、システムは買い売りを再開します。本当にロボットを起動しますか？",
    KQMRH:
      "購入をオンにすると、システムは自動的に購入と販売を行います。本当に購入をオンにしますか？",
    ZTMRH:
      "購入を一時停止しても、自動的には購入されませんが、販売には影響しません。購入を一時停止しますか？",

    // api授权
    APISQ: "APIライセンス",
    QSRKEY: "取引所から作成したAPI KEYとAPI SECRETを入力してください",
    CHAPIJC: "APIチュートリアルの作成",
    OK: "確認",
    APITS:
      "APIの承認が完了するまでポリシーを開始できません。まず対応する取引所に行ってAPIを作成し、このページでバインディングの承認を完了してください",
    QSQ: "権限解除",
    // 选择币种
    XZBZ: "通貨の選択",
    SSBZ: "通貨の検索",
    ZX: "じせん",
    QB: "すべて",
    ZWZX: "しばらく自由選択なし",
    // 历史机器人
    MD: "マーティン",
    WG: "グリッド",
    DT: "定投",
    HY: "契約",
    // 选择交易所
    XZJYS: "取引所の選択",
    XZJYSSM:
      "ポリシーを開始するためにバインドが許可されている取引所の1つを選択します」",
    JYS: "取引所 ",
  },
  // 创建机器人
  CHUANGJIAN: {
    // 定投
    ZYJBDXYXJ: "締切価格は現在価格を下回ってはならない",
    ZXTZ: "最小投資",
    ZYBLBDXY: "締まり率は以下の値にしてはならない",
    ZTZEBDXY: "総投資額は",
    XHCZBLZX: "サイクルリセットの最小比率は0.5%",
    CJDTJQR: "固定投影ロボットの作成",
    SZ: "設定",
    YSJMR: "市価で買う",
    YXJMR: "指し値で買う",
    MRJG: "買入価格",
    ZSMJGMR: "どんな値段で買うか",
    ZYJ: "しまりか",
    JGDDDSSMC: "値段がいくらになったときに売る",
    ZYBL: "しまりひ",
    ZDYLDSSMC: "最低採算で売る",
    ZHU: "注意:締り率に達した後は直接販売されず、コールバック時に販売される",
    ZTZE: "総投資額",
    ZXTZ: "最小投資",
    KYZJ: "使用可能資金:",
    QRCS: "確認パラメータ",
    ZSBL: "ストップスケール",
    SR0BZDZS: "0を入力すると自動停止は行われません",
    HTMRBL: "コールバック購入率",
    JGDDZDDDSSMR: "価格が最低値に達したときにどれだけ買い戻すか",
    HTMCBL: "コールバック売り比率",
    JGDDZGDDSSMC: "価格が最高値に達したときにどれだけ売れるか",
    XHCZBL: "サイクルスケール（Cycle Reset Scale）",
    XHSMRJGXTBL: "循環時買入価格引き下げ率",
    FQ: "放り出す",
    QRSZ: "設定の確認",
    // 马丁
    ZXTZEW: "最小投資額は",
    ZDSLQJW: "受注数量区間は次のとおりです",
    BCBLQJ: "在庫補充比例区間",
    BCZFBLQJW: "倉庫補充増加率の区間は以下の通りである",
    ZYBLQJW: "締まり率区間は次のとおりです",
    BCBSQJW: "倉庫補充倍数区間は",
    CJMDJQR: "マーティンロボットの作成",
    GQQRNH: "過去七日年化",
    AICL: "AI戦略",
    DCZDTXCS: "このオートフィルパラメータをクリックします",
    SYAICL: "AIポリシーの使用",
    SDSZ: "手動設定",
    ZDSL: "注文数量",
    ZDXXBCCS: "倉庫を下向きに補充する最大回数",
    BCBL: "在庫補充比率",
    MCBCDBL: "1回の在庫補充の割合",
    BCZFBL: "倉庫補充増加率",
    ZBCBLSDZF: "倉庫補充比率の増加幅",
    ZYBL: "しまりひ",
    YJMCSDYLBL: "売却時の予想利益率",
    MCZF: "つどぞうふく",
    BCBS: "在庫補充倍数",
    // 网格
    WGS: "メッシュ数（Mesh Number）",
    QJJGBHF: "区間価格非合法",
    MGZXLRW: "1マス当たりの利益の最小値は",
    CJWWGJQR: "メッシュロボットを作成する",
    NRW: "あなたが思うのは",
    ZDJ: "安値",
    ZGJ: "最高値",
    QJSXJG: "ゾーン上限価格",
    QJXXJG: "かげんいきかかく",
    WGSL: "メッシュの数",
    MGLR: "格当たり利益:",
    JGFW: "価格帯",
    HD: "すべりてん",
    // ---启动
    XDZ: "注文中",
    CZDTJQR: "固定投影ロボットの作成",
    DQJG: "カレントプライス",
    SJMRJSM: "実際の購入価格は取引所から戻ってきた成約平均価格を基準とする",
    ZDSHCJ: "どれくらい上がってから取引が成立するか",
    MCJG: "うりね",
    YJZTZE: "予想総投資額",
    KYYE: "可用余额",
    QDJQR: "スタートロボット",
    CJMDJQR: "マーティンロボットの作成",
    JG: "価格",
    TZE: "投資額",
    SL: "数量",
    CJWGJQR: "メッシュロボットを作成する",
    WGQJ: "グリッド区間",
  },
  // 机器人详情
  XIANGQING: {
    GDXQ: "注文書の詳細",
    JYJL: "取引記録",
    JQRCS: "パラメータ",
    DTJQRXQ: "定投ロボットの詳細",
    DQJG: "カレントプライス",
    BUY: "買い入れる",
    SELL: "うりさばく",
    CCSL: "持ち高",
    CCJJ: "在庫平均価格",
    JQRZT: "ロボット状態",
    DDMR: "買い待ち",
    JHMRJ: "計画購入価格",
    ZDMRJ: "最低購入価格",
    JQRZT: "ロボット状態",
    DDMC: "売り待ち",
    SJMRJ: "じっこう価格",
    MBZYJ: "目標締切価格",
    ZGMCJ: "さいこううりね",
    ZSJ: "ていしそんか",
    DQJGGYMR:
      "現在の価格が最低購入価格を上回り、購入予定価格を下回った場合に購入する",
    DQJGDYMC:
      "現在の価格が目標締り価格を上回り、かつ、最高売却価格を下回る場合に締り売りをすること。 時価がストップセールを下回ったときストップセールをする",
    JQRYBTZ: "ロボットは停止しました",
    JXJQRBKCK: "ミラーロボットは表示できません",
    XXTLCS: "24H裁定回数",
    ZTLCS: "総裁定回数",
    RJCS: "1日平均回数",
    TLJL: "裁定取引記録",
    DTJQRWCYCMRMC:
      "定投ロボットが1回の購入と対応販売を完了した後、1回の裁定と見なす",
    MDJQRWCYCMRMC:
      "マーティンロボットは1回の買いと対応売りを完了した後、1回の裁定と見なす",
    BDJQRWCYCMRMC:
      "マーティンロボットは1回の買いと対応売りを完了した後、1回の裁定と見なす",
    WGJQRWCYCMRMC:
      "グリッドロボットは、1回の購入とそれに対応する販売を完了した後、1回の裁定取引と見なす",
    HYJQRWCYCMRMC:
      "契約ロボットが購入とそれに対応する販売を完了した後、それは裁定取引と見なされます",
    ZLR: "総利益",
    CJJJ: "平均成約価格",
    CJL: "出来高",
    CJE: "出来高",
    DTCLCS: "ていとうせんりゃくパラメータ",
    MDCLCS: "マーティンポリシーパラメータ",
    WGCLCS: "グリッド・ポリシー・パラメータ",
    JYBD: "取引の対象",
    ZTZE: "総投資額",
    ZYBL: "しまりひ",
    JHMRJG: "購入予定価格",
    HTMRBL: "コールバック購入比率",
    HTMCBL: "コールバック売り比率",
    ZSBL: "ストップスケール",
    XHCZBL: "サイクルスケール（Cycle Reset Scale）",
    //
    MDJQRXQ: "マーティンロボットの詳細",
    JG: "価格",
    TZE: "投資額",
    SL: "数量",
    YMR: "購入済み",
    DHT: "コールバックする",
    SDTZE: "最初の投資額",
    JHZD: "計画発注",
    BCBL: "在庫補充比率",
    BCZF: "倉庫補充増加率",
    BCBS: "在庫補充倍数",
    //
    WGJQRXQ: "グリッドロボットの詳細",
    MRJG: "買入価格",
    ZDSHCJ: "どれくらい上がってから取引が成立するか",
    MCJG: "うりね",
    JCSMRSL: "倉庫建立時買入数量",
    JCSMRJJ: "倉庫建立時買入平均価格",
    QJSXJG: "区間上限価格",
    QJXXJG: "下限区間価格",
    WGSL: "メッシュの数",
    MGLR: "格当たり利益",
    HD: "すべりてん",
    MGMMSL: "すべりてん",
  },
  ABOUTUS: {
    GYWM: "私たちに関しては",
    SM: `
        <p>Tradease Quantitative Robotは、Tradease Technologyが立ち上げた暗号通貨取引プラットフォーム全体のサードパーティ取引戦略ソフトウェアであり、ユーザーが自由に構成可能な定量戦略を通じて取引裁定取引を自動化するのに役立ちます。</p >
        <p>このプラットフォームは、グリッド取引戦略/マーティン取引戦略/固定投資取引戦略などのさまざまな豊富な機能を含むがこれらに限定されない、ユーザーにサービスを提供できる定量的ツールを革新および深化させ続け、ユーザーが凝視することなく簡単に利益を上げることができるようにします市場で。</p >
        <p>信頼していただきありがとうございます。コメントや提案がある場合は、作業指示システムを介してカスタマーサービスに送信するか、WeChatおよびWeiboでお問い合わせください。</p >
        <p></p >
        <p></p >
        `,
  },
};
