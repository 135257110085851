// 常用数据验证规则
export const REG = {
  // MOBILE: /^[0-9]{7,16}$/,
  // MOBILE: /^1\d{10}$/, // 以1开头的11位数字
  MOBILE: /^\d{5,}$/,
  EMAIL: /^[\w-.]+@([\w-]+\.)+[a-zA-Z]+$/,
  SMSCODE: /^[0-9]{0,6}$/,
  PASSWORD: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,}$/, // 至少8 位英文和数字（英文数字都要有，忽略大小写）
  NUMBER: /^-?\d+(.\d+)?$/,
  IDCARD: /^[a-z0-9A-Z]+$/,
  // NICKNAME: /^[0-9a-zA-Z\u4e00-\u9fa5_]{1,10}$/,//昵称验证
  NICKNAME: /^[0-9a-zA-Z]{1,10}$/,//昵称验证
  CELUENAME: /^[0-9a-zA-Z\u4e00-\u9fa5_]{1,10}$/,//策略昵称验证
  // NICKNAME: /^\w{1,10}$/,//昵称验证
  CONTRACTNAME: /^\w+$/,//新服名称
  HASH:/^1x[a-zA-Z0-9]{64}$/,//hash
  ADDRESS:/^1x[a-zA-Z0-9]{40}$/,//地址
  BLOCK:/^-?\d+$/,//整数
  // CONTRACTNAME: /^[0-9a-zA-Z\u4e00-\u9fa5_]{1,100}$/,
}

// 网易验证码 ID
export const CAPTCHA_ID = '659ba10af0634d2d9451f90fdcadb336'

export const COUNT_DOWN = 60 // 倒计时
export const COUNT_DOWN_XD = 5 // 倒计时

export const TOAST_DURATION = 0.8 // 提示框出现时间1.9s

export const USDT_POINT_LENGTH = 5 // USDT 价格和数量保留数点后8位

export const COIN_POINT_LENGTH = 4 // COIN 价格和数量保留数点后4位

export const SPECIAL_POINT_LENGTH = 5 // 特价算力保留数点后4位

export const COUNTRIES_LIST = [
  'China中国',
  'Hong Kong香港',
  'Taiwan台湾',
  'Macao澳门',
  'United States of America (USA)美国',
  'Argentina阿根廷',
  'Andorra安道尔',
  'United Arab Emirates阿联酋',
  'Afghanistan阿富汗',
  'Antigua & Barbuda安提瓜和巴布达',
  'Anguilla安圭拉',
  'Albania阿尔巴尼亚',
  'Armenia亚美尼亚',
  'Angola安哥拉',
  'Antarctica南极洲',
  'American Samoa美属萨摩亚',
  'Austria奥地利',
  'Australia澳大利亚',
  'Aruba阿鲁巴',
  'Aland Island奥兰群岛',
  'Azerbaijan阿塞拜疆',
  'Bosnia & Herzegovina波黑',
  'Barbados巴巴多斯',
  'Bangladesh孟加拉',
  'Belgium比利时',
  'Burkina布基纳法索',
  'Bulgaria保加利亚',
  'Bahrain巴林',
  'Burundi布隆迪',
  'Benin贝宁',
  'Saint Barthélemy圣巴泰勒米岛',
  'Bermuda百慕大',
  'Brunei文莱',
  'Bolivia玻利维亚',
  'Caribbean Netherlands荷兰加勒比区',
  'Brazil巴西',
  'The Bahamas巴哈马',
  'Bhutan不丹',
  'Bouvet Island布韦岛',
  'Botswana博茨瓦纳',
  'Belarus白俄罗斯',
  'Belize伯利兹',
  'Canada加拿大',
  'Cocos (Keeling) Islands科科斯群岛',
  'Democratic Republic of the Congo刚果（金）',
  'Central African Republic中非',
  'Republic of the Congo刚果（布）',
  'Switzerland瑞士',
  "Cote d'Ivoire科特迪瓦",
  'Cook Islands库克群岛',
  'Chile智利',
  'Cameroon喀麦隆',
  'Colombia哥伦比亚',
  'Costa Rica哥斯达黎加',
  'Cuba古巴',
  'Cape Verde佛得角',
  'Curacao库拉索',
  'Christmas Island圣诞岛',
  'Cyprus塞浦路斯',
  'Czech Republic捷克',
  'Germany德国',
  'Djibouti吉布提',
  'Denmark丹麦',
  'Dominica多米尼克',
  'Dominican Republic多米尼加',
  'Algeria阿尔及利亚',
  'Ecuador厄瓜多尔',
  'Estonia爱沙尼亚',
  'Egypt埃及',
  'Western Sahara西撒哈拉',
  'Eritrea厄立特里亚',
  'Spain西班牙',
  'Ethiopia埃塞俄比亚',
  'Finland芬兰',
  'Fiji斐济群岛',
  'Falkland Islands马尔维纳斯群岛（福克兰）',
  'Federated States of Micronesia密克罗尼西亚联邦',
  'Faroe Islands法罗群岛',
  'France法国 法国',
  'Gabon加蓬',
  'Great Britain (United Kingdom; England)英国',
  'Grenada格林纳达',
  'Georgia格鲁吉亚',
  'French Guiana法属圭亚那',
  'Guernsey根西岛',
  'Ghana加纳',
  'Gibraltar直布罗陀',
  'Greenland格陵兰',
  'Gambia冈比亚',
  'Guinea几内亚',
  'Guadeloupe瓜德罗普',
  'Equatorial Guinea赤道几内亚',
  'Greece希腊',
  'South Georgia and the South Sandwich Islands南乔治亚岛和南桑威奇群岛',
  'Guatemala危地马拉',
  'Guam关岛',
  'Guinea-Bissau几内亚比绍',
  'Guyana圭亚那',
  'Heard Island and McDonald Islands赫德岛和麦克唐纳群岛',
  'Honduras洪都拉斯',
  'Croatia克罗地亚',
  'Haiti海地',
  'Hungary匈牙利',
  'Indonesia印尼',
  'Ireland爱尔兰',
  'Israel以色列',
  'Isle of Man马恩岛',
  'India印度',
  'British Indian Ocean Territory英属印度洋领地',
  'Iraq伊拉克',
  'Iran伊朗',
  'Iceland冰岛',
  'Italy意大利',
  'Jersey泽西岛',
  'Jamaica牙买加',
  'Jordan约旦',
  'Japan日本',
  'Kenya肯尼亚',
  'Kyrgyzstan吉尔吉斯斯坦',
  'Cambodia柬埔寨',
  'Kiribati基里巴斯',
  'The Comoros科摩罗',
  'St. Kitts & Nevis圣基茨和尼维斯',
  'North Korea朝鲜',
  'South Korea韩国',
  'Kuwait科威特',
  'Cayman Islands开曼群岛',
  'Kazakhstan哈萨克斯坦',
  'Laos老挝',
  'Lebanon黎巴嫩',
  'St. Lucia圣卢西亚',
  'Liechtenstein列支敦士登',
  'Sri Lanka斯里兰卡',
  'Liberia利比里亚',
  'Lesotho莱索托',
  'Lithuania立陶宛',
  'Luxembourg卢森堡',
  'Latvia拉脱维亚',
  'Libya利比亚',
  'Morocco摩洛哥',
  'Monaco摩纳哥',
  'Moldova摩尔多瓦',
  'Montenegro黑山',
  'Saint Martin (France)法属圣马丁',
  'Madagascar马达加斯加',
  'Marshall islands马绍尔群岛',
  'Republic of Macedonia (FYROM)马其顿',
  'Mali马里',
  'Myanmar (Burma)缅甸',
  'Mongolia蒙古国',
  'Northern Mariana Islands北马里亚纳群岛',
  'Martinique马提尼克',
  'Mauritania毛里塔尼亚',
  'Montserrat蒙塞拉特岛',
  'Malta马耳他',
  'Mauritius毛里求斯',
  'Maldives马尔代夫',
  'Malawi马拉维',
  'Mexico墨西哥',
  'Malaysia马来西亚',
  'Mozambique莫桑比克',
  'Namibia纳米比亚',
  'New Caledonia新喀里多尼亚',
  'Niger尼日尔',
  'Norfolk Island诺福克岛',
  'Nigeria尼日利亚',
  'Nicaragua尼加拉瓜',
  'Netherlands荷兰',
  'Norway挪威',
  'Nepal尼泊尔',
  'Nauru瑙鲁',
  'Niue纽埃',
  'New Zealand新西兰',
  'Oman阿曼',
  'Panama巴拿马',
  'Peru秘鲁',
  'French polynesia法属波利尼西亚',
  'Papua New Guinea巴布亚新几内亚',
  'The Philippines菲律宾',
  'Pakistan巴基斯坦',
  'Poland波兰',
  'Saint-Pierre and Miquelon圣皮埃尔和密克隆',
  'Pitcairn Islands皮特凯恩群岛',
  'Puerto Rico波多黎各',
  'Palestinian territories巴勒斯坦',
  'Portugal葡萄牙',
  'Palau帕劳',
  'Paraguay巴拉圭',
  'Qatar卡塔尔',
  'Réunion留尼汪',
  'Romania罗马尼亚',
  'Serbia塞尔维亚',
  'Russian Federation俄罗斯',
  'Rwanda卢旺达',
  'Saudi Arabia沙特阿拉伯',
  'Solomon Islands所罗门群岛',
  'Seychelles塞舌尔',
  'Sudan苏丹',
  'Sweden瑞典',
  'Singapore新加坡',
  'St. Helena & Dependencies圣赫勒拿',
  'Slovenia斯洛文尼亚',
  'Svalbard and Jan Mayen斯瓦尔巴群岛和扬马延岛',
  'Slovakia斯洛伐克',
  'Sierra Leone塞拉利昂',
  'San Marino圣马力诺',
  'Senegal塞内加尔',
  'Somalia索马里',
  'Suriname苏里南',
  'South Sudan南苏丹',
  'Sao Tome & Principe圣多美和普林西比',
  'El Salvador萨尔瓦多',
  'Sint Maarten荷属圣马丁',
  'Syria叙利亚',
  'Swaziland斯威士兰',
  'Turks & Caicos Islands特克斯和凯科斯群岛',
  'Chad乍得',
  'French Southern Territories法属南部领地',
  'Togo多哥',
  'Thailand泰国',
  'Tajikistan塔吉克斯坦',
  'Tokelau托克劳',
  'Timor-Leste (East Timor)东帝汶',
  'Turkmenistan土库曼斯坦',
  'Tunisia突尼斯',
  'Tonga汤加',
  'Turkey土耳其',
  'Trinidad & Tobago特立尼达和多巴哥',
  'Tuvalu图瓦卢',
  'Tanzania坦桑尼亚',
  'Ukraine乌克兰',
  'Uganda乌干达',
  'United States Minor Outlying Islands美国本土外小岛屿',
  'Uruguay乌拉圭',
  'Uzbekistan乌兹别克斯坦',
  'Vatican City (The Holy See)梵蒂冈',
  'St. Vincent & the Grenadines圣文森特和格林纳丁斯',
  'Venezuela委内瑞拉',
  'British Virgin Islands英属维尔京群岛',
  'United States Virgin Islands美属维尔京群岛',
  'Vietnam越南',
  'Vanuatu瓦努阿图',
  'Wallis and Futuna瓦利斯和富图纳',
  'Samoa萨摩亚',
  'Yemen也门',
  'Mayotte马约特',
  'South Africa南非',
  'Zambia赞比亚',
  'Zimbabwe津巴布韦'
]

export const globalCountries = [
  { code: 'HK', en: 'Hong Kong', cn: '香港' },
  { code: 'TW', en: 'Taiwan', cn: '台湾' },
  { code: 'MO', en: 'Macao', cn: '澳门' },
  { code: 'US', en: 'United States of America (USA)', cn: '美国' },
  { code: 'AR', en: 'Argentina', cn: '阿根廷' },
  { code: 'AD', en: 'Andorra', cn: '安道尔' },
  { code: 'AE', en: 'United Arab Emirates', cn: '阿联酋' },
  { code: 'AF', en: 'Afghanistan', cn: '阿富汗' },
  { code: 'AG', en: 'Antigua & Barbuda', cn: '安提瓜和巴布达' },
  { code: 'AI', en: 'Anguilla', cn: '安圭拉' },
  { code: 'AL', en: 'Albania', cn: '阿尔巴尼亚' },
  { code: 'AM', en: 'Armenia', cn: '亚美尼亚' },
  { code: 'AO', en: 'Angola', cn: '安哥拉' },
  { code: 'AQ', en: 'Antarctica', cn: '南极洲' },
  { code: 'AS', en: 'American Samoa', cn: '美属萨摩亚' },
  { code: 'AT', en: 'Austria', cn: '奥地利' },
  { code: 'AU', en: 'Australia', cn: '澳大利亚' },
  { code: 'AW', en: 'Aruba', cn: '阿鲁巴' },
  { code: 'AX', en: 'Aland Island', cn: '奥兰群岛' },
  { code: 'AZ', en: 'Azerbaijan', cn: '阿塞拜疆' },
  { code: 'BA', en: 'Bosnia & Herzegovina', cn: '波黑' },
  { code: 'BB', en: 'Barbados', cn: '巴巴多斯' },
  { code: 'BD', en: 'Bangladesh', cn: '孟加拉' },
  { code: 'BE', en: 'Belgium', cn: '比利时' },
  { code: 'BF', en: 'Burkina', cn: '布基纳法索' },
  { code: 'BG', en: 'Bulgaria', cn: '保加利亚' },
  { code: 'BH', en: 'Bahrain', cn: '巴林' },
  { code: 'BI', en: 'Burundi', cn: '布隆迪' },
  { code: 'BJ', en: 'Benin', cn: '贝宁' },
  { code: 'BL', en: 'Saint Barthélemy', cn: '圣巴泰勒米岛' },
  { code: 'BM', en: 'Bermuda', cn: '百慕大' },
  { code: 'BN', en: 'Brunei', cn: '文莱' },
  { code: 'BO', en: 'Bolivia', cn: '玻利维亚' },
  { code: 'BQ', en: 'Caribbean Netherlands', cn: '荷兰加勒比区' },
  { code: 'BR', en: 'Brazil', cn: '巴西' },
  { code: 'BS', en: 'The Bahamas', cn: '巴哈马' },
  { code: 'BT', en: 'Bhutan', cn: '不丹' },
  { code: 'BV', en: 'Bouvet Island', cn: '布韦岛' },
  { code: 'BW', en: 'Botswana', cn: '博茨瓦纳' },
  { code: 'BY', en: 'Belarus', cn: '白俄罗斯' },
  { code: 'BZ', en: 'Belize', cn: '伯利兹' },
  { code: 'CA', en: 'Canada', cn: '加拿大' },
  { code: 'CC', en: 'Cocos (Keeling) Islands', cn: '科科斯群岛' },
  { code: 'CD', en: 'Democratic Republic of the Congo', cn: '刚果（金）' },
  { code: 'CF', en: 'Central African Republic', cn: '中非' },
  { code: 'CG', en: 'Republic of the Congo', cn: '刚果（布）' },
  { code: 'CH', en: 'Switzerland', cn: '瑞士' },
  { code: 'CI', en: "Cote d'Ivoire", cn: '科特迪瓦' },
  { code: 'CK', en: 'Cook Islands', cn: '库克群岛' },
  { code: 'CL', en: 'Chile', cn: '智利' },
  { code: 'CM', en: 'Cameroon', cn: '喀麦隆' },
  { code: 'CN', en: 'China', cn: '中国' },
  { code: 'CO', en: 'Colombia', cn: '哥伦比亚' },
  { code: 'CR', en: 'Costa Rica', cn: '哥斯达黎加' },
  { code: 'CU', en: 'Cuba', cn: '古巴' },
  { code: 'CV', en: 'Cape Verde', cn: '佛得角' },
  { code: 'CW', en: 'Curacao', cn: '库拉索' },
  { code: 'CX', en: 'Christmas Island', cn: '圣诞岛' },
  { code: 'CY', en: 'Cyprus', cn: '塞浦路斯' },
  { code: 'CZ', en: 'Czech Republic', cn: '捷克' },
  { code: 'DE', en: 'Germany', cn: '德国' },
  { code: 'DJ', en: 'Djibouti', cn: '吉布提' },
  { code: 'DK', en: 'Denmark', cn: '丹麦' },
  { code: 'DM', en: 'Dominica', cn: '多米尼克' },
  { code: 'DO', en: 'Dominican Republic', cn: '多米尼加' },
  { code: 'DZ', en: 'Algeria', cn: '阿尔及利亚' },
  { code: 'EC', en: 'Ecuador', cn: '厄瓜多尔' },
  { code: 'EE', en: 'Estonia', cn: '爱沙尼亚' },
  { code: 'EG', en: 'Egypt', cn: '埃及' },
  { code: 'EH', en: 'Western Sahara', cn: '西撒哈拉' },
  { code: 'ER', en: 'Eritrea', cn: '厄立特里亚' },
  { code: 'ES', en: 'Spain', cn: '西班牙' },
  { code: 'ET', en: 'Ethiopia', cn: '埃塞俄比亚' },
  { code: 'FI', en: 'Finland', cn: '芬兰' },
  { code: 'FJ', en: 'Fiji', cn: '斐济群岛' },
  { code: 'FK', en: 'Falkland Islands', cn: '马尔维纳斯群岛（福克兰）' },
  { code: 'FM', en: 'Federated States of Micronesia', cn: '密克罗尼西亚联邦' },
  { code: 'FO', en: 'Faroe Islands', cn: '法罗群岛' },
  { code: 'FR', en: 'France', cn: '法国 法国' },
  { code: 'GA', en: 'Gabon', cn: '加蓬' },
  { code: 'GB', en: 'Great Britain (United Kingdom; England)', cn: '英国' },
  { code: 'GD', en: 'Grenada', cn: '格林纳达' },
  { code: 'GE', en: 'Georgia', cn: '格鲁吉亚' },
  { code: 'GF', en: 'French Guiana', cn: '法属圭亚那' },
  { code: 'GG', en: 'Guernsey', cn: '根西岛' },
  { code: 'GH', en: 'Ghana', cn: '加纳' },
  { code: 'GI', en: 'Gibraltar', cn: '直布罗陀' },
  { code: 'GL', en: 'Greenland', cn: '格陵兰' },
  { code: 'GM', en: 'Gambia', cn: '冈比亚' },
  { code: 'GN', en: 'Guinea', cn: '几内亚' },
  { code: 'GP', en: 'Guadeloupe', cn: '瓜德罗普' },
  { code: 'GQ', en: 'Equatorial Guinea', cn: '赤道几内亚' },
  { code: 'GR', en: 'Greece', cn: '希腊' },
  {
    code: 'GS',
    en: 'South Georgia and the South Sandwich Islands',
    cn: '南乔治亚岛和南桑威奇群岛'
  },
  { code: 'GT', en: 'Guatemala', cn: '危地马拉' },
  { code: 'GU', en: 'Guam', cn: '关岛' },
  { code: 'GW', en: 'Guinea-Bissau', cn: '几内亚比绍' },
  { code: 'GY', en: 'Guyana', cn: '圭亚那' },
  {
    code: 'HM',
    en: 'Heard Island and McDonald Islands',
    cn: '赫德岛和麦克唐纳群岛'
  },
  { code: 'HN', en: 'Honduras', cn: '洪都拉斯' },
  { code: 'HR', en: 'Croatia', cn: '克罗地亚' },
  { code: 'HT', en: 'Haiti', cn: '海地' },
  { code: 'HU', en: 'Hungary', cn: '匈牙利' },
  { code: 'ID', en: 'Indonesia', cn: '印尼' },
  { code: 'IE', en: 'Ireland', cn: '爱尔兰' },
  { code: 'IL', en: 'Israel', cn: '以色列' },
  { code: 'IM', en: 'Isle of Man', cn: '马恩岛' },
  { code: 'IN', en: 'India', cn: '印度' },
  { code: 'IO', en: 'British Indian Ocean Territory', cn: '英属印度洋领地' },
  { code: 'IQ', en: 'Iraq', cn: '伊拉克' },
  { code: 'IR', en: 'Iran', cn: '伊朗' },
  { code: 'IS', en: 'Iceland', cn: '冰岛' },
  { code: 'IT', en: 'Italy', cn: '意大利' },
  { code: 'JE', en: 'Jersey', cn: '泽西岛' },
  { code: 'JM', en: 'Jamaica', cn: '牙买加' },
  { code: 'JO', en: 'Jordan', cn: '约旦' },
  { code: 'JP', en: 'Japan', cn: '日本' },
  { code: 'KE', en: 'Kenya', cn: '肯尼亚' },
  { code: 'KG', en: 'Kyrgyzstan', cn: '吉尔吉斯斯坦' },
  { code: 'KH', en: 'Cambodia', cn: '柬埔寨' },
  { code: 'KI', en: 'Kiribati', cn: '基里巴斯' },
  { code: 'KM', en: 'The Comoros', cn: '科摩罗' },
  { code: 'KN', en: 'St. Kitts & Nevis', cn: '圣基茨和尼维斯' },
  { code: 'KP', en: 'North Korea', cn: '朝鲜' },
  { code: 'KR', en: 'South Korea', cn: '韩国' },
  { code: 'KW', en: 'Kuwait', cn: '科威特' },
  { code: 'KY', en: 'Cayman Islands', cn: '开曼群岛' },
  { code: 'KZ', en: 'Kazakhstan', cn: '哈萨克斯坦' },
  { code: 'LA', en: 'Laos', cn: '老挝' },
  { code: 'LB', en: 'Lebanon', cn: '黎巴嫩' },
  { code: 'LC', en: 'St. Lucia', cn: '圣卢西亚' },
  { code: 'LI', en: 'Liechtenstein', cn: '列支敦士登' },
  { code: 'LK', en: 'Sri Lanka', cn: '斯里兰卡' },
  { code: 'LR', en: 'Liberia', cn: '利比里亚' },
  { code: 'LS', en: 'Lesotho', cn: '莱索托' },
  { code: 'LT', en: 'Lithuania', cn: '立陶宛' },
  { code: 'LU', en: 'Luxembourg', cn: '卢森堡' },
  { code: 'LV', en: 'Latvia', cn: '拉脱维亚' },
  { code: 'LY', en: 'Libya', cn: '利比亚' },
  { code: 'MA', en: 'Morocco', cn: '摩洛哥' },
  { code: 'MC', en: 'Monaco', cn: '摩纳哥' },
  { code: 'MD', en: 'Moldova', cn: '摩尔多瓦' },
  { code: 'ME', en: 'Montenegro', cn: '黑山' },
  { code: 'MF', en: 'Saint Martin (France)', cn: '法属圣马丁' },
  { code: 'MG', en: 'Madagascar', cn: '马达加斯加' },
  { code: 'MH', en: 'Marshall islands', cn: '马绍尔群岛' },
  { code: 'MK', en: 'Republic of Macedonia (FYROM)', cn: '马其顿' },
  { code: 'ML', en: 'Mali', cn: '马里' },
  { code: 'MM', en: 'Myanmar (Burma)', cn: '缅甸' },
  { code: 'MN', en: 'Mongolia', cn: '蒙古国' },
  { code: 'MP', en: 'Northern Mariana Islands', cn: '北马里亚纳群岛' },
  { code: 'MQ', en: 'Martinique', cn: '马提尼克' },
  { code: 'MR', en: 'Mauritania', cn: '毛里塔尼亚' },
  { code: 'MS', en: 'Montserrat', cn: '蒙塞拉特岛' },
  { code: 'MT', en: 'Malta', cn: '马耳他' },
  { code: 'MU', en: 'Mauritius', cn: '毛里求斯' },
  { code: 'MV', en: 'Maldives', cn: '马尔代夫' },
  { code: 'MW', en: 'Malawi', cn: '马拉维' },
  { code: 'MX', en: 'Mexico', cn: '墨西哥' },
  { code: 'MY', en: 'Malaysia', cn: '马来西亚' },
  { code: 'MZ', en: 'Mozambique', cn: '莫桑比克' },
  { code: 'NA', en: 'Namibia', cn: '纳米比亚' },
  { code: 'NC', en: 'New Caledonia', cn: '新喀里多尼亚' },
  { code: 'NE', en: 'Niger', cn: '尼日尔' },
  { code: 'NF', en: 'Norfolk Island', cn: '诺福克岛' },
  { code: 'NG', en: 'Nigeria', cn: '尼日利亚' },
  { code: 'NI', en: 'Nicaragua', cn: '尼加拉瓜' },
  { code: 'NL', en: 'Netherlands', cn: '荷兰' },
  { code: 'NO', en: 'Norway', cn: '挪威' },
  { code: 'NP', en: 'Nepal', cn: '尼泊尔' },
  { code: 'NR', en: 'Nauru', cn: '瑙鲁' },
  { code: 'NU', en: 'Niue', cn: '纽埃' },
  { code: 'NZ', en: 'New Zealand', cn: '新西兰' },
  { code: 'OM', en: 'Oman', cn: '阿曼' },
  { code: 'PA', en: 'Panama', cn: '巴拿马' },
  { code: 'PE', en: 'Peru', cn: '秘鲁' },
  { code: 'PF', en: 'French polynesia', cn: '法属波利尼西亚' },
  { code: 'PG', en: 'Papua New Guinea', cn: '巴布亚新几内亚' },
  { code: 'PH', en: 'The Philippines', cn: '菲律宾' },
  { code: 'PK', en: 'Pakistan', cn: '巴基斯坦' },
  { code: 'PL', en: 'Poland', cn: '波兰' },
  { code: 'PM', en: 'Saint-Pierre and Miquelon', cn: '圣皮埃尔和密克隆' },
  { code: 'PN', en: 'Pitcairn Islands', cn: '皮特凯恩群岛' },
  { code: 'PR', en: 'Puerto Rico', cn: '波多黎各' },
  { code: 'PS', en: 'Palestinian territories', cn: '巴勒斯坦' },
  { code: 'PT', en: 'Portugal', cn: '葡萄牙' },
  { code: 'PW', en: 'Palau', cn: '帕劳' },
  { code: 'PY', en: 'Paraguay', cn: '巴拉圭' },
  { code: 'QA', en: 'Qatar', cn: '卡塔尔' },
  { code: 'RE', en: 'Réunion', cn: '留尼汪' },
  { code: 'RO', en: 'Romania', cn: '罗马尼亚' },
  { code: 'RS', en: 'Serbia', cn: '塞尔维亚' },
  { code: 'RU', en: 'Russian Federation', cn: '俄罗斯' },
  { code: 'RW', en: 'Rwanda', cn: '卢旺达' },
  { code: 'SA', en: 'Saudi Arabia', cn: '沙特阿拉伯' },
  { code: 'SB', en: 'Solomon Islands', cn: '所罗门群岛' },
  { code: 'SC', en: 'Seychelles', cn: '塞舌尔' },
  { code: 'SD', en: 'Sudan', cn: '苏丹' },
  { code: 'SE', en: 'Sweden', cn: '瑞典' },
  { code: 'SG', en: 'Singapore', cn: '新加坡' },
  { code: 'SH', en: 'St. Helena & Dependencies', cn: '圣赫勒拿' },
  { code: 'SI', en: 'Slovenia', cn: '斯洛文尼亚' },
  { code: 'SJ', en: 'Svalbard and Jan Mayen', cn: '斯瓦尔巴群岛和扬马延岛' },
  { code: 'SK', en: 'Slovakia', cn: '斯洛伐克' },
  { code: 'SL', en: 'Sierra Leone', cn: '塞拉利昂' },
  { code: 'SM', en: 'San Marino', cn: '圣马力诺' },
  { code: 'SN', en: 'Senegal', cn: '塞内加尔' },
  { code: 'SO', en: 'Somalia', cn: '索马里' },
  { code: 'SR', en: 'Suriname', cn: '苏里南' },
  { code: 'SS', en: 'South Sudan', cn: '南苏丹' },
  { code: 'ST', en: 'Sao Tome & Principe', cn: '圣多美和普林西比' },
  { code: 'SV', en: 'El Salvador', cn: '萨尔瓦多' },
  { code: 'SX', en: 'Sint Maarten', cn: '荷属圣马丁' },
  { code: 'SY', en: 'Syria', cn: '叙利亚' },
  { code: 'SZ', en: 'Swaziland', cn: '斯威士兰' },
  { code: 'TC', en: 'Turks & Caicos Islands', cn: '特克斯和凯科斯群岛' },
  { code: 'TD', en: 'Chad', cn: '乍得' },
  { code: 'TF', en: 'French Southern Territories', cn: '法属南部领地' },
  { code: 'TG', en: 'Togo', cn: '多哥' },
  { code: 'TH', en: 'Thailand', cn: '泰国' },
  { code: 'TJ', en: 'Tajikistan', cn: '塔吉克斯坦' },
  { code: 'TK', en: 'Tokelau', cn: '托克劳' },
  { code: 'TL', en: 'Timor-Leste (East Timor)', cn: '东帝汶' },
  { code: 'TM', en: 'Turkmenistan', cn: '土库曼斯坦' },
  { code: 'TN', en: 'Tunisia', cn: '突尼斯' },
  { code: 'TO', en: 'Tonga', cn: '汤加' },
  { code: 'TR', en: 'Turkey', cn: '土耳其' },
  { code: 'TT', en: 'Trinidad & Tobago', cn: '特立尼达和多巴哥' },
  { code: 'TV', en: 'Tuvalu', cn: '图瓦卢' },
  { code: 'TZ', en: 'Tanzania', cn: '坦桑尼亚' },
  { code: 'UA', en: 'Ukraine', cn: '乌克兰' },
  { code: 'UG', en: 'Uganda', cn: '乌干达' },
  {
    code: 'UM',
    en: 'United States Minor Outlying Islands',
    cn: '美国本土外小岛屿'
  },
  { code: 'UY', en: 'Uruguay', cn: '乌拉圭' },
  { code: 'UZ', en: 'Uzbekistan', cn: '乌兹别克斯坦' },
  { code: 'VA', en: 'Vatican City (The Holy See)', cn: '梵蒂冈' },
  {
    code: 'VC',
    en: 'St. Vincent & the Grenadines',
    cn: '圣文森特和格林纳丁斯'
  },
  { code: 'VE', en: 'Venezuela', cn: '委内瑞拉' },
  { code: 'VG', en: 'British Virgin Islands', cn: '英属维尔京群岛' },
  { code: 'VI', en: 'United States Virgin Islands', cn: '美属维尔京群岛' },
  { code: 'VN', en: 'Vietnam', cn: '越南' },
  { code: 'VU', en: 'Vanuatu', cn: '瓦努阿图' },
  { code: 'WF', en: 'Wallis and Futuna', cn: '瓦利斯和富图纳' },
  { code: 'WS', en: 'Samoa', cn: '萨摩亚' },
  { code: 'YE', en: 'Yemen', cn: '也门' },
  { code: 'YT', en: 'Mayotte', cn: '马约特' },
  { code: 'ZA', en: 'South Africa', cn: '南非' },
  { code: 'ZM', en: 'Zambia', cn: '赞比亚' },
  { code: 'ZW', en: 'Zimbabwe', cn: '津巴布韦' }
]

export const PRECISION = {
  SPECIAL_OFFER: 2,
  OFFER: 4
}
