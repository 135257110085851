// import UserStore from './user'
import UserM4DStore from './userM4D'
// import PersonStore from './person'
import personM4DStore from './personM4D'
import WalletStore from './wallet'
// import CertificationStore from './certification'
import NoticeStore from './notice'
import ProductStore from './product'
import LocaleStore from './locale'
// import WsStore from './wsdata'

class Stores {
  constructor() {
    // this.userStore = new UserStore()
    this.userM4DStore = new UserM4DStore()//m4duser
    // this.personStore = new PersonStore()
    this.personM4DStore = new personM4DStore()
    this.walletStore = new WalletStore()
    // this.CertificationStore = new CertificationStore()
    this.noticeStore = new NoticeStore()
    this.productStore = new ProductStore()
    this.localeStore = new LocaleStore()
    // this.WsStore = new WsStore()
  }
}

export default new Stores()
