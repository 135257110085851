import Loadable from "react-loadable";
import Loading from "./components/common/Loading";

// ---------------------------------------------------------------------------------
// 登录注册

const Login = Loadable({
  loader: () => import("./pages/login/Login"),
  loading: Loading,
});

const Register = Loadable({
  loader: () => import("./pages/login/Register"),
  loading: Loading,
});

const RegisterDownload = Loadable({
  loader: () => import("./pages/login/RegisterDownload"),
  loading: Loading,
});
const Download = Loadable({
  loader: () => import("./pages/login/Download"),
  loading: Loading,
});
const PasswordNew = Loadable({
  loader: () => import("./pages/login/PasswordNew"),
  loading: Loading,
});
const PasswordNewOn = Loadable({
  loader: () => import("./pages/login/PasswordNewOn"),
  loading: Loading,
});
const OTCPassword = Loadable({
  loader: () => import("./pages/login/OTCPassword"),
  loading: Loading,
});
const Agreement = Loadable({
  loader: () => import("./pages/login/Agreement"),
  loading: Loading,
});
const TelPrefixM4D = Loadable({
  loader: () => import("./pages/login/TelPrefixM4D"),
  loading: Loading,
});

// ----------------------首页--------------------------
const Home = Loadable({
  loader: () => import("./pages/home/home"),
  loading: Loading,
});
// 跟单
const GendanWangge = Loadable({
  loader: () => import("./pages/home/GendanWangge"),
  loading: Loading,
});
const GendanDingtou = Loadable({
  loader: () => import("./pages/home/GendanDingtou"),
  loading: Loading,
});
const GendanMdding = Loadable({
  loader: () => import("./pages/home/GendanMdding"),
  loading: Loading,
});
const GendanBuding = Loadable({
  loader: () => import("./pages/home/GendanBuding"),
  loading: Loading,
});
// 资产
const Recharge = Loadable({
  loader: () => import("./pages/home/Recharge"),
  loading: Loading,
});

const Extract = Loadable({
  loader: () => import("./pages/home/Extract"),
  loading: Loading,
});
const Record = Loadable({
  loader: () => import("./pages/home/Record"),
  loading: Loading,
});
const ZhichanRecord = Loadable({
  //全部资产流水
  loader: () => import("./pages/home/ZhichanRecord"),
  loading: Loading,
});
const USDTRecord = Loadable({
  //资产流水
  loader: () => import("./pages/home/USDTRecord"),
  loading: Loading,
});
const JifenRecord = Loadable({
  //积分流水
  loader: () => import("./pages/home/JifenRecord"),
  loading: Loading,
});

const CoinRecord = Loadable({
  //积分流水
  loader: () => import("./pages/home/CoinRecord"),
  loading: Loading,
});
// --------------------------日历----------------------

const riliIndex = Loadable({
  loader: () => import("./pages/rili/riliIndex"),
  loading: Loading,
});
const riliISearch = Loadable({
  loader: () => import("./pages/rili/riliISearch"),
  loading: Loading,
});
// --------------------------面板-----------------------
//面板首页
const mianbanIndex = Loadable({
  loader: () => import("./pages/mianban/mianbanIndex"),
  loading: Loading,
});
// 一键平仓
const oneClikePC = Loadable({
  loader: () => import("./pages/mianban/oneClikePC"),
  loading: Loading,
});
// 资产分析
const fenxi = Loadable({
  loader: () => import("./pages/mianban/fenxi"),
  loading: Loading,
});
// -------------------镜像----------------
const jingxiangIndex = Loadable({
  loader: () => import("./pages/jingxiang/jingxiangIndex"),
  loading: Loading,
});

const XiangqingJx = Loadable({
  loader: () => import("./pages/jingxiang/XiangqingJx"),
  loading: Loading,
});
const YijingJx = Loadable({
  loader: () => import("./pages/jingxiang/YijingJx"),
  loading: Loading,
});
const Xuanren = Loadable({
  loader: () => import("./pages/jingxiang/Xuanren"),
  loading: Loading,
});
// ----------------------------------策略-----------------
const Shouquan = Loadable({
  //
  loader: () => import("./pages/celue/Shouquan"),
  loading: Loading,
});
const ApiShouquan = Loadable({
  //
  loader: () => import("./pages/celue/ApiShouquan"),
  loading: Loading,
});
const Celue = Loadable({
  //
  loader: () => import("./pages/celue/celue"),
  loading: Loading,
});
const CelueHy = Loadable({
  //
  loader: () => import("./pages/celue/celueHy"),
  loading: Loading,
});
const Jiaoyishuo = Loadable({
  //选择交易所
  loader: () => import("./pages/celue/Jiaoyishuo"),
  loading: Loading,
});
const Xuanbi = Loadable({
  //选择bi
  loader: () => import("./pages/celue/Xuanbi"),
  loading: Loading,
});
// const ChuangjianRobot = Loadable({//机器人传参
//   loader: () => import('./pages/celue/ChuangjianRobot'),
//   loading: Loading
// })

const ChuangjianMading = Loadable({
  //创建马丁机器人
  loader: () => import("./pages/celue/ChuangjianMading"),
  loading: Loading,
});
const ChuangjianBuding = Loadable({
  loader: () => import("./pages/celue/ChuangjianBuding"),
  loading: Loading,
});
const ChuangjianBudingHy = Loadable({
  loader: () => import("./pages/celue/ChuangjianBudingHy"),
  loading: Loading,
});
const ChuangjianDingtou = Loadable({
  //创建定投机器人
  loader: () => import("./pages/celue/ChuangjianDingtou"),
  loading: Loading,
});
const ChuangjianQuancang = Loadable({
  //创建满仓机器人
  loader: () => import("./pages/celue/ChuangjianQuancang"),
  loading: Loading,
});
// const ChuangjianZhucang = Loadable({
//   //创建分仓机器人
//   loader: () => import("./pages/celue/ChuangjianZhucang"),
//   loading: Loading,
// });
const ChuangjianHeyue = Loadable({
  //创建合约机器人
  loader: () => import("./pages/celue/ChuangjianHeyue"),
  loading: Loading,
});
const ChuangjianWangge = Loadable({
  //创建网格机器人
  loader: () => import("./pages/celue/ChuangjianWangge"),
  loading: Loading,
});
// const QidongMading = Loadable({//启动马丁机器人
//   loader: () => import('./pages/celue/QidongMading'),
//   loading: Loading
// })
const HistoryRobot = Loadable({
  //历史机器人
  loader: () => import("./pages/celue/HistoryRobot"),
  loading: Loading,
});
const HistoryRobotHy = Loadable({
  //历史机器人
  loader: () => import("./pages/celue/HistoryRobotHy"),
  loading: Loading,
});
const XiangqingRobot = Loadable({
  //机器人详情
  loader: () => import("./pages/celue/XiangqingRobot"),
  loading: Loading,
});
const XiangqingDingtou = Loadable({
  //定投机器人详情
  loader: () => import("./pages/celue/XiangqingDingtou"),
  loading: Loading,
});
const XiangqingDingtouHy = Loadable({
  //定投机器人详情
  loader: () => import("./pages/celue/XiangqingDingtouHy"),
  loading: Loading,
});
const XiangqingHeyue = Loadable({
  //合约机器人详情
  loader: () => import("./pages/celue/XiangqingHeyue"),
  loading: Loading,
});
const XiangqingMading = Loadable({
  //马丁机器人详情
  loader: () => import("./pages/celue/XiangqingMading"),
  loading: Loading,
});
const XiangqingBuding = Loadable({
  //布丁机器人详情
  loader: () => import("./pages/celue/XiangqingBuding"),
  loading: Loading,
});
const XiangqingBudingHy = Loadable({
  //布丁机器人详情
  loader: () => import("./pages/celue/XiangqingBudingHy"),
  loading: Loading,
});
const XiangqingWangge = Loadable({
  //网格机器人详情
  loader: () => import("./pages/celue/XiangqingWangge"),
  loading: Loading,
});
// ------------------社区----------------
const Shequ = Loadable({
  //---
  loader: () => import("./pages/shequ/shequ"),
  loading: Loading,
});
const Ruler = Loadable({
  //
  loader: () => import("./pages/shequ/Ruler"),
  loading: Loading,
});
// ----------------------模拟-------------------
const MoniZh = Loadable({
  //
  loader: () => import("./pages/moni/MoniZh"),
  loading: Loading,
});

// ---------------------------------------------
//-----------------------------------我的---------------------------------
const MineList = Loadable({
  loader: () => import("./pages/user/MineList"),
  loading: Loading,
});
const JxSetting = Loadable({
  loader: () => import("./pages/user/JxSetting"),
  loading: Loading,
});
const BuyVip = Loadable({
  loader: () => import("./pages/user/BuyVip"),
  loading: Loading,
});
const About = Loadable({
  loader: () => import("./pages/user/About"),
  loading: Loading,
});
const FrindsCome = Loadable({
  loader: () => import("./pages/user/FrindsCome"),
  loading: Loading,
});
const Safety = Loadable({
  loader: () => import("./pages/user/Safety"),
  loading: Loading,
});
const Notice = Loadable({
  loader: () => import("./pages/user/Notice"),
  loading: Loading,
});
const NoticeDetail = Loadable({
  loader: () => import("./pages/user/NoticeDetail"),
  loading: Loading,
});
const Help = Loadable({
  loader: () => import("./pages/user/Help"),
  loading: Loading,
});
const HelpDetail = Loadable({
  loader: () => import("./pages/user/HelpDetail"),
  loading: Loading,
});
const LangSwitch = Loadable({
  loader: () => import("./pages/user/LangSwitch"),
  loading: Loading,
});
const CustomerService = Loadable({
  loader: () => import("./pages/user/CustomerService"),
  loading: Loading,
});
const CustomerServiceHistory = Loadable({
  loader: () => import("./pages/user/CustomerServiceHistory"),
  loading: Loading,
});
// -----------X-Robot-----------
const xRobotList = Loadable({
  loader: () => import("./pages/xrobot/xRobotList"),
  loading: Loading,
});
const AddxRobot = Loadable({
  loader: () => import("./pages/xrobot/AddxRobot"),
  loading: Loading,
});
const UpdataxRobot = Loadable({
  loader: () => import("./pages/xrobot/UpdataxRobot"),
  loading: Loading,
});
const xRobotPreview = Loadable({
  loader: () => import("./pages/xrobot/xRobotPreview"),
  loading: Loading,
});
const ChoosexRobot = Loadable({
  loader: () => import("./pages/xrobot/ChoosexRobot"),
  loading: Loading,
});
// const MissionCenter = Loadable({
//   loader: () => import('./pages/user/MissionCenter'),
//   loading: Loading
// })
//规则说明

// 404
const NoMatch = Loadable({
  loader: () => import("./pages/exception/404"),
  loading: Loading,
});

export default [
  // ----------------
  // 登录注册
  { path: "/login", name: "Login", component: Login },
  // {path: '/register/:type', name: 'Register', component: Register},
  { path: "/Register", name: "Register", component: Register },
  {
    path: "/RegisterDownload",
    name: "RegisterDownload",
    component: RegisterDownload,
  },
  { path: "/Download", name: "Download", component: Download },
  { path: "/PasswordNew/:type", name: "PasswordNew", component: PasswordNew },
  { path: "/PasswordNewOn/", name: "PasswordNewOn", component: PasswordNewOn },
  { path: "/OTCPassword", name: "OTCPassword", component: OTCPassword },
  { path: "/telPrefixM4D", name: "TelPrefixM4D", component: TelPrefixM4D },
  { path: "/agreement", name: "Agreement", component: Agreement },
  // 首页-------
  { path: "/home", name: "home", component: Home },
  { path: "/GendanWangge", name: "GendanWangge", component: GendanWangge },
  { path: "/GendanDingtou", name: "GendanDingtou", component: GendanDingtou },
  { path: "/GendanMdding", name: "GendanMdding", component: GendanMdding },
  { path: "/GendanBuding", name: "GendanBuding", component: GendanBuding },
  // 策略
  { path: "/Shouquan", name: "Shouquan", component: Shouquan },
  { path: "/ApiShouquan", name: "ApiShouquan", component: ApiShouquan },
  { path: "/celue", name: "Celue", component: Celue },
  { path: "/celueHy", name: "CelueHy", component: CelueHy },
  { path: "/Jiaoyishuo", name: "Jiaoyishuo", component: Jiaoyishuo },
  { path: "/Xuanbi", name: "Xuanbi", component: Xuanbi },
  {
    path: "/ChuangjianMading",
    name: "ChuangjianMading",
    component: ChuangjianMading,
  },
  {
    path: "/ChuangjianBuding",
    name: "ChuangjianBuding",
    component: ChuangjianBuding,
  },
  {
    path: "/ChuangjianBudingHy",
    name: "ChuangjianBudingHy",
    component: ChuangjianBudingHy,
  },
  {
    path: "/ChuangjianDingtou",
    name: "ChuangjianDingtou",
    component: ChuangjianDingtou,
  },
  {
    path: "/ChuangjianQuancang",
    name: "ChuangjianQuancang",
    component: ChuangjianQuancang,
  },
  {
    path: "/ChuangjianHeyue",
    name: "ChuangjianHeyue",
    component: ChuangjianHeyue,
  },
  {
    path: "/ChuangjianWangge",
    name: "ChuangjianWangge",
    component: ChuangjianWangge,
  },
  // { path: '/QidongMading', name: 'QidongMading', component: QidongMading },
  // { path: '/ChuangjianRobot', name: 'ChuangjianRobot', component: ChuangjianRobot },
  {
    path: "/XiangqingRobot",
    name: "XiangqingRobot",
    component: XiangqingRobot,
  },
  {
    path: "/XiangqingDingtou",
    name: "XiangqingDingtou",
    component: XiangqingDingtou,
  },
  {
    path: "/XiangqingDingtouHy",
    name: "XiangqingDingtouHy",
    component: XiangqingDingtouHy,
  },
  {
    path: "/XiangqingHeyue",
    name: "XiangqingHeyue",
    component: XiangqingHeyue,
  },
  {
    path: "/XiangqingMading",
    name: "XiangqingMading",
    component: XiangqingMading,
  },
  {
    path: "/XiangqingBuding",
    name: "XiangqingBuding",
    component: XiangqingBuding,
  },
  {
    path: "/XiangqingBudingHy",
    name: "XiangqingBudingHy",
    component: XiangqingBudingHy,
  },
  {
    path: "/XiangqingWangge",
    name: "XiangqingWangge",
    component: XiangqingWangge,
  },
  { path: "/HistoryRobot", name: "HistoryRobot", component: HistoryRobot },
  { path: "/HistoryRobotHy", name: "HistoryRobotHy", component: HistoryRobotHy },
  // 面板
  { path: "/mianban", name: "mianban", component: mianbanIndex },
  { path: "/oneClikePC", name: "oneClikePC", component: oneClikePC },
  { path: "/fenxi", name: "fenxi", component: fenxi },
  // 镜像
  { path: "/jingxiang", name: "jingxiangIndex", component: jingxiangIndex },
  { path: "/XiangqingJx", name: "XiangqingJx", component: XiangqingJx },
  { path: "/YijingJx", name: "YijingJx", component: YijingJx },
  { path: "/Xuanren", name: "Xuanren", component: Xuanren },
  // 日历
  { path: "/rili", name: "riliIndex", component: riliIndex },
  { path: "/riliISearch", name: "riliISearch", component: riliISearch },

  // 社区
  { path: "/shequ", name: "shequ", component: Shequ },
  { path: "/Ruler", name: "Ruler", component: Ruler },
  // X-Robot
  { path: "/xRobotList", name: "xRobotList", component: xRobotList },
  { path: "/AddxRobot", name: "AddxRobot", component: AddxRobot },
  { path: "/UpdataxRobot", name: "UpdataxRobot", component: UpdataxRobot },
  { path: "/xRobotPreview", name: "xRobotPreview", component: xRobotPreview },
  { path: "/ChoosexRobot", name: "ChoosexRobot", component: ChoosexRobot },

  // 资产
  { path: "/wallet/recharge", name: "Recharge", component: Recharge },
  { path: "/wallet/extract", name: "Extract", component: Extract },
  { path: "/wallet/record", name: "Record", component: Record },
  { path: "/ZhichanRecord", name: "ZhichanRecord", component: ZhichanRecord },
  { path: "/USDTRecord", name: "USDTRecord", component: USDTRecord },
  { path: "/JifenRecord", name: "JifenRecord", component: JifenRecord },
  { path: "/CoinRecord", name: "CoinRecord", component: CoinRecord },
  // m模拟
  { path: "/MoniZh", name: "MoniZh", component: MoniZh },

  // 我的
  { path: "/mineList", name: "MineList", component: MineList },
  { path: "/JxSetting", name: "JxSetting", component: JxSetting },
  // { path: '/activeVIP', name: 'ActiveVIP', component: ActiveVIP },
  { path: "/frindsCome", name: "FrindsCome", component: FrindsCome },
  { path: "/safety", name: "Safety", component: Safety },
  { path: "/Notice", name: "Notice", component: Notice },
  { path: "/BuyVip", name: "BuyVip", component: BuyVip },
  { path: "/noticeDetail", name: "NoticeDetail", component: NoticeDetail },
  { path: "/help", name: "Notice", component: Help },
  { path: "/helpDetail", name: "helpDetail", component: HelpDetail },
  { path: "/About", name: "About", component: About },
  { path: "/langSwitch", name: "LangSwitch", component: LangSwitch },
  {
    path: "/customerService",
    name: "CustomerService",
    component: CustomerService,
  },
  {
    path: "/customerServiceHistory",
    name: "customerServiceHistory",
    component: CustomerServiceHistory,
  },
  // { path: '/missionCenter', name: 'MissionCenter', component: MissionCenter },
  // 规则说明

  // // 主页
  // {path: '/', name: 'LoginBefore', component: LoginBefore},
  { path: "/", name: "home", component: Home },

  // 404
  {
    path: "/404",
    name: "404",
    component: NoMatch,
  },
];
